import axios from "axios";
import { CloudRenderingRegion } from "../hooks/types";

const latencyClient = axios.create();

latencyClient.interceptors.request.use((config) => {
  config.headers["request-startTime"] = Date.now();
  return config;
});

latencyClient.interceptors.response.use((response) => {
  if (response.config.headers) {
    const start = response.config.headers["request-startTime"] as string;
    const milliseconds = Date.now() - Number(start);
    response.headers["request-duration"] = milliseconds.toString();
  }
  return response;
});

async function getAjaxLatency(url: string) {
  const request = await latencyClient.get(url, { timeout: 1000 });
  return parseInt(request.headers["request-duration"] as string);
}

// Images get you a result even if cors is not there. Its an error but hey, we got a result.
// The only issue is that we do not know if we can actually reach the endpoint.
// Therefore we cant do this for WLZ's
function getImageLatency(url: string) {
  return new Promise<number>((resolve, reject) => {
    const callbackWrapper = (latency: number) => {
      // garbage measurement
      if (latency <= 3) {
        reject();
      } else {
        resolve(latency);
      }
    };

    const startTime = Date.now();
    const image = new Image();
    const timeCallback = function () {
      const endTime = Date.now();
      const timeElapsed = endTime - startTime;
      callbackWrapper(timeElapsed);
    };
    image.onerror = timeCallback;
    image.onload = timeCallback;
    image.src = `${url}?_q=${startTime}`;
  });
}

export async function latencyLookup(
  cloudRegion: CloudRenderingRegion,
  timeout: number = 1000,
): Promise<number> {
  let task = null;
  if (cloudRegion.type === "Region" || cloudRegion.type === "LocalZone") {
    task = getImageLatency(cloudRegion.latencyTestUrl);
  } else {
    task = getAjaxLatency(cloudRegion.latencyTestUrl);
  }

  await Promise.race([
    task,
    // max latency
    new Promise((_, reject) => setTimeout(() => reject(null), timeout)),
  ]);

  return await task;
}
