import type { Application, ApplicationBuildId } from "../hooks/types";
import type { ClientDevice } from "../session/types";

export function isApplicationBuildInstalled(
  applicationBuildId: ApplicationBuildId | undefined,
  device: ClientDevice | undefined,
) {
  if (!applicationBuildId || !device) return false;
  return device.installedApps.some(
    (installedApp) => installedApp.id === applicationBuildId,
  );
}

export function isAnyApplicationBuildInstalled(
  legacyIdentity: Application["legacy_identity"] | undefined,
  device: ClientDevice,
) {
  if (!legacyIdentity) return false;
  return device.installedApps.some(
    (installedApp) => installedApp.identity === legacyIdentity,
  );
}
