import {
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  Stack,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  FaPaste as PasteIcon,
  FaEllipsisV as StreamingMenuIcon,
} from "react-icons/fa";
import {
  selectInteractiveSpectator,
  setIsAudioOutputBlockedByBrowserPolicy,
} from "../../features/interactiveSpectatorSlice";
import { selectIsCloudRenderedVRSession } from "../../features/sessionSlice";
import {
  useAppDispatch,
  useApplicationForSessionQuery,
  useAppSelector,
} from "../../hooks";
import {
  AudioMutedIcon,
  CopyIcon,
  ExternalLinkIcon,
  MultiWindowIcon,
} from "../icons";
import { AudioButton } from "./AudioButton";
import { FullscreenButton } from "./FullscreenButton";
import { TargetWindow } from "./helpers";
import { MicrophoneButton } from "./MicrophoneButton";
import { useRemoteUrls } from "./useRemoteUrls";

export function InteractiveSpectatorControls({
  toggleAudioOutput,
  toggleAudioInput,
  toggleFullscreen,
  copyToClipboard,
  pasteToRemote,
  windows,
  onWindowChanged,
}: {
  toggleAudioOutput: () => void;
  toggleAudioInput: () => void;
  toggleFullscreen: () => void;
  copyToClipboard: () => void;
  pasteToRemote: () => void;
  windows: TargetWindow[];
  onWindowChanged: (handle: number) => void;
}) {
  const { data: application } = useApplicationForSessionQuery();
  const remoteUrls = useRemoteUrls(application?.id);
  const dispatch = useAppDispatch();
  const isMicrophoneSupported = useMemo(() => !!navigator.mediaDevices, []);
  const {
    isMicrophoneEnabled,
    isMicrophoneMuted,
    isAudioMuted,
    isFullscreen,
    isConnected,
    isAudioOutputBlockedByBrowserPolicy,
  } = useAppSelector(selectInteractiveSpectator);
  const { t } = useTranslation();
  const isCloudRenderedVRSession = useAppSelector(
    selectIsCloudRenderedVRSession,
  );

  return (
    <>
      <Popover
        isOpen={isAudioOutputBlockedByBrowserPolicy}
        onClose={() => dispatch(setIsAudioOutputBlockedByBrowserPolicy(false))}
        closeOnBlur={false}
        closeOnEsc={false}
        placement="bottom"
      >
        <PopoverAnchor>
          <AudioButton
            isAudioMuted={isAudioMuted}
            toggleAudioOutput={toggleAudioOutput}
            isDisabled={!isConnected}
          />
        </PopoverAnchor>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody fontSize={"sm"}>
            {t("spectator.audio.blocked_by_browser_policy")}
          </PopoverBody>
          <PopoverFooter>
            <HStack justifyContent={"end"}>
              <Button
                leftIcon={<Icon as={AudioMutedIcon} />}
                size="xs"
                onClick={toggleAudioOutput}
              >
                Unmute
              </Button>
              <Button
                size="xs"
                onClick={() =>
                  dispatch(setIsAudioOutputBlockedByBrowserPolicy(false))
                }
                variant={"ghost"}
              >
                Ignore
              </Button>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
      {!isCloudRenderedVRSession && (
        <MicrophoneButton
          isMicrophoneEnabled={isMicrophoneEnabled}
          isMicrophoneMuted={isMicrophoneMuted}
          isMicrophoneSupported={isMicrophoneSupported}
          toggleAudioInput={toggleAudioInput}
          isDisabled={!isConnected}
        />
      )}
      {windows.length > 1 && (
        <Menu placement="top">
          <MenuButton
            as={IconButton}
            icon={<MultiWindowIcon />}
            isDisabled={!isConnected}
          />
          <MenuList>
            <MenuOptionGroup
              title={t("spectator.windows.title")}
              type="radio"
              value={windows
                .find((window) => window.isActive)
                ?.handle.toString()}
              onChange={(e) => onWindowChanged(Number(e))}
            >
              {windows.map(
                ({ title, handle, isActive, thumbnail: thumbnailBase64 }) => (
                  <MenuItemOption
                    key={handle}
                    fontWeight={isActive ? "bold" : "normal"}
                    value={handle.toString()}
                  >
                    <TargetWindowThumbnail
                      base64Data={thumbnailBase64}
                      title={title}
                    />
                  </MenuItemOption>
                ),
              )}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      )}
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<StreamingMenuIcon />}
          isDisabled={!isConnected}
        />
        <MenuList>
          <MenuItem
            icon={<CopyIcon />}
            onClick={copyToClipboard}
            command="Ctrl + C"
            isDisabled={!isConnected}
          >
            {t("spectator.actions.copy")}
          </MenuItem>
          <MenuItem
            icon={<PasteIcon />}
            onClick={pasteToRemote}
            command="Ctrl + V"
            isDisabled={!isConnected}
          >
            {t("spectator.actions.paste")}
          </MenuItem>
          {remoteUrls.length > 0 && (
            <>
              <MenuDivider />
              <MenuGroup title={t("spectator.remoteUrls.title")}>
                {remoteUrls.map(({ url }, idx) => (
                  <MenuItem
                    key={url + idx}
                    as={Link}
                    href={url}
                    isExternal
                    title={url}
                  >
                    <Icon boxSize={3} as={ExternalLinkIcon} marginRight={2} />{" "}
                    {limitStringLength(url, 40)}
                  </MenuItem>
                ))}
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Menu>
      <FullscreenButton
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    </>
  );
}

function limitStringLength(str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
}

function TargetWindowThumbnail({
  base64Data,
  title,
}: {
  base64Data: string;
  title: string;
}) {
  return (
    <Stack spacing={1}>
      <Heading as="h4" size="xs">
        {title}
      </Heading>
      <Image
        src={`data:image/png;base64,${base64Data}`}
        alt="Thumbnail"
        className="thumbnail"
      />
    </Stack>
  );
}
