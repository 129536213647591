import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  HStack,
  Icon,
  Progress,
  Stack,
  StackProps,
  Text,
  keyframes,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useMemo } from "react";
import { FaDesktop, FaNetworkWired, FaServer } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useBranding } from "../../branding";
import { selectLatencyTestProgress } from "../../features/cloudRenderingSlice";

function AnimatedDot(props: BoxProps) {
  const { data: branding } = useBranding();
  // Define keyframes for the dot animation
  const dotAnimation = useMemo(() => {
    return keyframes`
    0% { background-color: ${branding?.background}; }
    10% { background-color: ${branding?.primary}; }
    25% { background-color: ${branding?.background}; }
  `;
  }, [branding?.background, branding?.primary]);

  return (
    <Box
      width={2.5}
      height={2.5}
      borderRadius="full"
      bg="gray.300"
      animation={`${dotAnimation} 1.7s linear infinite`}
      {...props}
    />
  );
}

export default function PingSimulation(props: StackProps) {
  const testProgress = useSelector(selectLatencyTestProgress);

  return (
    <Stack {...props}>
      <Grid
        alignSelf={"center"}
        templateColumns="repeat(12, 1fr)"
        columnGap={6}
        rowGap={3}
      >
        <GridItem colStart={2} colSpan={2}>
          <Icon as={FaDesktop} boxSize={12} />
        </GridItem>
        <GridItem colSpan={2} alignContent={"center"}>
          <HStack justifyContent={"space-evenly"}>
            <AnimatedDot sx={{ animationDelay: 0 }} />
            <AnimatedDot sx={{ animationDelay: "0.16s" }} />
            <AnimatedDot sx={{ animationDelay: "0.33s" }} />
          </HStack>
        </GridItem>
        <GridItem colSpan={2}>
          <Icon as={FaNetworkWired} boxSize={12} />
        </GridItem>
        <GridItem colSpan={2} alignContent={"center"}>
          <HStack justifyContent={"space-evenly"}>
            <AnimatedDot sx={{ animationDelay: "0.5s" }} />
            <AnimatedDot sx={{ animationDelay: "0.66s" }} />
            <AnimatedDot sx={{ animationDelay: "0.84s" }} />
          </HStack>
        </GridItem>
        <GridItem colSpan={2}>
          <Icon as={FaServer} boxSize={12} />
        </GridItem>
        <GridItem colSpan={4} textAlign={"center"}>
          <Text>{t("server_location_wizard.your_pc")}</Text>
        </GridItem>
        <GridItem colSpan={4} textAlign={"center"}>
          <Text>{t("server_location_wizard.network")}</Text>
        </GridItem>
        <GridItem colSpan={4} textAlign={"center"}>
          <Text>
            {t("server_location_wizard.cloud_rendering_infrastructure")}
          </Text>
        </GridItem>
        <GridItem colStart={2} colSpan={10} textAlign={"center"} paddingTop={4}>
          <Progress
            isAnimated
            hasStripe
            max={1}
            min={0}
            value={testProgress}
            colorScheme="brand"
            size={"sm"}
            borderRadius={"md"}
            transition={"width 300ms ease-in-out"}
          />
        </GridItem>
      </Grid>
    </Stack>
  );
}
