import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, HStack, Spacer } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/media-query";
import type { BoxProps } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  MdKeyboardArrowLeft as ArrowLeftIcon,
  MdKeyboardArrowRight as ArrowRightIcon,
} from "react-icons/md";
import useResizeObserver from "use-resize-observer";
import { SliderControlElementSide } from "./SliderControlElementSide";

interface IHorizontalSliderContext {
  itemWidth: BoxProps["width"];
}
export const HorizontalSliderContext = createContext(
  {} as IHorizontalSliderContext,
);

export const SliderControlElement = ({
  side,
  ...props
}: { side: SliderControlElementSide } & BoxProps) => {
  const left = side === SliderControlElementSide.Left;

  const boxProps: BoxProps = {
    position: "absolute",
    display: "flex",
    color: useColorModeValue("gray.900", "white"),
    height: "100%",
    padding: 4,
    alignItems: "center",
    zIndex: "docked",
    bgGradient: `linear(${
      left ? "to-r" : "to-l"
    }, backgroundAlpha.700 15%, backgroundAlpha.400 75%, backgroundAlpha.200, transparent)`,
    top: 0,
    cursor: "pointer",
    left: left ? 0 : undefined,
    right: left ? undefined : 0,
    transition: "all 300ms",
    _hover: {
      color: "brand.400",
      bgGradient: `linear(${
        left ? "to-r" : "to-l"
      }, backgroundAlpha.800 0%, backgroundAlpha.400 75%, backgroundAlpha.200, transparent)`,
    },
    ...props,
  };

  return (
    <Box {...boxProps}>
      {left ? (
        <Icon as={ArrowLeftIcon} boxSize={28} />
      ) : (
        <Icon as={ArrowRightIcon} boxSize={28} />
      )}
    </Box>
  );
};

const slideWidthPercentages = [0.7, 0.5, 0.25, 0.19];
const sliderSpacingPercentages = [0.08, 0.08, 0.03, 0.02];

export function HorizontalSlider({
  children,
  isDisabled,
}: {
  children: ReactNode[];
  isDisabled?: boolean;
}) {
  // detect if we're on a touch screen or not (this will enable scrolling within the app group)
  // see https://stackoverflow.com/a/52855084/1142028
  const [isTouch] = useMediaQuery("(pointer: coarse)");
  const [scrollXSteps, setScrollXSteps] = useState(0);
  const { ref, width = 0 } = useResizeObserver();

  const slideRight = useCallback(
    () => setScrollXSteps((scrollXSteps) => scrollXSteps + 1),
    [],
  );
  const slideLeft = useCallback(
    () => setScrollXSteps((scrollXSteps) => scrollXSteps - 1),
    [],
  );

  const hasReachedRightLimit = useCallback(
    (stepCount: number) =>
      stepCount * (scrollXSteps + 1) >= (children.length ?? 0),
    [children.length, scrollXSteps],
  );
  const hasReachedLeftLimit = useCallback(
    () => scrollXSteps === 0,
    [scrollXSteps],
  );

  // how many elements do we fit on the screen (all relative to viewport units)
  const sliderStepData = useMemo(() => {
    return sliderSpacingPercentages.map((_, idx) => {
      if (idx < 2) {
        return {};
      }
      const totalSlideWidthPercentage =
        sliderSpacingPercentages[idx] + slideWidthPercentages[idx];
      const stepCount = Math.floor(1 / totalSlideWidthPercentage);
      return {
        stepWidth: !width
          ? undefined
          : stepCount * totalSlideWidthPercentage * width,
        stepCount,
      };
    });
  }, [width]);

  return (
    <Box marginTop={0} padding={[4, 6]} ref={ref}>
      <Box overflow={isTouch ? "auto" : "hidden"} position="relative">
        {!isTouch && !isDisabled && (
          <SliderControlElement
            side={SliderControlElementSide.Left}
            onClick={() => slideLeft()}
            display={sliderStepData.map(({ stepCount }) => {
              return hasReachedLeftLimit() || !stepCount ? "none" : "flex";
            })}
          />
        )}
        {!isTouch && !isDisabled && (
          <SliderControlElement
            side={SliderControlElementSide.Right}
            onClick={() => slideRight()}
            display={sliderStepData.map(({ stepCount }) => {
              if (hasReachedRightLimit(stepCount ?? 0) || !stepCount) {
                return "none";
              }
              return "flex";
            })}
          />
        )}
        <HStack
          alignItems="normal"
          spacing={
            !width
              ? undefined
              : sliderSpacingPercentages.map(
                  (percent) => width * percent + "px",
                )
          }
          transform={
            !isTouch
              ? sliderStepData.map(({ stepCount, stepWidth }, index) => {
                  if (index < 2 || !stepWidth || !stepCount) {
                    return null;
                  }
                  if (hasReachedRightLimit(stepCount)) {
                    return `translateX(-${
                      (children.length / stepCount - 1) * stepWidth
                    }px)`;
                  }
                  return `translateX(-${scrollXSteps * stepWidth}px)`;
                })
              : undefined
          }
          paddingY={2}
          transition="transform 500ms"
        >
          <HorizontalSliderContext.Provider
            value={{
              itemWidth: slideWidthPercentages.map((pct) => `${pct * 100}%`),
            }}
          >
            {children}
          </HorizontalSliderContext.Provider>
          <Spacer minWidth="1" />
        </HStack>
      </Box>
    </Box>
  );
}
