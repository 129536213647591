import { CircularProgress, CircularProgressLabel } from "@chakra-ui/progress";
import { useTranslation } from "react-i18next";

export function SessionEndingState() {
  const { t } = useTranslation();

  return (
    <CircularProgress
      isIndeterminate
      color="brand.300"
      thickness="3px"
      // FIXME this is shit
      size={[120, 140, 160, 200] as unknown as string}
    >
      <CircularProgressLabel fontSize={"lg"}>
        {t("session.ending")}
      </CircularProgressLabel>
    </CircularProgress>
  );
}
