import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  HStack,
} from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const SessionAlreadyRunningWarningDialog = ({
  isOpen,
  onClose,
  onConfirm,
  ...props
}: Omit<AlertDialogProps, "leastDestructiveRef" | "children"> & {
  onConfirm(): void;
}) => {
  const { t } = useTranslation();
  const cancelRef = useRef<FocusableElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const confirmClick = () => {
    setIsLoading(true);
    onConfirm();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      leastDestructiveRef={cancelRef}
      {...props}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("session.session_already_running_dialog.title")}
          </AlertDialogHeader>

          <AlertDialogBody whiteSpace={"pre-wrap"}>
            {t("session.session_already_running_dialog.body")}
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack>
              <Button onClick={onClose}>{t("session.cancel")}</Button>
              <Button
                colorScheme="red"
                onClick={confirmClick}
                isLoading={isLoading}
              >
                {t("session.confirm")}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
