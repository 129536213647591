import {
  Button,
  ButtonGroup,
  FormControl,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs, { extend } from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en";
import RelativeTime from "dayjs/plugin/relativeTime";
import { ChangeEvent, RefObject, useCallback, useRef, useState } from "react";
import FocusLock from "react-focus-lock";
import { Trans, useTranslation } from "react-i18next";
import { useActiveOrganizationQuery } from "../hooks";
import { Application, ApplicationId, Organization } from "../hooks/types";
import { getQueryKeyForOrganization } from "../hooks/useActiveOrganizationQuery";
import { getQueryKeyForApplication } from "../hooks/useApplicationsQuery";
import {
  createApplicationAccessRequest,
  fetchApplicationAccessRequest,
  getApplicationExternalReferences,
} from "../portal-api";
import { BrandedSkeleton } from "./BrandedSkeleton";
import { RequestAccessIcon } from "./icons";

extend(RelativeTime);

interface AppActionProps {
  application: Application;
}

const requestAccessReferenceType = "request_access";

function getQueryKeyForApplicationAccessRequest(
  applicationId: ApplicationId,
  organizationId: Organization["id"] | undefined,
) {
  return [
    ...getQueryKeyForOrganization(organizationId),
    ...getQueryKeyForApplication(applicationId),
    "access-request",
  ];
}

export function RequestAccessButton({
  application: { id: applicationId },
}: AppActionProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { t, i18n } = useTranslation();
  const firstFieldRef = useRef<HTMLTextAreaElement>(null);
  const { data: organization } = useActiveOrganizationQuery();
  const externalReferencesQuery = useQuery({
    queryKey: [
      "applications",
      applicationId,
      "external-references",
      { type: requestAccessReferenceType },
    ],
    queryFn: () =>
      getApplicationExternalReferences(applicationId ?? "", {
        type: requestAccessReferenceType,
      }),
    enabled: !!applicationId,
  });
  const requestAccessUrlReference = externalReferencesQuery.data?.results.find(
    (reference) => reference.type === requestAccessReferenceType,
  );
  const accessRequestQuery = useQuery({
    queryKey: getQueryKeyForApplicationAccessRequest(
      applicationId,
      organization?.id,
    ),
    queryFn: () =>
      fetchApplicationAccessRequest(applicationId, {
        organization: organization?.id,
      }),
    enabled: !!organization,
  });

  const isLoading = accessRequestQuery.isLoading;

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
          leftIcon={<Icon as={RequestAccessIcon} />}
          isLoading={isLoading}
          isDisabled={isOpen}
        >
          {accessRequestQuery.isError ? (
            t("request_access.request_access")
          ) : isLoading ? (
            <BrandedSkeleton>Loading ...</BrandedSkeleton>
          ) : (
            t("request_access.access_requested", {
              relativeDate: dayjs(accessRequestQuery.data?.created_date)
                .locale(i18n.language)
                .fromNow(),
            })
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent p={5}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <Stack paddingTop={2}>
            {requestAccessUrlReference?.is_active ? (
              <Text>
                <Trans
                  t={t}
                  i18nKey="request_access.handled_externally"
                  values={{ url: requestAccessUrlReference.url }}
                >
                  Please go to{" "}
                  <Link isExternal href={requestAccessUrlReference.url}>
                    {requestAccessUrlReference.url}
                  </Link>{" "}
                  to request access to this application.
                </Trans>
              </Text>
            ) : accessRequestQuery.isSuccess ? (
              <>
                <Text>{t("request_access.pending_request")}</Text>
                <Text as="cite">{accessRequestQuery.data.message}</Text>
              </>
            ) : (
              <RequestAccessForm
                applicationId={applicationId}
                firstFieldRef={firstFieldRef}
                onCancel={onClose}
              />
            )}
          </Stack>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
}

function RequestAccessForm({
  applicationId,
  firstFieldRef,
  onCancel,
}: {
  applicationId: ApplicationId;
  firstFieldRef: RefObject<HTMLTextAreaElement>;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const { data: organization } = useActiveOrganizationQuery();

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setMessage(inputValue);
  };

  const queryClient = useQueryClient();
  const { mutate: _requestAccess, ...requestAccessMutation } = useMutation<
    unknown,
    unknown,
    {
      organization: Organization;
      applicationId: ApplicationId;
      message: string;
    }
  >({
    mutationFn: ({ organization, applicationId }) => {
      return createApplicationAccessRequest(
        applicationId,
        organization.id,
        message,
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: getQueryKeyForApplicationAccessRequest(
          applicationId,
          organization?.id,
        ),
      }),
    onSuccess: () => {
      onCancel();
    },
  });

  const requestAccess = useCallback(() => {
    if (!organization) return;
    _requestAccess({
      applicationId,
      organization,
      message,
    });
  }, [applicationId, organization, _requestAccess, message]);

  return (
    <Stack spacing={4}>
      <FormControl marginTop={4}>
        <Textarea
          ref={firstFieldRef}
          id="request-access-message"
          placeholder={t("request_access.message_label")}
          value={message}
          onChange={handleInputChange}
        />
      </FormControl>

      <ButtonGroup display="flex" justifyContent="flex-end">
        <Button variant="outline" onClick={onCancel}>
          {t("request_access.cancel")}
        </Button>
        <Button
          isDisabled={!message}
          isLoading={requestAccessMutation.isPending}
          colorScheme="brand"
          onClick={requestAccess}
        >
          {t("request_access.submit")}
        </Button>
      </ButtonGroup>
    </Stack>
  );
}
