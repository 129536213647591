import { Stack, Text } from "@chakra-ui/layout";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  StyleProps,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from "react-router-dom";
import { z } from "zod";
import { showClientApplicationStoreInstallationInstructions } from "../../config";
import { ClientApplicationType } from "../../hooks/types";
import { routes } from "../../utils/routes";
import { InstallationInstructionsAppLab } from "./InstallationInstructionsAppLab";
import { InstallationInstructionsMDM } from "./InstallationInstructionsMDM";
import { InstallationInstructionsPicoEnterpriseStore } from "./InstallationInstructionsPicoEnterpriseStore";
import { InstallationInstructionsSideloading } from "./InstallationInstructionsSideloading";
import { InstallationInstructionsViveBusinessAppStore } from "./InstallationInstructionsViveBusinessAppStore";
import { deviceSlugSchema } from "./schemata";
import { useDeviceTranslation } from "./useDeviceTranslation";

const installationTypeSchema = z.enum(["store", "sideloading", "mdm"]);
type InstallationType = z.infer<typeof installationTypeSchema>;

export function InstallationInstructionsForStandaloneClient() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const showStoreInstructions =
    showClientApplicationStoreInstallationInstructions;
  const expandedStyle: StyleProps = { fontWeight: "bold" };
  const defaultInstallationType: InstallationType = showStoreInstructions
    ? "store"
    : "sideloading";
  const {
    deviceSlug,
    installationType: installationTypeStr = defaultInstallationType,
  } = useParams();
  const clientType = deviceSlugSchema.parse(deviceSlug);
  const installationType = installationTypeSchema.parse(installationTypeStr);
  const deviceTranslation = useDeviceTranslation(clientType);
  const supportedInstallationTypes: InstallationType[] = ["sideloading", "mdm"];
  if (showStoreInstructions) {
    // prepend store installation type if it should be shown
    supportedInstallationTypes.unshift("store");
  }
  const activeIndex = supportedInstallationTypes.indexOf(installationType);

  if (activeIndex === -1) {
    return (
      <Navigate
        to={generatePath(routes.clients.installForDeviceSpecificType, {
          deviceSlug: clientType,
          installationType: defaultInstallationType,
        })}
      />
    );
  }

  return (
    <Stack spacing={6}>
      <Text>{t("clients.installation_methods.intro", deviceTranslation)}</Text>
      <Accordion
        index={activeIndex}
        onChange={(index) => {
          navigate(
            generatePath(routes.clients.installForDeviceSpecificType, {
              deviceSlug: clientType,
              installationType: supportedInstallationTypes[index as number],
            }),
            { replace: true },
          );
        }}
        allowMultiple={false}
      >
        {showStoreInstructions && (
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={expandedStyle}>
                <Text as="span" flex="1" textAlign="left">
                  {clientType === ClientApplicationType.MetaQuest
                    ? t("clients.installation_methods.meta_applab.title")
                    : clientType === ClientApplicationType.HtcViveFocus3
                      ? t(
                          "clients.installation_methods.vive_business_appstore.title",
                        )
                      : clientType === ClientApplicationType.PicoNeo3Pro
                        ? t(
                            "clients.installation_methods.pico_enterprise_store.title",
                          )
                        : null}{" "}
                  <Text
                    as="span"
                    fontSize={"sm"}
                    color="chakra-subtle-text"
                    fontWeight={"normal"}
                  >
                    ({t("clients.installation_methods.recommended")})
                  </Text>
                </Text>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel paddingY={6}>
              {clientType === ClientApplicationType.MetaQuest ? (
                <InstallationInstructionsAppLab />
              ) : clientType === ClientApplicationType.HtcViveFocus3 ? (
                <InstallationInstructionsViveBusinessAppStore />
              ) : clientType === ClientApplicationType.PicoNeo3Pro ? (
                <InstallationInstructionsPicoEnterpriseStore />
              ) : null}
            </AccordionPanel>
          </AccordionItem>
        )}

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={expandedStyle}>
              <Text as="span" flex="1" textAlign="left">
                {t("clients.installation_methods.sideloading.title")}
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel paddingY={6}>
            <InstallationInstructionsSideloading clientType={clientType} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem _last={{ borderBottomWidth: 0 }}>
          <h2>
            <AccordionButton _expanded={expandedStyle}>
              <Text as="span" flex="1" textAlign="left">
                {t("clients.installation_methods.mdm.title")}
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel paddingY={6}>
            <InstallationInstructionsMDM clientType={clientType} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
}
