import { Stack } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SessionDataExtended } from "../../../features/sessionSlice";
import { useSessionDevice } from "../../../hooks";

export function SessionReadyState({
  session,
}: {
  session: SessionDataExtended;
}) {
  const { t } = useTranslation();
  const device = useSessionDevice();

  return (
    <Box
      backgroundColor="backgroundAlpha.500"
      width={{ base: "100%", md: "auto" }}
    >
      <Alert
        status="info"
        flexDirection="column"
        variant="solid"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        padding={10}
        width="auto"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {!session.isBrowser
            ? t("session.ready_instructions", {
                device: device?.name ?? "Unknown",
              })
            : t("session.ready")}
        </AlertTitle>
        {!session.isBrowser && (
          <Stack spacing={5}>
            <AlertDescription maxWidth="sm">
              <Text>{t("session.ready")}</Text>
            </AlertDescription>
          </Stack>
        )}
      </Alert>
    </Box>
  );
}
