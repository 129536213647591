import { Button, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon } from "../../components/icons";

const clientApplabUrl =
  "https://www.oculus.com/deeplink/?action=view&path=/app/6008883965894107";

export function InstallationInstructionsAppLab() {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Text>{t("clients.installation_methods.meta_applab.text")}</Text>
      <Link isExternal href={clientApplabUrl}>
        <Button
          colorScheme="brand"
          width="full"
          textDecoration={"none"}
          rightIcon={<Icon as={ExternalLinkIcon} boxSize={3} />}
        >
          <Text>{t("clients.installation_methods.meta_applab.cta")}</Text>
        </Button>
      </Link>
    </Stack>
  );
}
