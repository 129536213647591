import Color from "color";

/**
 * Generates a palette of colors based on the given centerColor (suitable for chakra-ui or tailwind css)
 * taken from https://github.com/tomhicks/themera/blob/aab8183d71d5200fa9c2899b425ceafe70a13bb2/src/atom.ts#L12-L72
 */
export default function generatePalette(centerColor: string, center = 500) {
  const config = {
    darkest: {
      lightness: 10,
      rotate: 0,
      saturate: 0,
    },
    lightest: {
      lightness: 95,
      rotate: 0,
      saturate: 0,
    },
  };

  const spectrumIndices = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

  const centerIndex = spectrumIndices.indexOf(center);

  const darkSteps = spectrumIndices.length - 1 - centerIndex;
  const lightSteps = centerIndex + 1;

  const centerLightness = Color(centerColor).hsl().lightness();
  const lightnessStep =
    (config.lightest.lightness - centerLightness) / lightSteps;
  const darknessStep = (centerLightness - config.darkest.lightness) / darkSteps;

  return Object.fromEntries(
    spectrumIndices.map((spectrumIndex, index) => {
      const lightnessParams =
        centerLightness +
        (index > centerIndex ? darknessStep : lightnessStep) *
          (centerIndex - index);
      return [
        spectrumIndex,
        Color(centerColor).lightness(lightnessParams).hex(),
      ];
    }),
  );
}
