import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  VStack,
} from "@chakra-ui/react";
import { Layout, LinkButton } from ".";

function isError(value: unknown): value is Error {
  return value instanceof Error;
}

export function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: unknown;
  resetErrorBoundary: () => void;
}) {
  return (
    <Layout>
      <Alert status="error" variant="subtle" flexDirection={"column"}>
        <VStack spacing={3}>
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Something went wrong
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            {isError(error) ? error.message : "Unknown Error ocurred"}
          </AlertDescription>
          <LinkButton onClick={resetErrorBoundary} to={"/"}>
            Restart
          </LinkButton>
        </VStack>
      </Alert>
    </Layout>
  );
}
