import { Button, Icon, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdArrowRightAlt as ArrowIcon } from "react-icons/md";
import { LaunchableApplicationLaunchConfiguration } from "../hooks/types";
import { XRPlatformDisplay } from "./XRPlatformDisplay";
import { getXRPlatformDisplayText } from "./getXRPlatformDisplayText";

export function LaunchConfigurationSelection({
  launchConfigurations,
  onLaunchConfigurationSelected: onTargetDeviceSelected,
}: {
  launchConfigurations: LaunchableApplicationLaunchConfiguration[];
  onLaunchConfigurationSelected: (
    launchConfiguration: LaunchableApplicationLaunchConfiguration,
  ) => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Text fontSize="lg" fontWeight="bold" paddingY={8}>
        {t("launch.select_launch_configuration")}
      </Text>
      <Stack spacing={4}>
        {launchConfigurations.map((launchConfiguration) => (
          <Button
            key={launchConfiguration.xr_platform}
            value={launchConfiguration.xr_platform}
            width="full"
            size="lg"
            colorScheme="brand"
            title={`Select ${getXRPlatformDisplayText(
              launchConfiguration.xr_platform,
            )}`}
            role="group"
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            onClick={() => onTargetDeviceSelected(launchConfiguration)}
          >
            <XRPlatformDisplay xrPlatform={launchConfiguration.xr_platform} />
            <Icon
              as={ArrowIcon}
              transition={"all 300ms ease-in-out"}
              opacity={0}
              _groupHover={{ opacity: 1 }}
            />
          </Button>
        ))}
      </Stack>
    </>
  );
}
