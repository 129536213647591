import { Badge, useColorModeValue } from "@chakra-ui/react";

export function BrandedBadge({ ...props }) {
  return (
    <Badge
      backgroundColor={useColorModeValue("blackAlpha.200", "whiteAlpha.300")}
      {...props}
    />
  );
}
