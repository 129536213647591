import { ReactNode, useMemo } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { DEVICE_FILTER, RENDER_FILTER } from ".";
import { XRPlatformType } from "../hooks/types";
import { SearchContext } from "./context";

export function SearchProvider({ children }: { children?: ReactNode }) {
  const [deviceFilter, setDeviceFilter] = useQueryParam(
    "device",
    withDefault(StringParam, "all"),
  );
  const [renderFilter, setRenderFilter] = useQueryParam(
    "render-mode",
    withDefault(StringParam, "all"),
  );

  const queryParams = useMemo(() => {
    const params: { xr_platform?: XRPlatformType[] } = {};
    if (deviceFilter !== DEVICE_FILTER.ALL) {
      if (deviceFilter === DEVICE_FILTER.BROWSER) {
        params.xr_platform = ["win-non-vr"];
        // cannot combine local rendering with browser
        if (renderFilter === "local") {
          setRenderFilter("all");
        }
      } else if (deviceFilter === DEVICE_FILTER.VR) {
        params.xr_platform = ["win-vr"];
      } else if (deviceFilter === DEVICE_FILTER.QUEST) {
        params.xr_platform = ["quest", "win-vr"];
      } else if (deviceFilter === DEVICE_FILTER.FOCUS) {
        params.xr_platform = ["wave", "win-vr"];
      } else if (deviceFilter === DEVICE_FILTER.PICO) {
        params.xr_platform = ["pico", "win-vr"];
      }
    }

    if (renderFilter !== RENDER_FILTER.ALL) {
      if (renderFilter === RENDER_FILTER.STREAMING) {
        params.xr_platform = ["win-vr", "win-non-vr"];
      } else if (renderFilter === RENDER_FILTER.LOCAL) {
        if (deviceFilter === DEVICE_FILTER.QUEST) {
          params.xr_platform = ["quest"];
        } else if (deviceFilter === DEVICE_FILTER.FOCUS) {
          params.xr_platform = ["wave"];
        } else if (deviceFilter === DEVICE_FILTER.PICO) {
          params.xr_platform = ["pico"];
        }
      }
    }
    return params;
  }, [deviceFilter, renderFilter, setRenderFilter]);

  return (
    <SearchContext.Provider
      value={{
        deviceFilter,
        renderFilter,
        setDeviceFilter,
        setRenderFilter,
        queryParams,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
