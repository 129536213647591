import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { SessionRating } from "../hooks/types";
import { useSecondsTicker } from "../hooks/useSecondsTicker";
import { rateSession } from "../session-management-api";
import { SESSION_STATE } from "../session/session-state";
import { LinearProgressButton } from "./LinearProgressButton";
import { StarRating } from "./StarRating";

export function RateSessionDialog() {
  const disclosure = useDisclosure({
    onClose: () => {
      setRating(undefined);
      setMessage("");
      setHasEdit(false);
      setWasStarted(false);
      setLastSessionId(undefined);
    },
  });
  const { t } = useTranslation();
  const cancelRef = useRef(null);
  const currentSession = useAppSelector(selectSessionState);
  const [message, setMessage] = useState("");
  const [wasStarted, setWasStarted] = useState(false);
  const [hasEdit, setHasEdit] = useState(false);
  const [rating, setRating] = useState<number | undefined>();
  const [lastSessionId, setLastSessionId] = useState<string | undefined>();
  const autoDismissSeconds = 30;
  const ticker = useSecondsTicker({
    startSeconds: autoDismissSeconds,
    direction: -1,
    min: 0,
    max: 30,
    enabled: disclosure.isOpen,
  });

  const rateSessionMutation = useMutation<
    unknown,
    unknown,
    { lastSessionId: string; rating: SessionRating }
  >({
    mutationFn: ({ lastSessionId, rating }) =>
      rateSession(lastSessionId, rating),
    onSuccess: () => disclosure.onClose(),
  });

  useEffect(() => {
    if (currentSession.state === SESSION_STATE.ACTIVE) {
      setWasStarted(true);
    }
    if (currentSession.state === SESSION_STATE.ENDING && wasStarted) {
      disclosure.onOpen();
    }
  }, [currentSession.state, disclosure, wasStarted]);

  useEffect(() => {
    if (currentSession.id) {
      setLastSessionId(currentSession.id);
    }
  }, [currentSession.id]);

  useEffect(() => {
    if (ticker <= 0 && !hasEdit) {
      disclosure.onClose();
    }
  }, [disclosure, hasEdit, ticker]);

  const submit = (rating: number) => {
    if (!lastSessionId || !rating) {
      disclosure.onClose();
      return;
    }

    rateSessionMutation.mutate({
      lastSessionId: lastSessionId,
      rating: {
        starRating: rating,
        ratingComment: message,
      },
    });
  };

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={disclosure.onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" alignSelf="center">
            {t("session.rate_dialog.title")}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Stack spacing="lg">
              <Center mb={6}>
                <StarRating
                  size={30}
                  rating={rating}
                  setRating={(rating: number) => {
                    setHasEdit(true);
                    setRating(rating);
                    if (rating === 5) {
                      submit(rating);
                    }
                  }}
                />
              </Center>
              {rating && rating <= 4 && (
                <Textarea
                  placeholder={t("session.rate_dialog.placeholder")}
                  onChange={(c) => {
                    setHasEdit(true);
                    setMessage(c.target.value);
                  }}
                />
              )}
            </Stack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <LinearProgressButton
              value={hasEdit ? 0 : autoDismissSeconds - ticker}
              max={autoDismissSeconds}
              onClick={disclosure.onClose}
              isLoading={rateSessionMutation.isPending}
              width="fit-content"
            >
              {t("session.rate_dialog.cancel")}
              {hasEdit ? "" : ` (${ticker}s)`}
            </LinearProgressButton>
            {rating && rating <= 4 && (
              <Button
                colorScheme="green"
                onClick={() => submit(rating)}
                ml={3}
                isDisabled={!rating}
                isLoading={rateSessionMutation.isPending}
              >
                {t("session.rate_dialog.rate_action")}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
