import { Box, Heading, Stack } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { usePrivacyAndTerms } from "../hooks";

export function PrivacyPage() {
  const { t } = useTranslation();
  const { data: { privacy_content: content, is_enabled } = {} } =
    usePrivacyAndTerms();

  return (
    <Stack spacing={8}>
      <Heading as="h2">{t("privacy.title")}</Heading>
      {is_enabled && (
        <Box dangerouslySetInnerHTML={{ __html: content || "" }} />
      )}
    </Stack>
  );
}
