import { ToastId, useColorModeValue } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createEnumParam, useQueryParams } from "use-query-params";
import { useBranding } from "../branding";
import { useActiveOrganizationQuery, useNotificationToast } from "../hooks";

enum OnboardingSource {
  Invitation = "invitation-accepted",
}

const toastId: ToastId = "onboarding";

export function Onboarding() {
  const [{ source }, setQueryParams] = useQueryParams({
    source: createEnumParam([OnboardingSource.Invitation]),
  });
  const toast = useNotificationToast();
  const { data: branding } = useBranding();
  const { data: organization } = useActiveOrganizationQuery();
  const { t } = useTranslation();
  const variant = useColorModeValue("subtle", "solid");

  useEffect(() => {
    if (!branding) return;

    if (source === OnboardingSource.Invitation && !toast.isActive(toastId)) {
      toast({
        status: "success",
        title: t("invitation.accepted.title", {
          name:
            `${branding?.company_name} ${branding?.product_name}`.trim() ||
            organization?.name,
        }),
        description: t("invitation.accepted.description"),
        onCloseComplete: () => {
          setQueryParams({ source: undefined }, "replaceIn");
        },
        isClosable: true,
        position: "top",
        duration: null,
        id: toastId,
      });
    }
  }, [branding, organization?.name, setQueryParams, source, toast, t, variant]);

  return null;
}
