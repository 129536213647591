import { useLocalStorage } from "usehooks-ts";

export function useRegionsWithoutCapacity(
  capacityErrorAgeThresholdSeconds: number = 1800,
) {
  const [regionsWithoutCapacity, setRegionsWithoutCapacity] = useLocalStorage<{
    [region: string]: Date;
  }>("regionsWithoutCapacity", {});

  const now = new Date();
  const regionNames = Object.keys(regionsWithoutCapacity).filter(
    (region) =>
      now.getTime() - new Date(regionsWithoutCapacity[region]).getTime() <
      capacityErrorAgeThresholdSeconds * 1000,
  );
  return [
    // only return those regions where the error is older than the threshold
    regionNames,
    setRegionsWithoutCapacity,
  ] as const;
}
