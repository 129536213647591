import { useMemo } from "react";
import { selectOnlineDevices } from "../features/devicesSlice";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "./redux";

export function useSessionDevice() {
  const currentSession = useAppSelector(selectSessionState);
  const connectedDevices = useAppSelector(selectOnlineDevices);

  return useMemo(
    () =>
      connectedDevices.find(
        (device) => device.identifier === currentSession.deviceIdentifier,
      ),
    [connectedDevices, currentSession.deviceIdentifier],
  );
}
