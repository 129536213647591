import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { selectCloudRendering } from "../features/cloudRenderingSlice";
import { useAppSelector } from "../hooks";
import { routes } from "../utils/routes";

export function RequireLatency({
  children,
}: PropsWithChildren): JSX.Element | null {
  const cloudRendering = useAppSelector(selectCloudRendering);
  const location = useLocation();

  if (
    !cloudRendering.latencyTestCompleted &&
    // if latency check was aborted by user, accept it and continue
    !location.state?.latencyCheckAborted
  ) {
    // make sure we know where we came from to redirect back after latency test
    return (
      <Navigate to={routes.serverLocationWizard} state={{ from: location }} />
    );
  }

  // all good, render children
  return <>{children}</>;
}
