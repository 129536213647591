import { Icon, IconButton } from "@chakra-ui/react";
import {
  FaCompress as ExitFullscreenIcon,
  FaExpand as FullscreenIcon,
} from "react-icons/fa";

export function FullscreenButton({
  isFullscreen,
  toggleFullscreen,
}: {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
}) {
  return (
    <IconButton
      onClick={toggleFullscreen}
      icon={<Icon as={isFullscreen ? ExitFullscreenIcon : FullscreenIcon} />}
      paddingX={4}
      {...{
        "aria-label": isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen",
      }}
    />
  );
}
