import { ReactElement, useMemo } from "react";

type SwitchProps<T> = {
  test: T;
  children: ReactElement<CaseProps<T>>[];
  fallback: ReactElement;
};

export function Switch<T>({ test, children, fallback }: SwitchProps<T>) {
  return useMemo(
    () =>
      children.find((child) => {
        return child.props.value === test;
      }) ?? fallback,
    [children, fallback, test],
  );
}

type CaseProps<T> = {
  component: React.ReactElement;
  value: T;
};

export function Case<T>({ component }: CaseProps<T>) {
  return component;
}
