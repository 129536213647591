import { Icon, IconProps } from "@chakra-ui/icon";
import { Link, LinkProps } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";

const ExternalLinkIcon = (props: IconProps) => (
  <Icon as={FaExternalLinkAlt} {...props} />
);
export const ExternalLink = ({ children, ...props }: LinkProps) => (
  <Link
    isExternal
    {...props}
    role="group"
    display="inline-flex"
    alignItems="center"
  >
    {children}{" "}
    <ExternalLinkIcon
      boxSize={3}
      marginLeft={1}
      opacity="0.2"
      _groupHover={{ opacity: 1 }}
    />
  </Link>
);
