import { useMemo } from "react";
import { useAppProperties, useAppSelector, useAppSupportedActions } from ".";
import { selectOnlineDevices } from "../features/devicesSlice";
import { DeviceType } from "../session/types";
import { ClientDeviceType, getDeviceType } from "../utils/devices";
import { Application } from "./types";

export function useSupportedDevices(application?: Application) {
  const connectedDevices = useAppSelector(selectOnlineDevices);
  const applicationProperties = useAppProperties(application);
  const supportedActions = useAppSupportedActions(application);

  return useMemo(
    () =>
      connectedDevices.filter((hmd) => {
        if (
          supportedActions.desktopRenderingSupported &&
          hmd.type === DeviceType.Desktop
        ) {
          return true;
        }

        if (
          supportedActions.vrCloudRenderingSupported &&
          hmd.type === DeviceType.Standalone
        ) {
          return true;
        }

        if (
          hmd.type === DeviceType.Standalone &&
          applicationProperties.isStandaloneVrApp
        ) {
          const deviceType = getDeviceType(hmd);

          if (
            deviceType === ClientDeviceType.Wave &&
            applicationProperties.isWaveApp
          ) {
            return true;
          }

          if (
            deviceType === ClientDeviceType.Quest &&
            applicationProperties.isQuestApp
          ) {
            return true;
          }

          if (
            deviceType === ClientDeviceType.Pico &&
            applicationProperties.isPicoApp
          ) {
            return true;
          }
        }

        return false;
      }),
    [applicationProperties, supportedActions, connectedDevices],
  );
}
