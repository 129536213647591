import { useQuery } from "@tanstack/react-query";
import { useAuthentication } from "../auth";
import { VmFilter, getVirtualMachines } from "../session-management-api";

export function getVirtualMachinesQueryKey(param: VmFilter = {}, id?: string) {
  return ["virtualMachines", param, id];
}

export function useVirtualMachinesQuery(
  param: VmFilter = {},
  refetchInterval?: number,
) {
  const { user } = useAuthentication();

  return useQuery({
    queryKey: getVirtualMachinesQueryKey(param),
    queryFn: () => getVirtualMachines(param),
    refetchOnMount: true,
    enabled: !!user?.id,
    refetchInterval,
  });
}
