export const DEVICE_FILTER = Object.freeze({
  ALL: "all",
  BROWSER: "win-non-vr",
  VR: "win-vr",
  QUEST: "quest",
  FOCUS: "wave",
  PICO: "pico",
});

export const RENDER_FILTER = Object.freeze({
  ALL: "all",
  STREAMING: "streaming",
  LOCAL: "local",
});
