import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { useAuthentication } from "../auth";
import { sentryDSN } from "../config";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { version } from "./version";

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const SetSentryUser = function () {
  const { user } = useAuthentication();

  useEffect(() => {
    if (!user) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        email: user.email,
        ip_address: "{{auto}}",
        id: user.id.toString(),
      });
    }
  }, [user]);

  return null;
};

export const SetSentrySession = function () {
  const currentSession = useAppSelector(selectSessionState);

  useEffect(() => {
    if (!currentSession.id) {
      Sentry.setContext("portalSession", null);
    } else {
      Sentry.setContext("portalSession", {
        id: currentSession.id,
        applicationBuildId: currentSession.applicationBuildId,
        deviceIdentifier: currentSession.deviceIdentifier,
      });
    }
  }, [currentSession]);

  return null;
};

export const setupSentry = () => {
  if (import.meta.env.PROD && sentryDSN) {
    Sentry.init({
      dsn: sentryDSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      release: `portal-frontend@${version}`,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
      ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.",
      ],
    });
  }
};
