import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import log from "loglevel";
import { RootState } from "../store";

export const requestNotificationPermission = createAsyncThunk(
  "notifications/requestPermission",
  async () => {
    // Ask for permission to send notifications to the user (to notify when session is ready)
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      log.warn("This browser does not support desktop notification");
      return "unsupported";
    }

    // Let's check whether notification permissions have already been granted
    if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      log.debug("notifications already permitted");
      return Notification.permission;
    }
    // Otherwise, we need to ask the user for permission
    return await Notification.requestPermission();
  },
);

export interface NotificationState {
  permission: NotificationPermission | "unsupported";
  permissionRequested: boolean;
}

const initialState: NotificationState = {
  permission:
    "Notification" in window ? Notification.permission : "unsupported",
  permissionRequested: false,
};

export const counterSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetPermissionRequested: (state) => {
      state.permissionRequested = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      requestNotificationPermission.fulfilled,
      (state, action) => {
        state.permission = action.payload;
      },
    );
    builder.addCase(requestNotificationPermission.pending, (state) => {
      state.permissionRequested = true;
    });
  },
});

export const { resetPermissionRequested } = counterSlice.actions;

export const selectNotification = (state: RootState) => state.notification;

export default counterSlice.reducer;
