import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { useRegionsWithoutCapacity } from "../hooks/useRegionsWithoutCapacity";
import { SESSION_STATE } from "../session/session-state";
import { KnownErrorCodes, SessionData } from "../session/types";
import { RegionNoCapacityWarning } from "./RegionNoCapacityWarning";
import { SessionErrorDialog } from "./SessionErrorDialog";

export function SessionErrorHandler() {
  const currentSession = useAppSelector(selectSessionState);
  const [erroredSession, setErroredSession] = useState<SessionData | null>(
    null,
  );
  const disclosure = useDisclosure({ onClose: () => setErroredSession(null) });
  const onOpen = disclosure.onOpen;
  const [, setRegionsWithoutCapacity] = useRegionsWithoutCapacity();

  // triggers dialog when session fails
  useEffect(() => {
    if (currentSession.state === SESSION_STATE.ERRORED) {
      setErroredSession(currentSession);
      // remember the failed region so we won't reuse them in the next xx minutes, also see RegionNoCapacityWarning
      if (
        currentSession.error?.knownSessionErrorCode ===
        KnownErrorCodes.NoVmAvailable
      ) {
        setRegionsWithoutCapacity((regions) => {
          if (!currentSession.renderRegion) return regions;
          return { ...regions, [currentSession.renderRegion]: new Date() };
        });
      }
      onOpen();
    }
  }, [currentSession.state, currentSession, onOpen, setRegionsWithoutCapacity]);

  if (
    erroredSession?.error?.knownSessionErrorCode ===
    KnownErrorCodes.NoVmAvailable
  ) {
    // if there's no capacity in the region, we have two options
    // in automatic region selection mode, we can just try again in the next best region but INFORM the user
    // in manual region selection mode, we need to prompt the user to try again in the next best region (or to select any other region)
    return (
      <RegionNoCapacityWarning
        erroredSessionData={erroredSession}
        {...disclosure}
      />
    );
  }

  if (erroredSession?.message) {
    return (
      <SessionErrorDialog
        sessionId={erroredSession.id}
        errorMessage={erroredSession.message}
        {...disclosure}
      />
    );
  }

  return null;
}
