import { Box, Heading, Link, Stack, Text } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";

export function LegalPage() {
  const { t } = useTranslation();

  return (
    <Stack spacing={8}>
      <Heading as="h2">{t("legal.title")}</Heading>
      <Stack spacing={4}>
        <Text>
          Innoactive GmbH
          <br />
          Rupert-Mayer-Str. 44
          <br />
          81379 München
          <br />
          GERMANY
        </Text>
        <Box>
          <Heading as="h3" fontSize="md" marginBottom={2}>
            {t("legal.managing_director")}:
          </Heading>
          <Text>Daniel Seidl</Text>
        </Box>
        <Box>
          <Heading as="h3" fontSize="md" marginBottom={2}>
            {t("legal.contact")}:
          </Heading>
          <Text>
            Tel.: <Link href="tel:+498921548700">+49 89 2154870-0</Link>
          </Text>
          <Text>
            Email:{" "}
            <Link href="mailto:info@innoactive.de">info@innoactive.de</Link>
          </Text>
        </Box>
        <Box>
          <Heading as="h3" fontSize="md" marginBottom={2}>
            {t("legal.other")}:
          </Heading>
          <Text>Sitz München, Amtsgericht München HRB 190187</Text>
          <Text>Verantwortlich im Sinne des §5 TMG: Daniel Seidl</Text>
        </Box>
        <Heading as="h3" fontSize="md" marginBottom={2}>
          {t("legal.vat")}: DE275550353
        </Heading>
      </Stack>
    </Stack>
  );
}
