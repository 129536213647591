import { useMemo } from "react";
import {
  selectIsDesktopClientConnected,
  selectOnlineDevices,
} from "../features/devicesSlice";
import { useAppSelector } from "./redux";
import { Application } from "./types";

export function useAppProperties(app?: Application) {
  const supportedXRPlatforms =
    app?.launch_configurations
      .filter(({ application_build }) => !!application_build)
      .map(({ xr_platform }) => xr_platform) ?? [];
  const isQuestApp = supportedXRPlatforms?.includes("quest") ?? false;
  const isPicoApp = supportedXRPlatforms?.includes("pico") ?? false;
  const isWaveApp = supportedXRPlatforms?.includes("wave") ?? false;
  const isVRXApp = supportedXRPlatforms?.includes("vrx") ?? false;
  const isWindowsVRApp = supportedXRPlatforms?.includes("win-vr") ?? false;
  const isStandaloneVrApp = isQuestApp || isPicoApp || isWaveApp || isVRXApp;

  return {
    isQuestApp,
    isPicoApp,
    isWaveApp,
    isNotXR: supportedXRPlatforms?.includes("win-non-vr") ?? false,

    isStandaloneVrApp,
    isVrApp: isWindowsVRApp || isStandaloneVrApp,
    isWindowsVRApp,
    isWinApp:
      supportedXRPlatforms.some((xrPlatform) =>
        xrPlatform.startsWith("win-"),
      ) ?? false,
  };
}

export function useAppPermissions(app?: Application) {
  return {
    download:
      // legacy support, if download not defined, it is allowed
      app?.permissions?.download === undefined ||
      app?.permissions?.download === true,
    stream:
      // legacy support, if stream not defined, it is allowed
      app?.permissions?.stream === undefined ||
      app?.permissions?.stream === true,
  };
}

export function useAppSupportedActions(app?: Application) {
  const { isWinApp, isWindowsVRApp, isVrApp, isStandaloneVrApp, isNotXR } =
    useAppProperties(app);
  const { download: isUserAllowedToDownload, stream: isUserAllowedToStream } =
    useAppPermissions(app);
  const isDesktopClientConnected = useAppSelector(
    selectIsDesktopClientConnected,
  );
  const connectedHMDs = useAppSelector(selectOnlineDevices);
  const connectedStandaloneVRDevices = useMemo(
    () =>
      connectedHMDs.filter(function (e) {
        return e.type === "vr-standalone";
      }),
    [connectedHMDs],
  );
  const isVrStandaloneDeviceConnected = useMemo(
    () => connectedStandaloneVRDevices.length > 0,
    [connectedStandaloneVRDevices],
  );

  const supportedActions = useMemo(() => {
    const vrCloudRenderingSupported = isWindowsVRApp && isUserAllowedToStream;

    const launchInBrowserSupported = isNotXR && isUserAllowedToStream;

    const desktopRenderingSupported = isWinApp && isUserAllowedToDownload;

    const standaloneRenderingSupported =
      isStandaloneVrApp && isUserAllowedToDownload;

    const vrCloudRenderingCurrentlyPossibile =
      vrCloudRenderingSupported && isVrStandaloneDeviceConnected;

    const launchInBrowserCurrentlyPossibile = launchInBrowserSupported;

    const desktopRenderingCurrentlyPossibile =
      desktopRenderingSupported && isDesktopClientConnected;

    const standaloneRenderingCurrentlyPossibile =
      standaloneRenderingSupported && isVrStandaloneDeviceConnected;

    const vrSupported =
      vrCloudRenderingSupported ||
      standaloneRenderingSupported ||
      (desktopRenderingSupported && isVrApp);

    const nonVrSupported =
      launchInBrowserSupported || (desktopRenderingSupported && !isVrApp);

    const isVrCurrentlyPossibile =
      vrSupported &&
      (standaloneRenderingCurrentlyPossibile ||
        vrCloudRenderingCurrentlyPossibile ||
        (desktopRenderingCurrentlyPossibile && isVrApp));

    const isNonVrCurrentlyPossibile =
      nonVrSupported &&
      (launchInBrowserCurrentlyPossibile ||
        (desktopRenderingCurrentlyPossibile && !isVrApp));

    const supportedActions = [
      vrCloudRenderingSupported,
      launchInBrowserSupported,
      desktopRenderingSupported,
      standaloneRenderingSupported,
    ];

    return {
      // the app supports the type and the user has the required permissions
      vrCloudRenderingSupported,
      launchInBrowserSupported,
      desktopRenderingSupported,
      standaloneRenderingSupported,
      cloudRenderingSupported:
        vrCloudRenderingSupported || launchInBrowserSupported,

      vrSupported,
      nonVrSupported,

      // the app suppports the type and the required device is also connected
      vrCloudRenderingCurrentlyPossibile,
      launchInBrowserCurrentlyPossibile,
      desktopRenderingCurrentlyPossibile,
      standaloneRenderingCurrentlyPossibile,

      // any cloud rendering possible
      isCloudRenderingPossible:
        vrCloudRenderingCurrentlyPossibile || launchInBrowserCurrentlyPossibile,

      // any vr / non vr possibile
      isVrCurrentlyPossibile,
      isNonVrCurrentlyPossibile,

      // anything supported at all?
      isAnyActionSupported: supportedActions.some(Boolean),
      // multiple actions supported?
      areMultipleActionsSupported: supportedActions.filter(Boolean).length > 1,
    };
  }, [
    isWindowsVRApp,
    isUserAllowedToStream,
    isNotXR,
    isWinApp,
    isUserAllowedToDownload,
    isStandaloneVrApp,
    isVrStandaloneDeviceConnected,
    isDesktopClientConnected,
    isVrApp,
  ]);

  return supportedActions;
}
