import { Dispatch, SetStateAction, createContext } from "react";

interface ICloudRenderingSessionContext {
  requestAuthentication(): void;
  setLocalConnectionPollingInterval: Dispatch<SetStateAction<number>>;
}

export const DesktopClientContext = createContext(
  {} as ICloudRenderingSessionContext,
);
