import { ButtonProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { routes } from "../utils/routes";
import { LinkButton } from "./LinkButton";

export const ConnectDeviceButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <LinkButton
      variant="outline"
      borderColor={"chakra-body-text"}
      to={routes.connectDevice}
      {...props}
    >
      {t("clients.connect")}
    </LinkButton>
  );
};
