// window.location.hostname

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { domain } from "../config";
import {
  getOrganizationDetails,
  getOrganizationDetailsById,
} from "../portal-api";
import { Organization, PortalError } from "./types";

export function getSubdomain() {
  // during development, we can hardcode the domain via an environment variable
  return import.meta.env.VITE_DOMAIN &&
    window.location.hostname.indexOf(domain) === -1
    ? import.meta.env.VITE_DOMAIN
    : window.location.hostname.replace(`.${domain}`, "");
}

export function useSubdomain() {
  return useMemo(() => getSubdomain(), []);
}

const baseOrganizationsQueryKey = ["organizations"] as const;

export function getQueryKeyForOrganization(id: number | null | undefined) {
  return [...baseOrganizationsQueryKey, id ?? null] as const;
}

export function useActiveOrganizationQuery() {
  const subdomain = useSubdomain();

  return useQuery<Organization, AxiosError<PortalError>>({
    queryKey: [...baseOrganizationsQueryKey, subdomain],
    queryFn: () => getOrganizationDetails(subdomain),
    // the organization details shouldn't change during the lifetime of the app
    staleTime: Infinity,
  });
}

export function useOrganizationQuery(id: Organization["id"]) {
  return useQuery<Organization, AxiosError<PortalError>>({
    queryKey: getQueryKeyForOrganization(id),
    queryFn: () => getOrganizationDetailsById(id),
    // the organization details shouldn't change during the lifetime of the app
    staleTime: Infinity,
  });
}
