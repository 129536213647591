import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import portalApiClient from "../portal-api";
import { ClientApplicationType, PortalBackendPaginatedResult } from "./types";

type ClientApplication = {
  slug: ClientApplicationType;
  name: string;
  current_version: string | null;
};

type ClientApplicationVersionData = {
  version: string;
  binary: string;
  mandatory: boolean;
  changelog: string;
};

const baseQueryKeyForClientApplications = ["client-applications"] as const;

export function useCurrentClientApplicationVersionQuery(
  clientType: ClientApplicationType | undefined,
) {
  return useQuery({
    queryKey: [...baseQueryKeyForClientApplications, clientType, "current"],
    queryFn: async () => {
      const response = await portalApiClient.get<ClientApplicationVersionData>(
        `/api/client-applications/${clientType}/versions/current/`,
      );
      return response.data;
    },
    retry: false,
    enabled: !!clientType,
  });
}

export function useClientApplicationsQuery(
  queryOptions: Partial<
    UseQueryOptions<PortalBackendPaginatedResult<ClientApplication>>
  > = {},
) {
  return useQuery({
    queryKey: [...baseQueryKeyForClientApplications],
    queryFn: async () => {
      const response = await portalApiClient.get<
        PortalBackendPaginatedResult<ClientApplication>
      >(`/api/client-applications/`);
      return response.data;
    },
    retry: false,
    ...queryOptions,
  });
}
