import { useMemo } from "react";
import { useCloudRenderingRegionsQuery } from ".";

export function useCloudProviders() {
  const { data: regions } = useCloudRenderingRegionsQuery();

  return useMemo(() => {
    const cloudProviders = (regions || [])
      ?.map((r) => r.cloudProvider)
      .filter((cp, index, self) => self.indexOf(cp) === index)
      .sort((a, b) => a.localeCompare(b))
      .map((cp) => ({
        name: cp,
        displayName: cp === "Unmanaged" ? "Self-Hosted" : cp,
      }));
    return cloudProviders;
  }, [regions]);
}
