import {
  Box,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { FaChevronRight as ChevronRight } from "react-icons/fa";
import { useCallback, useRef, useEffect } from "react";

interface InnerMenuProps {
  menuButton: MenuItemProps;
  children: JSX.Element[];
}

export const InnerMenu = ({ menuButton, children }: InnerMenuProps) => {
  const refSubMenuButton = useRef<HTMLButtonElement>(null);
  const refSubMenuList = useRef<HTMLDivElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const subMenuButtonEnterHandle = useCallback(() => {
    onOpen();
    if (refSubMenuList.current) {
      refSubMenuList.current.style.pointerEvents = "auto";
    }
  }, [onOpen]);

  const subMenuButtonLeaveHandle = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (refSubMenuList.current) {
      if (!isOpen) {
        refSubMenuList.current.style.pointerEvents = "none";
      }
    }
  }, [isOpen]);

  return (
    <Box pos="relative">
      <Menu autoSelect={false} offset={[0, 0]} isOpen={isOpen} placement="right">
        <MenuButton
          as={MenuItem}
          {...menuButton}
          ref={refSubMenuButton}
          w="full"
          textAlign="left"
          onPointerEnter={subMenuButtonEnterHandle}
          onPointerLeave={subMenuButtonLeaveHandle}
        >
          <HStack justify="space-between">
            {menuButton.children}
            <Icon as={ChevronRight} boxSize={3} />
          </HStack>
        </MenuButton>
        <MenuList
          minW={0}
          ref={refSubMenuList}
          onPointerEnter={subMenuButtonEnterHandle}
          onPointerLeave={subMenuButtonLeaveHandle}
        >
          {children}
        </MenuList>
      </Menu>
    </Box>
  );
};
