import {
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import queryString from "query-string";
import {
  getApplicationBuild,
  getApplicationBuildsPaginated,
  getLaunchConfigurationsForBuild,
} from "../portal-api";
import {
  ApplicationBuild,
  ApplicationBuildId,
  ApplicationBuildListFilters,
  PaginatedApplicationBuildsList,
} from "./types";
import { useApplicationQuery } from "./useApplicationsQuery";

export const baseQueryKeyForApplicationBuilds = ["application-builds"] as const;

export function getQueryKeyForApplicationBuild(applicationBuildId: number) {
  return [...baseQueryKeyForApplicationBuilds, applicationBuildId];
}

export function useApplicationBuildsPaginatedQuery(
  filters: ApplicationBuildListFilters = { page_size: 10 },
  queryOptions: Partial<UseQueryOptions<PaginatedApplicationBuildsList>> = {},
) {
  return useQuery<PaginatedApplicationBuildsList>({
    queryKey: getQueryKeyForApplicationBuildsPaginated(filters),
    queryFn: () => getApplicationBuildsPaginated(filters),
    ...queryOptions,
  });
}

export function getQueryKeyForApplicationBuildsPaginated({
  application,
  supported_xr_platform: xrPlatform,
  ...filters
}: ApplicationBuildListFilters = {}) {
  return [
    ...baseQueryKeyForApplicationBuilds,
    application,
    xrPlatform,
    filters,
  ];
}

export function useInfiniteApplicationBuildsQuery(
  filters: ApplicationBuildListFilters = { page_size: 5 },
) {
  return useInfiniteQuery({
    queryKey: [
      "infinite",
      ...getQueryKeyForApplicationBuildsPaginated(filters),
    ],
    queryFn: async ({ pageParam }) => {
      return await getApplicationBuildsPaginated({
        ...filters,
        page: pageParam,
      });
    },
    initialPageParam: 1,
    retry: false,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.next && queryString.parse(lastPage.next).page;
      return nextPage ? Number(nextPage) : undefined;
    },
  });
}

export function useApplicationBuildQuery(
  applicationBuildId?: ApplicationBuildId,
  queryOptions: Partial<UseQueryOptions<ApplicationBuild, AxiosError>> = {},
) {
  return useQuery<ApplicationBuild, AxiosError>({
    queryKey: getQueryKeyForApplicationBuild(applicationBuildId ?? 0),
    queryFn: () => getApplicationBuild(applicationBuildId ?? 0),
    ...queryOptions,
  });
}

function getQueryKeyForLaunchConfigurationsForApplicationBuild(
  applicationBuildId: ApplicationBuildId,
) {
  return [
    ...getQueryKeyForApplicationBuild(applicationBuildId),
    "launch-configurations",
  ];
}

type PaginatedLaunchConfigurationsForBuild = Awaited<
  ReturnType<typeof getLaunchConfigurationsForBuild>
>;

export function useLaunchConfigurationsForApplicationBuildQuery(
  applicationBuildId?: ApplicationBuildId,
  queryOptions: Partial<
    UseQueryOptions<PaginatedLaunchConfigurationsForBuild>
  > = {},
) {
  return useQuery<PaginatedLaunchConfigurationsForBuild>({
    queryKey: getQueryKeyForLaunchConfigurationsForApplicationBuild(
      applicationBuildId ?? 0,
    ),
    queryFn: () => getLaunchConfigurationsForBuild(applicationBuildId ?? 0),
    ...queryOptions,
  });
}

export function useApplicationForApplicationBuildQuery(
  applicationBuildId: ApplicationBuildId | undefined,
) {
  const { data: applicationBuild, isSuccess } =
    useApplicationBuildQuery(applicationBuildId);
  return useApplicationQuery(applicationBuild?.application ?? "", {
    enabled: isSuccess,
  });
}
