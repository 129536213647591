import { Skeleton, SkeletonProps, useColorModeValue } from "@chakra-ui/react";

export function BrandedSkeleton({ ...props }: SkeletonProps) {
  return (
    <Skeleton
      startColor={useColorModeValue("blackAlpha.50", "whiteAlpha.50")}
      endColor={useColorModeValue("blackAlpha.200", "whiteAlpha.200")}
      {...props}
    />
  );
}
