import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/alert";
import { Box, HStack, Heading, Stack, Text, VStack } from "@chakra-ui/layout";
import { PinInput, PinInputField } from "@chakra-ui/pin-input";
import { Progress } from "@chakra-ui/react";
import log from "loglevel";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import { LinkButton } from "../components";
import { DialogContainer } from "../components/DialogContainer";
import { selectOnlineDevices } from "../features/devicesSlice";
import { useActiveOrganizationQuery, useAppSelector } from "../hooks";
import { verifyDeviceAuthorizationRequest } from "../portal-api";
import type { ClientDevice } from "../session/types";
import { routes } from "../utils/routes";

export function ConnectDevicePage() {
  const [{ "user-code": userCodeQueryParam }] = useQueryParams({
    "user-code": StringParam,
  });
  const [pairingCode, setPairingCode] = useState<string>();
  const [lastPairingCode, setLastPairingCode] = useState("");
  const firstPinInput = useRef<HTMLInputElement | null>(null);
  const [pairingState, setPairingState] = useState<string | null>(null);
  const { t } = useTranslation();
  const connectedDevices = useAppSelector(selectOnlineDevices);
  const connectedDevicesBeforePairing = useRef<ClientDevice[] | null>(
    connectedDevices,
  );
  const { data: organization } = useActiveOrganizationQuery();
  const location = useLocation();
  const isPairing = pairingState === "in-progress";

  const verifyAuthorization = useCallback(
    async (pairingCode: string) => {
      try {
        // remember the current set of connected hmds to be able to compare it later on
        connectedDevicesBeforePairing.current = connectedDevices;
        setLastPairingCode(pairingCode);
        await verifyDeviceAuthorizationRequest(pairingCode, organization);
      } catch (error) {
        setPairingState("failure");
        setPairingCode("");
        // refocus first input element
        firstPinInput.current?.focus();
      }
    },
    [connectedDevices, organization],
  );

  const handlePairing = useCallback(
    async (pairingCode: string) => {
      // TODO: after pairing has been initiated, wait until the headset is connected (but let it timeout after a minute or so)
      setPairingState("in-progress");
      setPairingCode(pairingCode);
      await verifyAuthorization(pairingCode);
    },
    [verifyAuthorization],
  );

  // if user code is given as url param, set it now and start pairing
  useEffect(() => {
    if (!userCodeQueryParam) return;

    if (/^\d{6}$/.test(userCodeQueryParam) === false) return;

    if (userCodeQueryParam.length !== 6) {
      return setPairingCode(userCodeQueryParam);
    }

    // wrap the pairing process in a timeout so we can cancel it if this effect runs multiple times because FUCKING react.
    const pairingTimeout = setTimeout(() => {
      handlePairing(userCodeQueryParam);
    }, 300);

    return () => clearTimeout(pairingTimeout);
  }, [handlePairing, userCodeQueryParam]);

  // If a new device has come online, consider pairing to be successful
  useEffect(() => {
    // if a new device connected, pairing succeeded (not entirely true, but good enough for now)
    if (
      connectedDevicesBeforePairing.current &&
      connectedDevices.length > connectedDevicesBeforePairing.current.length &&
      pairingState === "in-progress"
    ) {
      setPairingState("success");
    }
  }, [connectedDevices, pairingState]);

  // Start a timeout after which the pairing process will be considered failed
  useEffect(() => {
    if (!isPairing) {
      return;
    }
    const timeout = window.setTimeout(
      () => {
        if (isPairing) {
          log.debug("Timeout while waiting for paired headset");
          setPairingState("failure");
        }
      },
      2 * 60 * 1000,
    );

    return () => clearTimeout(timeout);
  }, [isPairing]);

  if (pairingState === "success") {
    return <Navigate to={location.state?.from || routes.home} />;
  }

  return (
    <Stack spacing={4}>
      <Heading as="h2">{t("pairing.title")}</Heading>
      {location.state?.error !== undefined && (
        <Alert status="info">
          <AlertIcon />
          {t("session.pairing." + location.state.error)}
        </Alert>
      )}
      <DialogContainer>
        <Stack
          direction={["column", "column", "row"]}
          alignItems={[null, null, "normal"]}
          justifyContent={[null, null, "normal"]}
        >
          <VStack alignItems={["center", "center", "normal"]} spacing={6}>
            <Text>{t("pairing.instructions")}</Text>
            <HStack justifyContent={"center"}>
              <PinInput
                size={"lg"}
                isDisabled={isPairing}
                isInvalid={pairingState === "failure"}
                focusBorderColor={
                  pairingState === "failure" ? "red.700" : "blue.500"
                }
                onComplete={handlePairing}
                onChange={setPairingCode}
                value={pairingCode}
              >
                {[...Array(6)].map((_, idx) => (
                  <PinInputField
                    key={idx}
                    autoFocus={idx === 0}
                    ref={idx === 0 ? firstPinInput : undefined}
                  />
                ))}
              </PinInput>
            </HStack>
            {pairingState === "in-progress" && (
              <Progress size="xs" isIndeterminate />
            )}
          </VStack>
        </Stack>
        {pairingState === "failure" && (
          <Alert status="error" marginTop={5}>
            <AlertIcon />
            <AlertDescription>
              {t("pairing.failure", { code: lastPairingCode })}
            </AlertDescription>
          </Alert>
        )}
      </DialogContainer>
      <Stack
        direction={["column", "column", "row"]}
        px={[null, null, 5]}
        spacing={5}
        alignItems="center"
      >
        <Box>
          <Text>{t("pairing.client_hint")}</Text>
        </Box>
        <Box>
          <LinkButton
            to={routes.clients.install}
            state={{ from: location.state?.from }}
            flexGrow={1}
            w={["100%", "100%", 60]}
          >
            {t("pairing.install_client")}
          </LinkButton>
        </Box>
      </Stack>
    </Stack>
  );
}
