import { Box, BoxProps } from "@chakra-ui/layout";

export const DialogContainer = (props: BoxProps) => {
  return (
    <Box
      p={[null, null, 5]}
      borderRadius={[null, null, "md"]}
      border={[null, null, "1px solid"]}
      borderColor={"chakra-border-color"}
      {...props}
    ></Box>
  );
};
