import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  HStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export interface UpdateApplicationWarningDialogProps
  extends Omit<AlertDialogProps, "leastDestructiveRef" | "children"> {
  onConfirm(): void;
}

export function UpdateApplicationWarningDialog({
  isOpen,
  onClose,
  onConfirm,
  ...props
}: UpdateApplicationWarningDialogProps) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      {...props}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("session.application_requires_update_dialog.title")}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t("session.application_requires_update_dialog.body")}
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack>
              <Button colorScheme="red" onClick={onConfirm}>
                {t("session.skip")}
              </Button>
              <Button ref={cancelRef} onClick={onClose}>
                {t("session.update")}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
