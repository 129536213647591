import {
  Box,
  chakra,
  HStack,
  Link,
  Stack,
  Text,
  useToken,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../assets/img/innoactive-portal-logo.svg?react";
import config from "../config";
import { usePrivacyAndTerms } from "../hooks";
import { version } from "../utils/version";

const InnoactivePortalLogo = chakra(Logo);

const supportUrl: string =
  config.supportUrl || "http://innoactive.io/portal/support";

export function Footer() {
  const { t } = useTranslation();
  // decide whether we need the font color for dark or white mode and get the actual hex value
  // so we can use it for our logo (SVG) which does not accept chakra's color codes directly
  const [logoColor] = useToken("colors", ["chakra-body-text"], ["white"]);
  const { data: { is_enabled } = {} } = usePrivacyAndTerms();

  return (
    <Stack
      paddingX={5}
      paddingY={3}
      direction={["column", "column", "column", "row"]}
      wrap={[null, null, "wrap", "nowrap"]}
      opacity={0.5}
      justifyContent={["space-evenly", "space-evenly", "space-between"]}
      as="footer"
      marginTop="auto"
      fontSize="sm"
    >
      <Stack
        direction={["column", "column", "row", "row"]}
        alignItems="center"
        spacing={[5, 5, 10]}
        justifyContent={[null, null, "space-evenly"]}
      >
        <Box maxWidth="3xs">
          <InnoactivePortalLogo fill={logoColor} width="100%" />
        </Box>
        <Text align={["center", "center", "left"]}>
          <Trans t={t} i18nKey="footer.powered_by">
            This service is brought to you by Innoactive.{" "}
            <Link href="https://www.innoactive.io/portal">
              innoactive.io/portal
            </Link>
          </Trans>
        </Text>
      </Stack>
      <Stack
        direction={["column", "column", "row", "row"]}
        alignItems="center"
        spacing={[5, 5, 10]}
        justifyContent={[null, null, "space-evenly"]}
      >
        <HStack>
          <Link isExternal href={supportUrl}>
            {t("footer.support")}
          </Link>
          {is_enabled && (
            <>
              <Link as={RouterLink} to="/terms">
                {t("footer.terms")}
              </Link>
              <Link as={RouterLink} to="/privacy">
                {t("footer.privacy")}
              </Link>
            </>
          )}
          <Link as={RouterLink} to="/legal">
            {t("footer.legal")}
          </Link>
        </HStack>
        <Text>{t("footer.version", { version })}</Text>
      </Stack>
    </Stack>
  );
}
