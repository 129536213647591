import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en";
import i18next, { use } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import de from "./locales/de/translation.json";
import en from "./locales/en/translation.json";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  en: { translation: en },
  de: { translation: de },
};

export const supportedLanguages = Object.keys(resources);

// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
export default use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "en",
    debug: import.meta.env.DEV,
    returnNull: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .then(() => {
    dayjs.locale(i18next.language);
  });
