import * as log from "loglevel";
import { useMemo } from "react";
import { shallowEqual } from "react-redux";
import { entries } from "remeda";
import { ZodError, z } from "zod";
import {
  selectRemoteUrls,
  selectRenderingServerIp,
} from "../../features/sessionSlice";
import { useActiveOrganizationQuery, useAppSelector } from "../../hooks";
import { ApplicationId, ApplicationUrlRewriteRule } from "../../hooks/types";
import { useApplicationCloudRenderingConfigurationQuery } from "../../hooks/useApplicationsQuery";

const applicationUrlRewriteRuleSchema = z.object({
  name: z.string().refine(
    (value) => {
      try {
        new RegExp(value);
        return true;
      } catch (e) {
        return false;
      }
    },
    {
      message: "Match must be a valid regular expression (regex)",
    },
  ),
  match: z.string(),
  replace: z.string(),
});

const hubSdkAuthorizationRuleName = "Hub SDK Authorization";

function rewriteUrl(
  url: string,
  rules: Array<Pick<ApplicationUrlRewriteRule, "name" | "match" | "replace">>,
  variables: Record<string, string>,
) {
  return [
    ...rules,
    // inject variables last
    ...entries(variables).map(([key, value]) => ({
      name: key,
      description: `Inject variable ${key} into URL.`,
      match: `\\$${key}`,
      replace: value,
    })),
  ].reduce((acc, rule) => {
    if (rule.name === hubSdkAuthorizationRuleName) {
      // special handling for Hub SDK urls
      const redirectUri = acc.match(new RegExp(rule.match))?.[1];

      if (redirectUri) {
        return acc.replace(
          new RegExp(rule.match),
          rule.replace.replace(
            "$1",
            encodeURIComponent(`?redirect_uri=${redirectUri}`),
          ),
        );
      }
    }

    return acc.replace(new RegExp(rule.match), rule.replace);
  }, url);
}

export function useRemoteUrls(applicationId: ApplicationId | undefined) {
  const { data: cloudRenderingConfiguration } =
    useApplicationCloudRenderingConfigurationQuery(applicationId);
  const serverIp = useAppSelector(selectRenderingServerIp) ?? "127.0.0.1";
  const { data: organization } = useActiveOrganizationQuery();

  const rewriteRules = useMemo<Array<ApplicationUrlRewriteRule>>(() => {
    if (!cloudRenderingConfiguration?.url_rewrites?.rules) {
      return [];
    }

    return (
      (cloudRenderingConfiguration.url_rewrites?.rules ?? [])
        // filter out any invalid rules
        .map((rule) => {
          try {
            return applicationUrlRewriteRuleSchema.parse(rule);
          } catch (e) {
            log.debug("Invalid rewrite rule", rule, (e as ZodError).message);
            return null;
          }
        })
        .filter((rule): rule is ApplicationUrlRewriteRule => !!rule)
    );
  }, [cloudRenderingConfiguration]);

  const remoteUrls = useAppSelector(selectRemoteUrls, {
    equalityFn: shallowEqual,
  });
  const rewrittenUrls = useMemo(() => {
    return remoteUrls.map(({ url, ...rest }) => ({
      ...rest,
      url: rewriteUrl(url, rewriteRules, {
        serverIp,
        organizationDomain: organization?.domain ?? window.location.host,
      }),
    }));
  }, [organization?.domain, remoteUrls, serverIp, rewriteRules]);

  return rewrittenUrls;
}
