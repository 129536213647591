declare global {
  interface Window {
    SPA_RUNTIME_CONFIGURATION: {
      domain: string;
      portalBackendRootUrl: string;
      portalCloudRenderingSessionManagementRootUrl: string;
      portalOAuth2ClientId: string;
      googleMapsApiKey: string;
      desktopClientOAuth2ClientId: string;
      desktopClientOAuth2ClientSecret: string;
      gtmId?: string;
      sentryDSN?: string;
      supportUrl?: string;
      iceServers?: string;
      forceRelayForIce?: boolean;
      showClientApplicationStoreInstallationInstructions?: boolean;
    };
  }
}

export const domain = window.SPA_RUNTIME_CONFIGURATION.domain;

export const portalBackendUrl =
  window.SPA_RUNTIME_CONFIGURATION.portalBackendRootUrl;

export const portalOAuth2ClientId =
  window.SPA_RUNTIME_CONFIGURATION.portalOAuth2ClientId;

export const cloudRenderingSessionManagementServiceUrl =
  window.SPA_RUNTIME_CONFIGURATION.portalCloudRenderingSessionManagementRootUrl;

export const googleMapsApiKey =
  window.SPA_RUNTIME_CONFIGURATION.googleMapsApiKey;

export const desktopClientOAuth2ClientId =
  window.SPA_RUNTIME_CONFIGURATION.desktopClientOAuth2ClientId;

export const desktopClientOAuth2ClientSecret =
  window.SPA_RUNTIME_CONFIGURATION.desktopClientOAuth2ClientSecret;

export const googleTagManagerId = window.SPA_RUNTIME_CONFIGURATION.gtmId;

export const sentryDSN = window.SPA_RUNTIME_CONFIGURATION.sentryDSN;

export const iceServers: RTCIceServer[] | undefined =
  (window.SPA_RUNTIME_CONFIGURATION.iceServers &&
    JSON.parse(window.SPA_RUNTIME_CONFIGURATION.iceServers)) ??
  [];

export const forceRelayForIce =
  window.SPA_RUNTIME_CONFIGURATION.forceRelayForIce ?? false;

export const showClientApplicationStoreInstallationInstructions =
  window.SPA_RUNTIME_CONFIGURATION
    .showClientApplicationStoreInstallationInstructions ?? false;

export default window.SPA_RUNTIME_CONFIGURATION;
