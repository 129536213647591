import { Flex, VStack } from "@chakra-ui/layout";
import { StyleProps } from "@chakra-ui/react";
import { selectConnectionState } from "../../features/interactiveSpectatorSlice";
import { useAppSelector } from "../../hooks";
import { ConnectionState } from "./ConnectionState";

export function ConnectionStateOverlay(props: StyleProps) {
  const { isConnected, connectionState, connectionError } = useAppSelector(
    selectConnectionState,
  );

  return (
    <Flex
      width="full"
      height="full"
      zIndex={1}
      display={isConnected ? "none" : "flex"}
      justifyContent="center"
      alignItems="center"
      transition="all 300 ms"
      margin={0}
      backgroundColor="blackAlpha.700"
      {...props}
    >
      <VStack spacing={2}>
        <ConnectionState error={connectionError} state={connectionState} />
      </VStack>
    </Flex>
  );
}
