import { Flex } from "@chakra-ui/react";
import { Case, Switch } from "../../components";
import { SessionDataExtended } from "../../features/sessionSlice";
import { SESSION_STATE } from "../../session/session-state";
import {
  NoSessionState,
  SessionActiveState,
  SessionEndingState,
  SessionErrorState,
  SessionFallbackState,
  SessionLoadingState,
  SessionReadyState,
} from "./states";

export function SessionPreviewOverlay({
  session,
}: {
  session: SessionDataExtended;
}) {
  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position={{
        base:
          session.state < SESSION_STATE.READY ||
          session.state > SESSION_STATE.ACTIVE
            ? "absolute"
            : "relative",
        md: "absolute",
      }}
      zIndex="1"
      top="0"
      left="0"
    >
      <Switch test={true} fallback={<SessionFallbackState />}>
        <Case
          value={session.isErrored}
          component={<SessionErrorState errorMessage={session.message} />}
        />
        <Case value={!session.isValid} component={<NoSessionState />} />
        <Case
          value={session.isActive}
          component={<SessionActiveState session={session} />}
        />
        <Case
          value={session.isReady}
          component={<SessionReadyState session={session} />}
        />
        <Case
          value={session.isLoading}
          component={<SessionLoadingState session={session} />}
        />
        <Case value={session.isEnding} component={<SessionEndingState />} />
      </Switch>
    </Flex>
  );
}
