import { Icon } from "@chakra-ui/icon";
import { Text } from "@chakra-ui/layout";
import { Button, Link, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaDownload as DownloadIcon } from "react-icons/fa";
import { useCurrentClientApplicationVersionQuery } from "../../hooks";
import { ClientApplicationType } from "../../hooks/types";

const clientType = ClientApplicationType.MicrosoftWindows;

export function InstallationInstructionsDesktopClient() {
  const { t } = useTranslation();
  const { data, isLoading } =
    useCurrentClientApplicationVersionQuery(clientType);

  return (
    <Stack spacing={4}>
      <Text>{t("clients.desktop_client.intro")}</Text>

      <Button
        as={Link}
        target="_blank"
        href={data?.binary}
        width="full"
        colorScheme="brand"
        leftIcon={<Icon as={DownloadIcon} />}
        _hover={{ textDecoration: "none", bgColor: "brand.400" }}
        isLoading={isLoading}
      >
        {t("clients.desktop_client.download_cta")}
      </Button>

      <Text>{t("clients.desktop_client.installation_instructions")}</Text>
    </Stack>
  );
}
