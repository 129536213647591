import { Button, Icon, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdArrowRightAlt as ArrowIcon } from "react-icons/md";
import { ClientDevice } from "../session/types";

export function TargetDeviceSelection({
  targetDevices,
  onTargetDeviceSelected,
}: {
  targetDevices: ClientDevice[];
  onTargetDeviceSelected: (targetDevice: ClientDevice) => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Text fontSize="lg" fontWeight="bold" paddingY={8}>
        {t("launch.select_target_device")}
      </Text>
      <Stack spacing={4}>
        {targetDevices.map((targetDevice) => (
          <Button
            key={targetDevice.identifier}
            value={targetDevice.identifier}
            width="full"
            size="lg"
            colorScheme="brand"
            title={`Select ${targetDevice.name}`}
            role="group"
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            onClick={() => onTargetDeviceSelected(targetDevice)}
          >
            <Text as="span">{targetDevice.name}</Text>
            <Icon
              as={ArrowIcon}
              transition={"all 300ms ease-in-out"}
              opacity={0}
              _groupHover={{ opacity: 1 }}
            />
          </Button>
        ))}
      </Stack>
    </>
  );
}
