import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import portalApiClient from "../portal-api";

type ImageInfo = { src: string; width: number; height: number };

export function useImage(
  imageUrl?: string,
  imageParams?: string,
): UseQueryResult<ImageInfo, Error> {
  let compositeImageUrl = imageUrl;
  if (imageParams && compositeImageUrl) {
    compositeImageUrl += "?" + imageParams;
  }

  const imageQuery = useQuery<ImageInfo, AxiosError>({
    queryKey: ["image", compositeImageUrl],
    queryFn: async () => {
      const img = new Image();
      // TODO: Handle 401
      const imageResponse = await portalApiClient.get(compositeImageUrl || "", {
        responseType: "blob",
      });
      // generate an object url
      const objectURL = URL.createObjectURL(new Blob([imageResponse.data]));
      img.src = objectURL;
      await img.decode();
      return {
        src: img.src,
        width: img.naturalWidth,
        height: img.naturalHeight,
      };
    },
    enabled: !!imageUrl,
    retry: false,
    // images do not change often; we could cache them to avoid unnecessary calls
    staleTime: Infinity, // 1 day
    gcTime: 1000 * 60 * 60 * 24 * 7, // 1 week
  });

  return imageQuery;
}
