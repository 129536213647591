import { CircularProgress } from "@chakra-ui/react";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { SESSION_STATE } from "../session/session-state";

export function SessionRequestPendingIndicator() {
  const { state } = useAppSelector(selectSessionState);

  return state <= SESSION_STATE.REQUESTED ? (
    <CircularProgress isIndeterminate color="brand.500" />
  ) : null;
}
