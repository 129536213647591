import { ApplicationListFilters } from "./types";
import { useApplicationsQuery } from "./useApplicationsQuery";

const searchResultsPageSize = 7;

export function useSearchApplicationQuery(
  searchText?: string,
  filters: Omit<ApplicationListFilters, "searchText"> = {},
) {
  const { page_size, ...restFilters } = filters;
  return useApplicationsQuery({
    fulltext_search: searchText,
    page_size: page_size ?? searchResultsPageSize,
    ...restFilters,
  });
}
