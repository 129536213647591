import {
  Button,
  Circle,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiStreamOn as StreamIcon } from "react-icons/ci";
import { useAuthentication } from "../auth";
import { openConfigurationDialog } from "../features/cloudRenderingSlice";
import { useAppDispatch, useFeatureFlags } from "../hooks";
import { useDestroyVirtualMachineMutation } from "../hooks/useDestroyVirtualMachineMutation";
import { useVirtualMachinesQuery } from "../hooks/useVirtualMachinesQuery";
import { ConfirmMachineDestroyDialog } from "./ConfirmMachineDestroyDialog";
import { CreateRenderMachineDrawer } from "./CreateRenderMachineDrawer";
import { RenderMachineStatusButton } from "./RenderMachineStatusButton";

export function StreamingButton() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [flags, setFlags] = useFeatureFlags();
  const { data: vms } = useVirtualMachinesQuery({}, 10000);
  const destroyVm = useDestroyVirtualMachineMutation();
  const destroyDialog = useDisclosure();
  const createDrawer = useDisclosure();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { user } = useAuthentication();
  const [vmIdToDelete, setVmIdToDelete] = useState<string | undefined>();
  const virtualMachinesForUser = useMemo(
    () =>
      vms?.items.filter(
        (vm) =>
          vm.activeUserIdentifier === user?.id.toString() &&
          vm.expirationDateTime &&
          (vm.state === "Started" ||
            vm.state === "Starting" ||
            vm.state === "Created"),
      ) ?? [],
    [user?.id, vms?.items],
  );
  const personalVmAvailable = virtualMachinesForUser.length > 0;

  const destroyVmAndClearFlag = useCallback(() => {
    if (!vmIdToDelete) {
      return;
    }
    destroyVm.mutate(vmIdToDelete);
    setFlags((flags) => {
      if (flags.virtualMachineId === vmIdToDelete) {
        return flags;
      }

      return {
        ...flags,
        virtualMachineId: undefined,
      };
    });
    destroyDialog.onClose();
  }, [destroyDialog, destroyVm, setFlags, vmIdToDelete]);

  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        trigger="hover"
      >
        <PopoverTrigger>
          <Button
            variant="ghost"
            leftIcon={<Icon as={StreamIcon} boxSize={6} />}
          >
            {t("header.streaming")}
          </Button>
        </PopoverTrigger>
        <PopoverContent w={personalVmAvailable ? "md" : "xs"}>
          <PopoverArrow />
          <PopoverHeader fontWeight="semibold">
            <Text>{t("render_machine.stream_button.sub_heading")}</Text>
          </PopoverHeader>
          <PopoverBody>
            <Stack>
              {personalVmAvailable && (
                <>
                  <Text>
                    {t("render_machine.stream_button.active_machine")}
                  </Text>
                  {virtualMachinesForUser.map((vm, idx) => (
                    <Flex direction="row" alignItems="center" key={idx}>
                      <Text>
                        <span style={{ fontWeight: "bold" }}>
                          {vm.regionDisplayName}
                        </span>{" "}
                        - ({vm.vmSizeDisplayName})
                      </Text>

                      <Tooltip
                        label={
                          vm.isStarted
                            ? t("render_machine.stream_button.vm_started")
                            : t("render_machine.stream_button.vm_starting")
                        }
                      >
                        <Circle
                          bg={vm.isStarted ? "green.400" : "orange.400"}
                          ml={2}
                          size={3}
                          filter="drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))"
                        />
                      </Tooltip>
                      <Spacer />
                      <RenderMachineStatusButton
                        isActiveServer={flags.virtualMachineId === vm.id}
                        onDeleteButtonClick={() => {
                          setVmIdToDelete(vm.id);
                          destroyDialog.onOpen();
                        }}
                        onActiveButtonClick={(active) => {
                          setFlags((flags) => {
                            if (active) {
                              const { virtualMachineId, ...exceptVm } = flags;
                              return {
                                ...exceptVm,
                                virtualMachineId: undefined,
                              };
                            }
                            return {
                              ...flags,
                              virtualMachineId: vm.id,
                            };
                          });
                        }}
                      />
                    </Flex>
                  ))}
                </>
              )}
              <StackDivider />
              {!personalVmAvailable && (
                <Button
                  colorScheme="brand"
                  onClick={() => createDrawer.onOpen()}
                >
                  {t("render_machine.stream_button.create_button")}
                </Button>
              )}
              <Button onClick={() => dispatch(openConfigurationDialog())}>
                {t("details.streaming_preferences")}
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <ConfirmMachineDestroyDialog
        isOpen={destroyDialog.isOpen}
        onConfirm={destroyVmAndClearFlag}
        onClose={destroyDialog.onClose}
      />

      <CreateRenderMachineDrawer {...createDrawer} />
    </>
  );
}
