import { Box, Heading, Stack, Text, VStack } from "@chakra-ui/layout";
import { AbsoluteCenter, Divider } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { DialogContainer, LinkButton } from "../../components";
import { useCurrentClientApplicationVersionQuery } from "../../hooks";
import { ClientApplicationType } from "../../hooks/types";
import { routes } from "../../utils/routes";
import { InstallationInstructionsDesktopClient } from "./InstallationInstructionsDesktopClient";
import { InstallationInstructionsForStandaloneClient } from "./InstallationInstructionsForStandaloneClient";
import { deviceSlugSchema } from "./schemata";
import { useDeviceTranslation } from "./useDeviceTranslation";

export function InstallClientPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const { deviceSlug: deviceSlugString } = useParams();
  const deviceSlug = deviceSlugSchema.parse(deviceSlugString);
  const { isError } = useCurrentClientApplicationVersionQuery(deviceSlug);
  const deviceTranslation = useDeviceTranslation(deviceSlug);

  if (isError) {
    return <Navigate to={routes.clients.install} />;
  }

  return (
    <Stack spacing={8}>
      <Heading as="h2">
        {t("clients.installation_title", deviceTranslation)}
      </Heading>
      <DialogContainer>
        <VStack alignItems="normal" spacing={8}>
          {deviceSlug === ClientApplicationType.MicrosoftWindows ? (
            <InstallationInstructionsDesktopClient />
          ) : (
            <InstallationInstructionsForStandaloneClient />
          )}
          <Box position="relative" padding="4">
            <Divider />
            <AbsoluteCenter px="4" bg="chakra-body-bg">
              {t("or")}
            </AbsoluteCenter>
          </Box>
          <LinkButton to={routes.clients.install} width="full">
            {t("clients.go_to_overview")}
          </LinkButton>
        </VStack>
      </DialogContainer>
      {deviceSlug !== ClientApplicationType.MicrosoftWindows && (
        <Stack
          direction={["column", "column", "row"]}
          px={[null, null, 5]}
          spacing={5}
        >
          <Box>
            <Text>{t("clients.connect_hint")}</Text>
          </Box>
          <Box marginTop="auto">
            <LinkButton
              to={routes.connectDevice}
              state={{ from: location.state?.from }}
              flexGrow={1}
              w={["100%", "100%", 60]}
            >
              {t("clients.connect")}
            </LinkButton>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
