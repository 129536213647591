import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";

export function LatencyDisplay({ latency }: { latency?: number }) {
  return (
    <HStack>
      <Icon as={FaCircle} color={statusColor(getLatencyStatus(latency || 0))} />
      <Box>
        <Text as="b">{!latency ? "n/a" : latency?.toFixed() + " ms"}</Text>
      </Box>
    </HStack>
  );
}
enum Status {
  Unknown,
  Good,
  Ok,
  Bad,
  Critical,
}
const getLatencyStatus = (latency: number) => {
  if (latency <= 0) return Status.Unknown;

  if (latency < 50) return Status.Good;
  if (latency < 70) return Status.Ok;
  if (latency < 100) return Status.Bad;

  return Status.Critical;
};
const statusColor = (status: Status) => {
  switch (status) {
    case Status.Unknown:
      return "gray.500";
    case Status.Good:
      return "green.600";
    case Status.Ok:
      return "green.400";
    case Status.Bad:
      return "orange.400";
  }
  return "red.400";
};
