export enum MouseKeys {
  LeftButton = 0,
  RightButton = 1,
  MiddleButton = 2,
}

export enum ActionType {
  Paste = "paste",
  Copy = "copy",
  Resolution = "resolution",
  CursorChange = "cursor",
  AvailableWindows = "available-windows",
  SetActiveWindow = "set-active-window",
}

export enum CommandType {
  KeyUp = 0,
  KeyDown = 1,
  MouseUp = 2,
  MouseDown = 3,
  MousePosition = 4,
  MouseDelta = 5,
  MouseBind = 6,
  KeyboardLayout = 7,
  PING = 8,
  PONG = 9,
  MouseScroll = 10,
}

interface KeyMap {
  [name: string]: number;
}

export const keys: KeyMap = {
  // we are using a different mouse key input handler and cannot user mouse keys from this
  // enum as they do not map correctly. Please use the mouseKeys instead
  //LBUTTON: 1,
  //RBUTTON: 2,
  CANCEL: 3,
  //MBUTTON: 4,
  XBUTTON1: 5,
  XBUTTON2: 6,
  Backspace: 8,
  Tab: 9,
  CLEAR: 12,
  Enter: 13,
  NumpadEnter: 13,
  SHIFT: 16,
  CONTROL: 17,
  MENU: 18,
  PAUSE: 19,
  CAPITAL: 20,
  KANA: 21,
  HANGEUL: 21,
  HANGUL: 21,
  JUNJA: 23,
  FINAL: 24,
  HANJA: 25,
  KANJI: 25,
  Escape: 27,
  CONVERT: 28,
  NONCONVERT: 29,
  ACCEPT: 30,
  MODECHANGE: 31,
  Space: 32,
  PageUp: 33,
  PageDown: 34,
  End: 35,
  Home: 36,
  ArrowLeft: 37,
  ArrowUp: 38,
  ArrowRight: 39,
  ArrowDown: 40,
  SELECT: 41,
  PRINT: 42,
  EXECUTE: 43,
  SNAPSHOT: 44,
  Insert: 45,
  Delete: 46,
  HELP: 47,
  Digit0: 48,
  Digit1: 49,
  Digit2: 50,
  Digit3: 51,
  Digit4: 52,
  Digit5: 53,
  Digit6: 54,
  Digit7: 55,
  Digit8: 56,
  Digit9: 57,
  KeyA: 65,
  KeyB: 66,
  KeyC: 67,
  KeyD: 68,
  KeyE: 69,
  KeyF: 70,
  KeyG: 71,
  KeyH: 72,
  KeyI: 73,
  KeyJ: 74,
  KeyK: 75,
  KeyL: 76,
  KeyM: 77,
  KeyN: 78,
  KeyO: 79,
  KeyP: 80,
  KeyQ: 81,
  KeyR: 82,
  KeyS: 83,
  KeyT: 84,
  KeyU: 85,
  KeyV: 86,
  KeyW: 87,
  KeyX: 88,
  KeyY: 89,
  KeyZ: 90,
  MetaLeft: 91,
  RWIN: 92,
  APPS: 93,
  SLEEP: 95,
  Numpad0: 96,
  Numpad1: 97,
  Numpad2: 98,
  Numpad3: 99,
  Numpad4: 100,
  Numpad5: 101,
  Numpad6: 102,
  Numpad7: 103,
  Numpad8: 104,
  Numpad9: 105,
  NumpadMultiply: 106,
  NumpadAdd: 107,
  SEPARATOR: 108,
  NumpadSubtract: 109,
  NumpadDecimal: 110,
  NumpadDivide: 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  F13: 124,
  F14: 125,
  F15: 126,
  F16: 127,
  F17: 128,
  F18: 129,
  F19: 130,
  F20: 131,
  F21: 132,
  F22: 133,
  F23: 134,
  F24: 135,
  NumLock: 144,
  SCROLL: 145,
  ShiftLeft: 160,
  ShiftRight: 161,
  ControlLeft: 162,
  ControlRight: 163,
  AltLeft: 164,
  AltRight: 165,
  BROWSER_BACK: 166,
  BROWSER_FORWARD: 167,
  BROWSER_REFRESH: 168,
  BROWSER_STOP: 169,
  BROWSER_SEARCH: 170,
  BROWSER_FAVORITES: 171,
  BROWSER_HOME: 172,
  VOLUME_MUTE: 173,
  VOLUME_DOWN: 174,
  VOLUME_UP: 175,
  MEDIA_NEXT_TRACK: 176,
  MEDIA_PREV_TRACK: 177,
  MEDIA_STOP: 178,
  MEDIA_PLAY_PAUSE: 179,
  LAUNCH_MAIL: 180,
  LAUNCH_MEDIA_SELECT: 181,
  LAUNCH_APP1: 182,
  LAUNCH_APP2: 183,
  Semicolon: 186,
  OEM_PLUS: 187,
  Comma: 188,
  Minus: 189,
  Period: 190,
  Slash: 191,
  OEM_3: 192,
  BracketLeft: 219,
  Backslash: 220,
  BracketRight: 221,
  Quote: 222,
  OEM_8: 223,
  OEM_102: 226,
  PROCESSKEY: 229,
  PACKET: 231,
  ATTN: 246,
  CRSEL: 247,
  EXSEL: 248,
  EREOF: 249,
  PLAY: 250,
  ZOOM: 251,
  NONAME: 252,
  PA1: 253,
  OEM_CLEAR: 254,
};
