import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import * as Sentry from "@sentry/react";
import { EventEmitter } from "events";
import log from "loglevel";
import { cloudRenderingSessionManagementServiceUrl } from "../../config";

export enum SignalerType {
  SessionManagement = "session-management",
  WebsocketSelfhosted = "websocket-selfhosted",
}

export enum SignalingEventType {
  IceCandidateReceived = "icecandidatereceived",
  OfferReceived = "offerreceived",
  ConnectionError = "connectionerror",
}

type IncomingMessage = {
  sessionId: string;
  sourceConnectionId: string;
  payload: string;
};

type OutgoingMessage = {
  sessionId: string;
  connectionId: string;
  payload: string;
};

type InitMessage = {
  sessionId: string;
  clientType: string | null;
};

type IceCandidatePayload = {
  type: "ice";
  candidate: string;
};

type OfferPayload = {
  type: "sdp";
  answer: string;
};

export interface SignalerInterface extends EventEmitter {
  sendIceCandidate(candidate: RTCIceCandidate): Promise<void>;
  sendOfferAnswer(answer: RTCSessionDescriptionInit): Promise<void>;
  start(): Promise<void>;
  stop(): Promise<void>;
}

export class Signaler extends EventEmitter implements SignalerInterface {
  CONNECTION_TIMEOUT_IN_MS = 15000;

  websocket: WebSocket | null = null;
  connection: HubConnection;
  sessionId: string;
  serverConnectionId?: string;
  timeout: NodeJS.Timeout | null = null;

  constructor(sessionId: string, serverIp: string) {
    super();
    this.sessionId = sessionId;
    this.connection = new HubConnectionBuilder()
      .withUrl(`${cloudRenderingSessionManagementServiceUrl}/hub/signaling`, {
        withCredentials: false,
      })

      .build();

    this.connection.on("Receive", async (message: IncomingMessage) => {
      this.serverConnectionId = message.sourceConnectionId;
      const payload: IceCandidatePayload | OfferPayload = JSON.parse(
        message.payload,
      );
      if (payload["type"] === "ice") {
        this.emit(
          SignalingEventType.IceCandidateReceived,
          new RTCIceCandidate({
            ...payload,
            candidate: replacePrivateByPublicIp(payload.candidate, serverIp),
          }),
        );
      } else if (payload["type"] === "sdp") {
        this.timeout && clearTimeout(this.timeout);
        this.emit(SignalingEventType.OfferReceived, {
          ...payload,
          answer: replacePrivateByPublicIp(payload.answer, serverIp),
        });
      }
    });

    this.connection.on("Init", (message: InitMessage) => {
      if (message.clientType && message.clientType === "server") {
        const initMessage: InitMessage = {
          sessionId: this.sessionId,
          clientType: "client",
        };
        this.connection.send("Init", initMessage);
      }
    });
  }

  async sendIceCandidate(candidate: RTCIceCandidate) {
    if (!this.serverConnectionId) {
      throw new Error("Server connection ID not set");
    }

    const iceCandidatePayload = {
      type: "ice",
      candidate: candidate.candidate,
      sdpMLineindex: candidate.sdpMLineIndex,
      sdpMid: candidate.sdpMid,
    };

    const iceCandidateMessage: OutgoingMessage = {
      sessionId: this.sessionId,
      connectionId: this.serverConnectionId,
      payload: JSON.stringify(iceCandidatePayload),
    };
    return this.connection.send("Send", iceCandidateMessage);
  }

  async sendOfferAnswer(answer: RTCSessionDescriptionInit) {
    if (!this.serverConnectionId) {
      throw new Error("Server connection ID not set");
    }

    const offerAnswerPayload = {
      type: "sdp",
      answer: answer.sdp,
    };

    const offerAnswerMessage: OutgoingMessage = {
      sessionId: this.sessionId,
      connectionId: this.serverConnectionId,
      payload: JSON.stringify(offerAnswerPayload),
    };
    return this.connection.send("Send", offerAnswerMessage);
  }

  async start() {
    return this.connection
      .start()
      .then(() => {
        const initMessage: InitMessage = {
          sessionId: this.sessionId,
          clientType: "client",
        };
        this.connection.send("Init", initMessage);
        this.timeout = setTimeout(() => {
          log.warn("Signaler timeout, server did not respond");
          this.emit(
            SignalingEventType.ConnectionError,
            "Server did not respond",
          );
        }, this.CONNECTION_TIMEOUT_IN_MS);
      })
      .catch((err) => {
        // send the error to sentry
        Sentry.captureException(
          new Error("Connection to signaling service failed"),
        );
        this.emit(SignalingEventType.ConnectionError, err);
      });
  }

  async stop() {
    return this.connection.stop();
  }
}

export class LocalWebsocketSignaler
  extends EventEmitter
  implements SignalerInterface
{
  websocket: WebSocket | null = null;
  serverIp: string;

  constructor(serverIp: string = "127.0.0.1") {
    super();
    this.serverIp = serverIp;
  }

  async sendIceCandidate(candidate: RTCIceCandidate) {
    this.websocket?.send(
      JSON.stringify({
        type: "ice",
        candidate: candidate.candidate,
        sdpMLineindex: candidate.sdpMLineIndex,
        sdpMid: candidate.sdpMid,
      }),
    );
  }

  async sendOfferAnswer(answer: RTCSessionDescriptionInit) {
    this.websocket?.send(JSON.stringify({ type: "sdp", answer: answer.sdp }));
  }

  async start() {
    const websocket = new WebSocket(
      new URL(`ws://${this.serverIp}:28123/`),
      [],
    );
    this.websocket = websocket;

    websocket.onmessage = async (evt) => {
      const payload = JSON.parse(evt.data);
      if (payload["type"] === "ice") {
        this.emit(
          SignalingEventType.IceCandidateReceived,
          new RTCIceCandidate({
            ...payload,
            candidate: replacePrivateByPublicIp(
              payload.candidate,
              this.serverIp,
            ),
          }),
        );
      } else if (payload["type"] === "sdp") {
        this.emit(SignalingEventType.OfferReceived, {
          ...payload,
          answer: replacePrivateByPublicIp(payload.answer, this.serverIp),
        });
      }
    };

    // We'll only receive an offer after sending the initial init message
    websocket.onopen = async () => {
      websocket.send(JSON.stringify({ type: "init" }));
    };

    websocket.onclose = () => (this.websocket = null);

    websocket.onerror = (event) => {
      // send the error to sentry
      Sentry.captureException(
        new Error("Connection to signaling service failed"),
      );
      this.emit(SignalingEventType.ConnectionError, event);
    };
  }

  async stop() {
    if (!this.websocket) return;
    // unset the error handler as otherwise, we'll get an error there
    this.websocket.onerror = null;
    this.websocket.close();
  }
}

/**
 * Replace the private IP address in the ICE candidate string with the public one.
 * This is needed because the ICE candidate contains the local IP address, which is not reachable from the client.
 *
 * @param candidate ICE candidate string, like "2343 1 udp 659136 1.2.3.4 57380 typ host generation 0\r\n"
 * @param serverIp The (public) IP of the server
 * @returns The ICE candidate string with the private IP replaced by the public one
 */
function replacePrivateByPublicIp(candidate: string, serverIp: string) {
  const regex = /\d+ \d+ udp \d+ ([\d.]+) \d+ typ host/g;
  const answer = candidate.replace(regex, (match, ip) => {
    if (isPrivateIp(ip)) {
      return match.replace(ip, serverIp);
    } else {
      return match;
    }
  });
  return answer;
}

function isPrivateIp(address: string | null) {
  if (!address) return false;

  const privateIpPattern = /^(10\.|192\.168\.|172\.(1[6-9]|2[0-9]|3[0-1])\.)/;
  return privateIpPattern.test(address);
}
