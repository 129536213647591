import {
  Box,
  HStack,
  Icon,
  IconButton,
  Select,
  Stack,
  StackProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Table as ReactTable, flexRender } from "@tanstack/react-table";
import {
  FaAngleDoubleLeft as GoToFirstIcon,
  FaAngleDoubleRight as GoToLastIcon,
  FaAngleRight as GoToNextIcon,
  FaAngleLeft as GoToPrevIcon,
  FaCaretDown as TriangleDownIcon,
  FaCaretUp as TriangleUpIcon,
} from "react-icons/fa";

export function PaginatedTable<T>({
  table,
  ...props
}: { table: ReactTable<T> } & StackProps) {
  if (table.getRowModel().rows.length === 0) {
    return null;
  }

  return (
    <Stack {...props}>
      <TableContainer>
        <Table size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr
                borderLeft="1px"
                borderColor={"transparent"}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    cursor={header.column.getCanSort() ? "pointer" : undefined}
                    onClick={header.column.getToggleSortingHandler()}
                    {...(header.column.columnDef.meta?.props ?? {})}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                    {{
                      asc: (
                        <>
                          <Text as="span"> </Text>
                          <Icon as={TriangleUpIcon} />
                        </>
                      ),
                      desc: (
                        <>
                          <Text as="span"> </Text>
                          <Icon as={TriangleDownIcon} />
                        </>
                      ),
                    }[header.column.getIsSorted() as string] ?? null}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                _hover={{
                  borderColor: "brand-bg",
                }}
                borderLeft="1px"
                borderColor={"transparent"}
                transition="all 250ms"
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    {...(cell.column.columnDef.meta?.props ?? {})}
                    whiteSpace={"normal"}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <HStack justifyContent="space-between">
        <HStack>
          {table.getPageCount() > 1 && (
            <>
              <IconButton
                variant="outline"
                icon={<Icon as={GoToFirstIcon} />}
                onClick={() => table.setPageIndex(0)}
                isDisabled={!table.getCanPreviousPage()}
                aria-label="Go to first page"
                size="sm"
              />
              <IconButton
                variant="outline"
                icon={<Icon as={GoToPrevIcon} />}
                onClick={() => table.previousPage()}
                isDisabled={!table.getCanPreviousPage()}
                aria-label="Go to previous page"
                size="sm"
              />
              <Box flexGrow={1}>
                <Text>
                  {"Page "}
                  <strong>
                    {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount()}
                  </strong>
                </Text>
              </Box>
              <IconButton
                variant="outline"
                icon={<Icon as={GoToNextIcon} />}
                onClick={() => table.nextPage()}
                isDisabled={!table.getCanNextPage()}
                aria-label="Go to next page"
                size="sm"
              />
              <IconButton
                variant="outline"
                icon={<Icon as={GoToLastIcon} />}
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                isDisabled={!table.getCanNextPage()}
                aria-label="Go to last page"
                size="sm"
              />
            </>
          )}
        </HStack>
        {table.getPageCount() > 5 && (
          <HStack>
            <Text>Go to page:</Text>
            <Select
              size="sm"
              width="auto"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
            >
              {table.getPageOptions().map((pageIndex) => (
                <option key={pageIndex} value={pageIndex}>
                  {pageIndex}
                </option>
              ))}
            </Select>
          </HStack>
        )}
        {table.getPageCount() > 1 && (
          <Select
            size="sm"
            width="auto"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[
              ...new Set([
                10,
                20,
                25,
                50,
                100,
                200,
                table.getState().pagination.pageSize,
              ]),
            ]
              .sort((a, b) => a - b)
              .map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
          </Select>
        )}
      </HStack>
    </Stack>
  );
}
