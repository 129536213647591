import Bowser from "bowser";
import "webrtc-adapter";

// eslint-disable-next-line import/no-named-as-default-member
const parser = Bowser.getParser(window.navigator.userAgent);
// fullscreen mode on ios is really bad so we need to distinguish that, unfortunately ipads appear as desktop pc so we need the second check as well
// see https://stackoverflow.com/a/9039885/1142028
export const isIOS =
  parser.getOSName(true) === "ios" ||
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);
export const isMobile = parser.getPlatformType(true) === "mobile";

const operatingSystemName = parser.getOSName();

export const isWindows = operatingSystemName === "Windows";
