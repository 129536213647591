import { Button, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon } from "../../components/icons";

const clientViveBusinessAppStoreUrl =
  "https://business.vive.com/appstore/detail/4aceb592-2cb3-4f26-af12-fe8fd1935678";

export function InstallationInstructionsViveBusinessAppStore() {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Text>
        {t("clients.installation_methods.vive_business_appstore.text")}
      </Text>
      <Link isExternal href={clientViveBusinessAppStoreUrl}>
        <Button
          colorScheme="brand"
          width="full"
          textDecoration={"none"}
          rightIcon={<Icon as={ExternalLinkIcon} boxSize={3} />}
        >
          {t("clients.installation_methods.vive_business_appstore.cta")}
        </Button>
      </Link>
    </Stack>
  );
}
