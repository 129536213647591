import { Icon, IconButton, IconButtonProps } from "@chakra-ui/react";
import {
  FaMicrophoneSlash as MicrophoneMutedIcon,
  FaMicrophone as MicrophoneUnmutedIcon,
} from "react-icons/fa";

export function MicrophoneButton({
  isMicrophoneEnabled,
  isMicrophoneMuted,
  isMicrophoneSupported,
  toggleAudioInput,
  ...props
}: {
  isMicrophoneEnabled: boolean;
  isMicrophoneMuted: boolean;
  isMicrophoneSupported: boolean;
  toggleAudioInput: () => void;
} & Pick<IconButtonProps, "isDisabled">) {
  return (
    <IconButton
      disabled={!isMicrophoneSupported}
      onClick={() => toggleAudioInput()}
      icon={
        <Icon
          as={
            !isMicrophoneEnabled || isMicrophoneMuted
              ? MicrophoneMutedIcon
              : MicrophoneUnmutedIcon
          }
        />
      }
      paddingX={4}
      {...{
        "aria-label":
          !isMicrophoneEnabled || isMicrophoneMuted
            ? "Unmute Microphone"
            : "Mute Microphone",
      }}
      {...props}
    />
  );
}
