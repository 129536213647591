import {
  Box,
  Heading,
  LinkBox,
  LinkBoxProps,
  LinkOverlay,
  Stack,
} from "@chakra-ui/layout";
import {
  AspectRatio,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  StyleProps,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { IconBaseProps, IconType } from "react-icons";
import {
  BsHeadsetVr as BaseVrIcon,
  BsDisplay as DesktopIcon,
} from "react-icons/bs";
import { Link as RouterLink, generatePath } from "react-router-dom";
import {
  useAppPermissions,
  useAppSupportedActions,
  useApplicationQuery,
} from "../hooks";
import { Application, ApplicationId } from "../hooks/types";
import { routes } from "../utils/routes";
import { ApplicationImage } from "./ApplicationImage";
import { BrandedSkeleton } from "./BrandedSkeleton";
import { RequestAccessIcon } from "./icons";

const VrIcon = (props: IconBaseProps) => (
  <BaseVrIcon viewBox="0 0 16 13" {...props} />
);

function SupportedDeviceTypeIcon({
  icon,
  ...props
}: { icon: IconType } & IconProps) {
  return <Icon boxSize={6} as={icon} {...props} />;
}

function SupportedDeviceTypeIcons({ app }: { app: Application }) {
  const supportedActions = useAppSupportedActions(app);

  if (!supportedActions.vrSupported && !supportedActions.nonVrSupported) {
    return null;
  }

  return (
    <Stack
      padding={1}
      right={2}
      bottom={2}
      spacing={2}
      backgroundColor={"chakra-body-text"}
      color={"chakra-body-bg"}
      position="absolute"
      rounded="md"
      direction="row"
    >
      {supportedActions.vrSupported && (
        <SupportedDeviceTypeIcon icon={VrIcon} />
      )}
      {supportedActions.nonVrSupported && (
        <SupportedDeviceTypeIcon icon={DesktopIcon} />
      )}
    </Stack>
  );
}

function useIsAppWithoutPermissions(app?: Application) {
  const permissions = useAppPermissions(app);
  return !permissions.stream && !permissions.download;
}

function RequestAccessIndicator({
  app,
  ...props
}: { app: Application } & FlexProps) {
  const isAppWithoutPermissions = useIsAppWithoutPermissions(app);

  if (isAppWithoutPermissions) {
    return (
      <Flex
        position="absolute"
        width={40}
        height={10}
        top={0}
        left={-10}
        paddingBottom={2}
        backgroundColor={"chakra-body-text"}
        color={"chakra-body-bg"}
        rounded="md"
        alignItems="end"
        justifyContent="center"
        transform={"rotateZ(-30deg) translate(-.8rem, -1rem)"}
        borderRadius={0}
        {...props}
      >
        <Icon as={RequestAccessIcon} boxSize={4} transform={"rotateZ(30deg)"} />
      </Flex>
    );
  }

  return null;
}

interface AppPreviewProps extends Omit<LinkBoxProps, "width"> {
  appId: ApplicationId;
  url?: string;
  width?: StyleProps["width"];
}

export function LazyApplicationPreview({
  appId,
  width = [235, 245, 250],
  url = generatePath(routes.application.details, { id: appId }),
  ...props
}: AppPreviewProps) {
  const {
    data: app,
    isError,
    error,
    isLoading,
    isSuccess,
  } = useApplicationQuery(appId);

  if (isLoading) {
    return <AppPreviewSkeleton width={width} />;
  }

  if (isError) {
    return (
      <LinkBox role="group" width={width} {...props}>
        <AspectRatio ratio={16 / 10} marginBottom={4}>
          <Box borderRadius={5}>
            <BrandedSkeleton height="full" width="full" />
          </Box>
        </AspectRatio>
        <Heading as="h4" size="sm">
          {error?.response?.status && error?.response?.status === 401
            ? "Unauthorized"
            : "Error while loading"}
        </Heading>
      </LinkBox>
    );
  }

  if (isSuccess) {
    return <ApplicationPreview application={app} width={width} url={url} />;
  }

  return null;
}

export function ApplicationPreviewImage({
  application: app,
}: {
  application: Application;
}) {
  const isAppWithoutPermissions = useIsAppWithoutPermissions(app);
  return (
    <AspectRatio ratio={16 / 10} marginBottom={4}>
      <Box borderRadius={5}>
        <ApplicationImage
          src={app?.images[0]?.image}
          imageParams="width=400"
          fit="cover"
          height="full"
          width="full"
          opacity={isAppWithoutPermissions ? 0.5 : 1}
          _groupHover={{ opacity: 1 }}
          transition={"opacity 0.2s ease-in-out"}
        />
        {app && <SupportedDeviceTypeIcons app={app} />}
        {app && (
          <RequestAccessIndicator
            app={app}
            _groupHover={{ boxShadow: "md" }}
            transition={"opacity 0.2s ease-in-out"}
          />
        )}
      </Box>
    </AspectRatio>
  );
}

export function ApplicationPreview({
  application: app,
  width = [235, 245, 250],
  url = generatePath(routes.application.details, { id: app.id }),
  ...props
}: Omit<AppPreviewProps, "appId"> & { application: Application }) {
  return (
    <LinkBox
      as={motion.div}
      layoutScroll
      animate={{ scale: 1, opacity: 1 }}
      initial={{ scale: 0, opacity: 0.2 }}
      whileHover={{ scale: 1.05, x: 5 }}
      role="group"
      width={width}
      {...props}
    >
      <ApplicationPreviewImage application={app} />

      <LinkOverlay as={RouterLink} to={url}>
        <Heading as="h4" size="sm">
          {app.name}
        </Heading>
      </LinkOverlay>
    </LinkBox>
  );
}

export function AppPreviewSkeleton(props: StyleProps) {
  return (
    <Box {...props}>
      <AspectRatio ratio={16 / 10} marginBottom={4}>
        <BrandedSkeleton borderRadius={5}></BrandedSkeleton>
      </AspectRatio>
      <BrandedSkeleton>
        <Heading as="h4" size="sm">
          Loading
        </Heading>
      </BrandedSkeleton>
    </Box>
  );
}
