import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  useEncryptVrStreamSetting,
  useFeatureFlags,
  useOrganizationCloudRenderingPolicies,
} from "../hooks";

export function StreamingSecurityPreferences() {
  const { t } = useTranslation();
  const [, setFlags] = useFeatureFlags();
  const { data: policies, isSuccess } = useOrganizationCloudRenderingPolicies();
  const canUserChangeEncryptionSetting =
    policies?.cloudXREncryption === "OptIn" ||
    policies?.cloudXREncryption === "OptOut";
  const encryptVrStreamSetting = useEncryptVrStreamSetting();

  return (
    <FormControl as="fieldset">
      <Stack spacing={4} paddingTop={4}>
        <HStack>
          <Switch
            id="encrypt-vr-stream"
            isChecked={encryptVrStreamSetting}
            onChange={(evt) =>
              setFlags((flags) => ({
                ...flags,
                encryptVrStream: evt.target.checked,
              }))
            }
            isDisabled={isSuccess && !canUserChangeEncryptionSetting}
          />
          <FormLabel htmlFor="encrypt-vr-stream" margin={0}>
            {t("streamingPreferences.security.encrypt_vr_stream")}
          </FormLabel>
        </HStack>
        <FormHelperText marginTop={0}>
          {canUserChangeEncryptionSetting
            ? encryptVrStreamSetting === false
              ? t(
                  "streamingPreferences.security.encrypt_vr_stream_help_text_disabled",
                )
              : t(
                  "streamingPreferences.security.encrypt_vr_stream_help_text_enabled",
                )
            : t(
                "streamingPreferences.security.policies_deny_encrypt_vr_stream_change",
              )}
        </FormHelperText>
      </Stack>
    </FormControl>
  );
}
