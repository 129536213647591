import { ImageProps } from "@chakra-ui/react";
import { useMemo } from "react";
import { useBranding } from "../../branding";
import { ApplicationImage } from "../../components";
import { useApplicationForApplicationBuildQuery } from "../../hooks/useApplicationBuildsQuery";
import { SessionData } from "../../session/types";

export function SessionPreviewApplicationImage({
  applicationBuildId,
  ...props
}: Pick<SessionData, "applicationBuildId"> & ImageProps) {
  const { data: app } =
    useApplicationForApplicationBuildQuery(applicationBuildId);
  const { data: branding } = useBranding();
  const hasApplicationImage = useMemo(() => {
    return app?.images?.[0]?.image || branding?.app_image_placeholder;
  }, [app?.images, branding]);

  return (
    <ApplicationImage
      src={app?.images?.[0]?.image}
      filter={hasApplicationImage ? "brightness(0.8) blur(10px)" : undefined}
      alt={app?.name}
      objectFit="cover"
      objectPosition="center"
      borderRadius={5}
      flexGrow={1}
      {...props}
    />
  );
}
