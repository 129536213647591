import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { googleMapsApiKey } from "../config";
import { selectCloudRendering } from "../features/cloudRenderingSlice";
import { useAppSelector } from "../hooks";
import { type Region } from "../hooks/useCloudRenderingRegionsQuery";
import { BrandedSkeleton } from "./BrandedSkeleton";
import { LatencyDisplay } from "./LatencyDisplay";

const azureCloudColor = "#008AD7";
const awsCloudClour = "#FF9900";
const googleCloudClor = "#34A853";

interface CloudRenderingRegionsMapProps {
  regions: Region[] | undefined;
  preferredCloudRenderingRegionName: string | undefined;
  setCloudRenderingRegion(regionName: string): void;
  isLoading: boolean;
}

export function CloudRenderingRegionsMap({
  regions = [],
  preferredCloudRenderingRegionName,
  setCloudRenderingRegion,
  isLoading,
}: CloudRenderingRegionsMapProps) {
  const { t } = useTranslation();
  const cloudRendering = useAppSelector(selectCloudRendering);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
  });
  const [focussedRegionName, setFocussedRegionName] = useState<string | null>(
    null,
  );
  const map = useRef<google.maps.Map | null>(null);

  // focus the map onto the preferred region whenever it changes
  useEffect(() => {
    const region = regions.find(
      (r) => r.name === preferredCloudRenderingRegionName,
    );

    if (!region) return;

    map.current?.panTo({
      lat: region.latitude,
      lng: region.longitude,
    });

    // also focus it
    setFocussedRegionName(region.name);
  }, [preferredCloudRenderingRegionName, regions]);

  const onMapLoad = useCallback(
    function callback(_map: google.maps.Map) {
      map.current = _map;

      const bounds = new google.maps.LatLngBounds();
      regions.forEach((region) =>
        bounds.extend({ lat: region.latitude, lng: region.longitude }),
      );

      _map.fitBounds(bounds);
    },
    [regions],
  );

  if (loadError || (isLoaded && "google" in window === false)) {
    return (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription>
          <>Map cannot be loaded at the moment. Details: {loadError}</>
        </AlertDescription>
      </Alert>
    );
  }

  // we need a custom marker / symbol to change its color depending on the cloud provider
  // https://developers.google.com/maps/documentation/javascript/reference/marker#Symbol
  const markerSymbol: google.maps.Symbol | undefined =
    isLoaded && "google" in window
      ? {
          // this is font awesome's FaMapMarkerAlt's svg path.
          path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
          // viewport of svg is 384 x 512, we want it anchored at the bottom center
          anchor: new window.google.maps.Point(384 / 2, 512),
        }
      : undefined;

  return isLoaded ? (
    <>
      <GoogleMap
        zoom={5}
        onLoad={onMapLoad}
        mapContainerStyle={{ width: "100%", height: "400px" }}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          maxZoom: 10,
          minZoom: 2,
        }}
      >
        {regions.map((region) => {
          const position = { lat: region.latitude, lng: region.longitude };
          const isPreferredRegion =
            region.name === preferredCloudRenderingRegionName;
          const latency = cloudRendering.regionsLatency[region?.name];

          return (
            <Fragment key={region.name}>
              <Marker
                key={region.name}
                position={position}
                onClick={() => {
                  // focus the map onto the marker
                  map.current?.panTo(position);
                  // show the respective info window
                  setFocussedRegionName(region.name);
                }}
                icon={
                  {
                    ...markerSymbol,
                    strokeColor: "black",
                    strokeWeight: 0.3,
                    fillColor:
                      region.isEnabled === false
                        ? "gray"
                        : isPreferredRegion
                        ? "black"
                        : region.cloudProvider === "AWS"
                        ? awsCloudClour
                        : region.cloudProvider === "Azure"
                        ? azureCloudColor
                        : region.cloudProvider === "GoogleCloud"
                        ? googleCloudClor
                        : "gray",
                    fillOpacity: 1,
                    scale: 0.08,
                  } as google.maps.Symbol
                }
                cursor={region.isEnabled === false ? "not-allowed" : "pointer"}
              />
              {focussedRegionName === region.name && (
                <InfoWindow
                  position={position}
                  onCloseClick={() => setFocussedRegionName(null)}
                >
                  <Stack color="black">
                    <Heading size="xs">
                      {region.displayName} ({region.cloudProvider})
                    </Heading>
                    <HStack>
                      <Text>{t("streamingPreferences.map.distance")}: </Text>
                      <Text fontWeight="bold">
                        {(region.distanceM / 1000).toFixed()} km
                      </Text>
                    </HStack>
                    <HStack>
                      <Text>{t("streamingPreferences.map.latency")}: </Text>
                      <LatencyDisplay latency={latency} />
                    </HStack>
                    <Button
                      isLoading={isLoading}
                      onClick={() => setCloudRenderingRegion(region.name)}
                      isDisabled={
                        isPreferredRegion || region.isEnabled === false
                      }
                      colorScheme={isPreferredRegion ? "green" : "brand"}
                      leftIcon={isPreferredRegion ? <FaCheck /> : undefined}
                    >
                      {isPreferredRegion
                        ? t("streamingPreferences.map.ispreferred")
                        : region.isEnabled
                        ? t("streamingPreferences.map.setaspreferred")
                        : t("streamingPreferences.map.disabled")}
                    </Button>
                  </Stack>
                </InfoWindow>
              )}
            </Fragment>
          );
        })}
      </GoogleMap>
    </>
  ) : (
    <BrandedSkeleton isLoaded={isLoaded} />
  );
}
