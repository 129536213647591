import { Stack } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SessionDataExtended } from "../../../features/sessionSlice";
import { useSessionDevice } from "../../../hooks";
import { useApplicationForApplicationBuildQuery } from "../../../hooks/useApplicationBuildsQuery";

export function SessionActiveState({
  session,
}: {
  session: SessionDataExtended;
}) {
  const { t } = useTranslation();
  const { data: app } = useApplicationForApplicationBuildQuery(
    session.applicationBuildId,
  );
  const device = useSessionDevice();

  return (
    <Box
      backgroundColor="backgroundAlpha.500"
      width={{ base: "100%", md: "auto" }}
    >
      <Alert
        status="success"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {t("session.running")}
        </AlertTitle>
        <Stack spacing={5} alignItems="center">
          <AlertDescription maxWidth="sm">
            {!session.isBrowser ? (
              <Text>
                {t("session.app_running", {
                  name: app?.name ?? "Unknown",
                  device: device?.name ?? "Unknown",
                })}
              </Text>
            ) : (
              <Text>
                {t("session.app_running_browser", {
                  name: app?.name ?? "Unknown",
                })}
              </Text>
            )}
          </AlertDescription>
        </Stack>
      </Alert>
    </Box>
  );
}
