import { portalBackendUrl } from "../config";
import { AuthenticationProvider } from "./auth";
import { useAuthentication } from "./AuthenticationContext";
import Login from "./Login";
import Logout from "./Logout";
import OAuth2AuthCodeCallback from "./Oauth2AuthCodeCallback";
import type { IAuthenticationContext, UserDetails } from "./types";

function getOrganizationSelectionUrl(redirectUrl: string) {
  return `${portalBackendUrl}/control-panel/portal-frontend/?next=${redirectUrl}`;
}

function redirectToOrganizationSelection(redirectUrl: string) {
  window.location.replace(getOrganizationSelectionUrl(redirectUrl));
  return null;
}

export {
  AuthenticationProvider,
  getOrganizationSelectionUrl,
  IAuthenticationContext,
  Login,
  Logout,
  OAuth2AuthCodeCallback,
  redirectToOrganizationSelection,
  useAuthentication,
  UserDetails,
};
