import { useQuery } from "@tanstack/react-query";
import { useAuthentication } from "../auth";
import { fetchPrivacyAndTerms } from "../portal-api";
import { PortalPrivacyAndTerms } from "./types";
import { useActiveOrganizationQuery } from "./useActiveOrganizationQuery";

export function usePrivacyAndTerms() {
  const { data: organization } = useActiveOrganizationQuery();
  const { user } = useAuthentication();

  return useQuery<PortalPrivacyAndTerms>({
    queryKey: ["privacy-and-terms", organization?.id],
    queryFn: () => fetchPrivacyAndTerms({ organization: organization?.id }),
    enabled: !!organization?.id && !!user,
    retry: false,
  });
}
