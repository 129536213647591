import { useTranslation } from "react-i18next";
import { ClientApplicationType } from "../../hooks/types";

function getDeviceTranslationKey(deviceSlug: ClientApplicationType) {
  switch (deviceSlug) {
    case ClientApplicationType.MetaQuest:
      return "meta";
    case ClientApplicationType.HtcViveFocus3:
      return "wave";
    case ClientApplicationType.PicoNeo3Pro:
      return "pico";
    case ClientApplicationType.MicrosoftWindows:
      return "desktop";
    default:
      return "unknown";
  }
}

export function useDeviceTranslation(deviceSlug: ClientApplicationType) {
  const { t } = useTranslation();

  return { device: t(`clients.device.${getDeviceTranslationKey(deviceSlug)}`) };
}
