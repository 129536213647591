import { useMemo } from "react";
import { useAppSelector } from ".";
import { selectOnlineDevices } from "../features/devicesSlice";
import { DeviceType } from "../session/types";

/**
 * Hook to get the list of target devices of a specific type
 * @param targetDeviceType the type of the target device
 * @param targetDeviceIdentifier optional identifier of the target device, if this device is online it will be returned
 * @returns an object containing the list of candidate devices and the target device if only one is found
 */
export function useTargetDevices(
  targetDeviceType: DeviceType,
  targetDeviceIdentifier?: string,
) {
  const connectedDevices = useAppSelector(selectOnlineDevices);

  return useMemo(() => {
    const candidates = connectedDevices.filter(
      (device) =>
        device.type === targetDeviceType &&
        (!targetDeviceIdentifier ||
          device.identifier === targetDeviceIdentifier),
    );
    return {
      candidates,
    };
  }, [connectedDevices, targetDeviceIdentifier, targetDeviceType]);
}
