import { useContext, useEffect } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { portalBackendUrl } from "../config";
import { useActiveOrganizationQuery } from "../hooks";

export default function Logout() {
  const { data: organization } = useActiveOrganizationQuery();
  const { logOut }: IAuthContext = useContext(AuthContext);

  useEffect(() => {
    const logoutBaseUrl = organization
      ? `${window.location.protocol}//${organization.domain}`
      : `${portalBackendUrl}`;
    const url = `${window.location.protocol}//${window.location.host}`;

    window.location.assign(
      `${logoutBaseUrl}/auth/logout/?next=${encodeURIComponent(url)}`,
    );
    logOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  return null;
}
