import { compare } from "compare-versions";
import { useMemo } from "react";
import { useCurrentClientApplicationVersionQuery } from ".";
import { ClientDevice } from "../session/types";
import { getClientApplicationTypeForDevice } from "../utils/devices";

function useClientApplicationType(device?: ClientDevice) {
  return useMemo(
    () => (device ? getClientApplicationTypeForDevice(device) : undefined),
    [device],
  );
}

function useIsStoreVersion(device?: ClientDevice) {
  return useMemo(
    () => (device?.version ?? "1.0.0").endsWith("-store"),
    [device?.version],
  );
}

export function useClientApplicationVersionInformation(device?: ClientDevice) {
  const clientApplicationQuery = useCurrentClientApplicationVersionQuery(
    useClientApplicationType(device),
  );
  const isStoreVersion = useIsStoreVersion(device);

  return {
    isOutdated:
      clientApplicationQuery.data?.version && device?.version
        ? !isStoreVersion &&
          !compare(clientApplicationQuery.data.version, device.version, "=")
        : undefined,
    targetVersion: clientApplicationQuery.data?.version,
  };
}
