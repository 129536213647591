import { Alert, AlertIcon } from "@chakra-ui/alert";
import { Box } from "@chakra-ui/layout";
import axios from "axios";
import { Fragment, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useIntersectionObserver } from "usehooks-ts";
import { ApplicationGroup, EmptyList, FilterBar } from "../components";
import { ApplicationGroupSkeleton } from "../components/ApplicationGroup";
import { useActiveOrganizationQuery } from "../hooks";
import { PortalApplicationCategoryListResponse } from "../hooks/types";
import { useInfiniteApplicationCategoriesQuery } from "../hooks/useApplicationCategoriesQuery";
import { useApplicationsQuery } from "../hooks/useApplicationsQuery";
import { SearchProvider } from "../search";

// placeholder data to be shown while loading
const placeholderData: PortalApplicationCategoryListResponse["results"] =
  Array.from({ length: 3 }, (_, idx) => ({
    id: idx,
    order: idx,
    name: "",
    applications: Array.from(
      { length: Math.round(Math.random() * 10) },
      (_, index) => index.toString(),
    ),
  }));

export const HomePage = () => {
  const { t } = useTranslation();
  const { data: organization } = useActiveOrganizationQuery();
  const { ref, ...entry } = useIntersectionObserver();

  const {
    data,
    error,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetched,
    isFetchingNextPage,
    isLoading: isInitialLoading,
  } = useInfiniteApplicationCategoriesQuery(organization);

  useEffect(() => {
    // don't do anything if we're already fetching
    if (isFetching) return;
    // if we're not fetching and the intersection observer is triggered
    // fetch the next page
    if (!entry?.isIntersecting) return;
    // if no more pages, nothing to do
    if (!hasNextPage) return;
    fetchNextPage();
  }, [entry?.isIntersecting, fetchNextPage, isFetching, hasNextPage]);

  const {
    data: recentApplicationData,
    isSuccess,
    error: mostRecentApplicationsError,
  } = useApplicationsQuery({
    organization: organization?.id,
    ordering: "-modified_date",
  });

  const hasNoResults = useMemo(
    () =>
      ((axios.isAxiosError(error) && error?.response?.status === 404) ||
        (isFetched && !data?.pages[0].count)) &&
      (mostRecentApplicationsError?.response?.status === 404 ||
        !recentApplicationData?.count),
    [
      data?.pages,
      error,
      isFetched,
      mostRecentApplicationsError?.response?.status,
      recentApplicationData?.count,
    ],
  );

  return (
    <SearchProvider>
      {error && mostRecentApplicationsError && !hasNoResults && (
        <Box p={5} marginTop="auto">
          <Alert status="error">
            <AlertIcon /> Error: {error.message}
          </Alert>
        </Box>
      )}
      {hasNoResults ? (
        <EmptyList />
      ) : (
        <>
          <Box position="relative">
            <FilterBar
              paddingX={{ base: 2, sm: 6 }}
              paddingY={1}
              marginTop={{ base: 2, sm: 6 }}
            />
            {data?.pages?.map((page, i) => (
              <Fragment key={i}>
                {page.results
                  ?.filter(
                    (applicationCategory) =>
                      !!applicationCategory.applications.length,
                  )
                  .map((applicationCategory) => (
                    <ApplicationGroup
                      key={applicationCategory.id}
                      groupId={applicationCategory.id}
                      appIds={applicationCategory.applications || []}
                      title={applicationCategory.name}
                    />
                  ))}
              </Fragment>
            ))}
            {(isFetchingNextPage || isInitialLoading) &&
              placeholderData.map((applicationCategory) => (
                <ApplicationGroupSkeleton key={applicationCategory.id} />
              ))}
            {isSuccess && (
              <ApplicationGroup
                key={"recent-apps"}
                appIds={
                  recentApplicationData.results.map((app) => app.id) || []
                }
                title={t("recent_applications")}
                marginY={{ base: 2, sm: 6 }}
              />
            )}
            <Box ref={ref} />
          </Box>
        </>
      )}
    </SearchProvider>
  );
};
