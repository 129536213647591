import {
  Button,
  ButtonProps,
  Progress,
  ProgressProps,
  forwardRef,
} from "@chakra-ui/react";

export const LinearProgressButton = forwardRef(
  (
    {
      value,
      max,
      onClick,
      isLoading,
      children,
      ...props
    }: ButtonProps & Pick<ProgressProps, "value" | "max">,
    ref,
  ) => {
    return (
      <Button
        onClick={onClick}
        isLoading={isLoading}
        width="100%"
        position="relative"
        zIndex="1"
        ref={ref}
        {...props}
      >
        {value !== 0 && (
          <Progress
            value={value}
            max={max}
            position="absolute"
            bottom="0"
            width="100%"
            height="10%"
            zIndex="0"
            borderRadius="md"
          />
        )}
        {children}
      </Button>
    );
  },
);
