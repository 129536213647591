import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  CloseButton,
  Collapse,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LatencyDisplay } from "../../components/LatencyDisplay";
import { LinearProgressButton } from "../../components/LinearProgressButton";
import { PaginatedTable } from "../../components/PaginatedTable";
import { DropdownIcon, InfoIcon } from "../../components/icons";
import {
  abortLatencyTest,
  resetTestRegions,
  selectCloudRendering,
  setRegionsToTest,
} from "../../features/cloudRenderingSlice";
import {
  useAppDispatch,
  useAppSelector,
  useCloudRenderingRegionsQuery,
  usePortalName,
} from "../../hooks";
import { CloudRenderingRegion } from "../../hooks/types";
import { useSecondsTicker } from "../../hooks/useSecondsTicker";
import { routes } from "../../utils/routes";
import LatencySuccess from "./LatencySuccess";
import PingSimulation from "./PingSimulation";

type CloudRenderingRegionStats = {
  latency: number;
} & CloudRenderingRegion;

const columnHelper = createColumnHelper<CloudRenderingRegionStats>();

function LatencyTable({
  cloudRegions,
  distanceAvailable,
}: {
  cloudRegions: CloudRenderingRegion[];
  distanceAvailable: boolean;
}) {
  const { t } = useTranslation();
  const cloudRendering = useAppSelector(selectCloudRendering);
  const dispatch = useAppDispatch();
  const [selectedRegions, setSelectedRegions] = useState<
    CloudRenderingRegion[]
  >([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });

  const columns = useMemo(() => {
    const columns = [
      columnHelper.display({
        id: "selector",
        cell: (props) => {
          return <Checkbox value={props.row.original.name} />;
        },
        header: () => (
          <HStack>
            <Checkbox value="__all__">
              <Tooltip
                label={t("server_location_wizard.latency_table_select_all")}
              >
                <Box cursor={"pointer"}>
                  <Icon as={InfoIcon} />
                </Box>
              </Tooltip>
            </Checkbox>
          </HStack>
        ),
      }),
      columnHelper.accessor("displayName", {
        header: () => t("server_location_wizard.region"),
        enableSorting: true,
      }),
      columnHelper.accessor("cloudProvider", {
        header: () => t("server_location_wizard.cloud_provider"),
        enableSorting: true,
      }),
      columnHelper.accessor("latency", {
        header: () => t("server_location_wizard.latency"),
        cell: (info) => <LatencyDisplay latency={info.getValue()} />,
        enableSorting: true,
        sortUndefined: 1,
      }),
    ];

    if (distanceAvailable) {
      const column = columnHelper.accessor("distanceM", {
        header: () => t("server_location_wizard.distance"),
        cell: (info) => <>{(info.getValue() / 1000).toFixed()} km</>,
        enableSorting: true,
      });
      // insert the column at the third position
      columns.splice(2, 0, column);
    }

    return columns;
  }, [distanceAvailable, t]);

  const data = useMemo(
    () =>
      cloudRegions.map<CloudRenderingRegionStats>((region) => ({
        ...region,
        latency: cloudRendering.regionsLatency[region?.name],
      })),
    [cloudRegions, cloudRendering.regionsLatency],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: [{ desc: false, id: "latency" }], // sort by latency per default
    },
    autoResetPageIndex: false, // prevent changing the page when new data comes in.
    onPaginationChange: (newPagination) => {
      setPagination(newPagination);
    },
    state: {
      pagination,
    },
  });

  // reset the selected regions when page changes
  useEffect(() => {
    setSelectedRegions([]);
    setAreAllSelected(false);
  }, [pagination.pageIndex]);

  const selectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        const shownRegions = table.getRowModel().rows.map((r) => r.original);
        setSelectedRegions(shownRegions);
        setAreAllSelected(true);
      } else {
        setSelectedRegions([]);
        setAreAllSelected(false);
      }
    },
    [table],
  );

  return (
    <>
      <Tooltip
        label={t(
          "server_location_wizard.check_selected_regions_button_disabled_hint",
        )}
        isDisabled={
          cloudRendering.latencyTestRunning || selectedRegions.length > 0
        }
      >
        <Button
          size="sm"
          alignSelf={"start"}
          isDisabled={
            cloudRendering.latencyTestRunning || selectedRegions.length === 0
          }
          onClick={() => {
            if (cloudRendering.latencyTestRunning) {
              return;
            }

            const tests = selectedRegions
              .map((region) => {
                // we want to check each region 3 times
                return [region, region, region];
              })
              .flat();
            dispatch(setRegionsToTest(tests));
          }}
        >
          {t("server_location_wizard.check_selected_regions")}
        </Button>
      </Tooltip>
      <CheckboxGroup
        value={selectedRegions
          .map((r) => r.name)
          .concat(areAllSelected ? ["__all__"] : [])}
        onChange={(regionNames: string[]) => {
          if (!areAllSelected && regionNames.includes("__all__")) {
            selectAll(true);
            return;
          } else if (areAllSelected && !regionNames.includes("__all__")) {
            selectAll(false);
            return;
          }

          setSelectedRegions(
            cloudRegions.filter((region) => regionNames.includes(region.name)),
          );
          setAreAllSelected(false);
        }}
      >
        <PaginatedTable table={table} />
      </CheckboxGroup>
    </>
  );
}

export function ServerLocationWizard() {
  const productName = usePortalName();
  const cloudRendering = useAppSelector(selectCloudRendering);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen: showMoreDetails, onToggle } = useDisclosure();
  const location = useLocation();
  const nextLocation = useMemo(
    () => location.state?.from ?? { pathname: routes.home },
    [location.state?.from],
  );
  const autoContinueSeconds = 10;
  const ticker = useSecondsTicker({
    startSeconds: autoContinueSeconds,
    direction: -1,
    min: 0,
    max: 30,
    enabled: !!location.state?.from && cloudRendering.latencyTestCompleted,
  });

  const { data: cloudRenderingRegions, isSuccess } =
    useCloudRenderingRegionsQuery(
      {
        allowWavelengthZones: true,
      },
      {
        // only regions that have a latency test url
        select: (regions) => regions.filter((r) => r.latencyTestUrl),
      },
    );

  const distanceAvailable = useMemo(() => {
    if (!isSuccess) return false;

    if (cloudRenderingRegions.find((r) => r.distanceM)) {
      return true;
    }
    return false;
  }, [cloudRenderingRegions, isSuccess]);

  const checkAll = useCallback(() => {
    if (!isSuccess) {
      return;
    }

    let relevantRegions = cloudRenderingRegions
      .filter((r) => r.latencyTestUrl)
      .sort((a, b) => a.distanceM - b.distanceM);

    if (distanceAvailable) {
      // all regions that are closer than 5000km
      relevantRegions = relevantRegions
        .filter((r) => r.distanceM < 5000000)
        // max 20 regions
        .slice(0, 20);
    }
    // take top 10 regions
    relevantRegions = relevantRegions.slice(0, 10);

    // test all regions 3 times
    const allRegions = relevantRegions
      .concat(relevantRegions)
      .concat(relevantRegions);

    dispatch(resetTestRegions());
    dispatch(setRegionsToTest(allRegions));
  }, [cloudRenderingRegions, dispatch, distanceAvailable, isSuccess]);

  const automaticallyProceed = useMemo(
    () => !showMoreDetails && !!location.state?.from,
    [location.state?.from, showMoreDetails],
  );

  useEffect(() => {
    // automatically redirect after the test is completed and the ticker has finished
    if (
      ticker <= 0 &&
      automaticallyProceed &&
      cloudRendering.latencyTestCompleted
    ) {
      navigate(nextLocation);
    }
  }, [
    cloudRendering.latencyTestCompleted,
    navigate,
    nextLocation,
    ticker,
    automaticallyProceed,
  ]);

  useEffect(() => {
    if (
      !cloudRendering.latencyTestCompleted &&
      !cloudRendering.latencyTestRunning
    ) {
      checkAll();
    }
  }, [
    checkAll,
    cloudRendering.latencyTestCompleted,
    cloudRendering.latencyTestRunning,
  ]);

  const closeWizard = useCallback(() => {
    // reset the test (will also stop running tests through a redux listener middleware)
    cloudRendering.latencyTestRunning && dispatch(abortLatencyTest());
    navigate(nextLocation, {
      replace: true,
      state: {
        latencyCheckAborted: cloudRendering.latencyTestRunning,
      },
    });
  }, [cloudRendering.latencyTestRunning, dispatch, navigate, nextLocation]);
  return (
    <>
      <Helmet>
        <title>
          {t("server_location_wizard.title")} - {productName}
        </title>
      </Helmet>
      <Flex
        padding={8}
        justifyContent={"center"}
        alignItems={"center"}
        minH={"100vh"}
      >
        <Stack
          spacing={8}
          padding={4}
          border="1px"
          borderRadius={"md"}
          position={"relative"}
          borderColor={"chakra-border-color"}
          width={"container.md"}
        >
          <Tooltip label={t("server_location_wizard.close")}>
            <CloseButton
              size={"lg"}
              onClick={closeWizard}
              alignSelf="center"
              position={"absolute"}
              top={0}
              right={0}
              margin={2}
            />
          </Tooltip>
          {cloudRendering.latencyTestCompleted ? (
            <LatencySuccess />
          ) : (
            <Stack spacing={8} alignItems={"center"} textAlign={"center"}>
              <Heading as="h2" size="lg">
                {t("server_location_wizard.heading")}
              </Heading>
              <Text maxW={"xl"}>
                {t("server_location_wizard.test_description")}{" "}
                <Tooltip
                  label={t("server_location_wizard.keep_page_open_warning")}
                >
                  <Box as="span" display={"inline-block"} cursor={"pointer"}>
                    <Icon as={InfoIcon} />
                  </Box>
                </Tooltip>
              </Text>
              <PingSimulation />
            </Stack>
          )}
          <Button
            variant={"link"}
            size="sm"
            onClick={onToggle}
            fontWeight={"normal"}
            leftIcon={
              <Icon
                as={DropdownIcon}
                boxSize={2}
                transition={"transform 500ms ease-in-out"}
                transform={showMoreDetails ? "rotate(180deg)" : undefined}
              />
            }
          >
            {showMoreDetails
              ? t("server_location_wizard.hide_details")
              : t("server_location_wizard.show_details")}
          </Button>
          <Collapse in={showMoreDetails} animateOpacity>
            <Stack spacing={4}>
              <Text color="chakra-subtle-text">
                {t("server_location_wizard.latency_table_description")}
              </Text>
              <LatencyTable
                cloudRegions={cloudRenderingRegions ?? []}
                distanceAvailable={distanceAvailable}
              />
              <Divider />
            </Stack>
          </Collapse>
          <HStack>
            {!cloudRendering.latencyTestCompleted && (
              <Button
                colorScheme="red"
                variant={"ghost"}
                flexBasis={"50%"}
                flexGrow={1}
                onClick={closeWizard}
              >
                {t("server_location_wizard.cancel")}
              </Button>
            )}
            {!cloudRendering.latencyTestRunning && (
              <Button
                onClick={() => {
                  checkAll();
                }}
                flexBasis={"50%"}
              >
                {t("server_location_wizard.restart_test")}
              </Button>
            )}
            {cloudRendering.latencyTestCompleted && (
              <LinearProgressButton
                flexBasis={"50%"}
                colorScheme="brand"
                as={Link}
                to={nextLocation}
                my={6}
                value={!automaticallyProceed ? 0 : autoContinueSeconds - ticker}
                max={autoContinueSeconds}
              >
                {t("server_location_wizard.proceed")}
                {!automaticallyProceed ? "" : ` (${ticker}s)`}
              </LinearProgressButton>
            )}
          </HStack>
        </Stack>
      </Flex>
    </>
  );
}
