import { Button, ButtonGroup, IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";
import { FaRegTrashAlt as TrashIcon } from "react-icons/fa";
import { MdDone as CheckIcon } from "react-icons/md";

export function RenderMachineStatusButton({
  isActiveServer: isActive,
  onActiveButtonClick,
  onDeleteButtonClick,
}: {
  isActiveServer: boolean;
  onActiveButtonClick: (isActive: boolean) => void;
  onDeleteButtonClick: () => void;
}) {
  return (
    <ButtonGroup size="sm" isAttached colorScheme={"blue"}>
      <Tooltip
        label={
          isActive
            ? t("render_machine.status_button.disable_button")
            : t("render_machine.status_button.enable_button")
        }
      >
        <Button
          leftIcon={isActive ? <CheckIcon /> : undefined}
          colorScheme={isActive ? "green" : "gray"}
          variant={isActive ? "solid" : "outline"}
          onClick={() => onActiveButtonClick(isActive)}
        >
          {isActive
            ? t("render_machine.status_button.active")
            : t("render_machine.status_button.select")}
        </Button>
      </Tooltip>
      <Tooltip label={t("render_machine.status_button.destroy_tooltip")}>
        <IconButton
          colorScheme={"gray"}
          aria-label="Destroy Server"
          variant={"outline"}
          icon={<TrashIcon color="red" />}
          onClick={() => onDeleteButtonClick()}
        />
      </Tooltip>
    </ButtonGroup>
  );
}
