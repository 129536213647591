import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UUID } from "../hooks/types";
import { SESSION_STATE } from "../session/session-state";
import type {
  AppInstalledMessage,
  AppLaunchedMessage,
  CloudRenderedSessionCreatedMessage,
  CloudRenderedSessionOverviewMessage,
  DefaultSessionOverviewMessage,
  ErrorMessage,
  IssueHandledMessage,
  ReportedIssue,
  RequestSessionMessage,
  SessionCreatedMessage,
  SessionOverviewMessage,
  SessionStartupProgressMessage,
} from "../session/types";
import {
  DeviceType,
  KnownIssueCodes,
  NotificationMessage,
  SessionState as ServerSessionState,
  SessionData,
  SessionType,
} from "../session/types";
import type { RootState } from "../store";

interface SessionState {
  notification?: NotificationMessage;
  connectionState: HubConnectionState;
  sessionOverviewReceived: boolean;
  sessionState: SessionData;
  remoteUrls: { id: UUID; url: string; prompt?: boolean }[];
  issues: ReportedIssue[];
}

// Define the initial state using that type
const initialState: SessionState = {
  connectionState: HubConnectionState.Disconnected,
  sessionOverviewReceived: false,
  sessionState: {
    state: SESSION_STATE.UNKNOWN,
    type: SessionType.Unknown,
    debugModeEnabled: false,
    issues: {
      hasBadLatency: false,
      hasPackageLoss: false,
      hasHighJitter: false,
      hasNotEnoughBandwidth: false,
    },
  },
  remoteUrls: [],
  issues: [],
};

const getSessionType = (
  session:
    | Pick<DefaultSessionOverviewMessage, "deviceTargetPlatform">
    | Pick<
        CloudRenderedSessionOverviewMessage,
        "deviceTargetPlatform" | "renderRegion"
      >
    | Pick<SessionCreatedMessage, "deviceTargetPlatform">,
) => {
  return session.deviceTargetPlatform === DeviceType.Browser
    ? SessionType.CloudRenderedNonVR
    : session.deviceTargetPlatform === DeviceType.Desktop
      ? SessionType.LocallyRenderedWindows
      : session.deviceTargetPlatform === DeviceType.Standalone &&
          "renderRegion" in session
        ? SessionType.CloudRenderedVR
        : SessionType.LocallyRenderedStandalone;
};

export const sessionSlice = createSlice({
  name: "session",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setConnectionState: (state, action: PayloadAction<HubConnectionState>) => {
      state.connectionState = action.payload;

      if (action.payload === HubConnectionState.Disconnected) {
        state.sessionState = initialState.sessionState;
      }
    },
    notification: (state, action: PayloadAction<NotificationMessage>) => {
      if (!state.notification) {
        state.notification = action.payload;
        return;
      }
      state.notification.id = Math.random().toString(16);
      state.notification.notificationType = action.payload.notificationType;
      state.notification.level = action.payload.level;
      state.notification.alert = action.payload.alert;
      // cannot directly assign the nested object of params as this would always end up with a new object
      Object.assign(state.notification.params ?? {}, action.payload.params);
    },
    sessionCreated: (
      state,
      action: PayloadAction<
        SessionCreatedMessage | CloudRenderedSessionCreatedMessage
      >,
    ) => {
      const sessionState = state.sessionState;
      const { appId: applicationBuildId, ...payload } = action.payload;
      Object.assign(sessionState, {
        ...payload,
        // FIXME: session management sends application build id as string, we need an int
        applicationBuildId: Number(applicationBuildId),
      });
      sessionState.state = SESSION_STATE.LOADING;
      sessionState.launchArguments = action.payload.launchArguments;
      // Figure out the session's type / target device
      sessionState.type = getSessionType(action.payload);

      // clear the remote urls
      state.remoteUrls = initialState.remoteUrls;
    },
    sessionOverview: (
      lastState,
      action: PayloadAction<SessionOverviewMessage>,
    ) => {
      // in any case, remember that we got the session overview so that we know we now know whether the user has a session already or not
      lastState.sessionOverviewReceived = true;

      if ("id" in action.payload === false) {
        // if we received an empty session, but a new one has been requested already, ignore the empty session message
        // this can happen if request session is sent prior to session overview being received
        if (lastState.sessionState.state === SESSION_STATE.REQUESTED) {
          return;
        }
        // nothing to change, empty session, reset the session
        lastState.sessionState = initialState.sessionState;
        return;
      }

      // we can cast the type here as we made sure above that we don't handle an empty session overview message
      const {
        appId: applicationBuildIdAsString,
        ...data
      }: DefaultSessionOverviewMessage | CloudRenderedSessionOverviewMessage =
        action.payload as
          | DefaultSessionOverviewMessage
          | CloudRenderedSessionOverviewMessage;

      // FIXME: state can either be named state or sessionState
      let state: ServerSessionState | undefined;
      if ("state" in data) {
        state = data.state;
      }
      const appLaunched =
        state === ServerSessionState.LaunchedApp ||
        ("appLaunched" in data && data.appLaunched);

      // FIXME: session management sends application build id as string, we need an int
      let applicationBuildId: number | undefined;
      if (applicationBuildIdAsString) {
        applicationBuildId = Number(applicationBuildIdAsString);
      }

      // Figure out the session's state
      let sessionState: number = SESSION_STATE.UNKNOWN;
      const appInstalled = state === ServerSessionState.AppInstalled;
      if (appLaunched) {
        sessionState = SESSION_STATE.ACTIVE;
      } else if ("ipAddress" in data && appInstalled) {
        sessionState = SESSION_STATE.READY;
      } else {
        sessionState = SESSION_STATE.LOADING;
      }

      const newState: Partial<SessionData> = {
        ...data,
        appLaunched,
        applicationBuildId,
        state: sessionState,
      };

      // Figure out the session's type / target device
      newState.type = getSessionType(data);

      if ("state" in data && data.state === ServerSessionState.LaunchedApp) {
        newState.progress = 1;
      }

      Object.assign(lastState.sessionState, newState);
    },
    sessionTerminated: (state, action: PayloadAction<{ id: string }>) => {
      if (action.payload.id !== state.sessionState.id) {
        // the termination was for a different session
        return;
      }

      state.sessionState = {
        ...initialState.sessionState,
        // we might still need the application build
        applicationBuildId: state.sessionState.applicationBuildId,
        state: SESSION_STATE.ENDED,
      };

      // clear the remote urls
      state.remoteUrls = initialState.remoteUrls;
    },
    sessionProgress: (
      state,
      action: PayloadAction<SessionStartupProgressMessage>,
    ) => {
      const data = action.payload;
      const newState: Partial<SessionData> = {
        progress: data.progress,
        vmSize: "vmSize" in data ? data.vmSize : undefined,
        expectedWaitTimeSec:
          "expectedWaitTimeSec" in data ? data.expectedWaitTimeSec : undefined,
        message: data.message,
        launchArguments: data.launchArguments,
        extraLaunchArguments: data.extraLaunchArguments,
        renderRegion: "renderRegion" in data ? data.renderRegion : undefined,
        renderCloudProvider:
          "renderCloudProvider" in data ? data.renderCloudProvider : undefined,
        cloudXREncryption:
          "cloudXREncryption" in data ? data.cloudXREncryption : undefined,
      };

      if (data.sessionState === "LaunchedApp") {
        newState.state = SESSION_STATE.ACTIVE;
        newState.appLaunched = true;
      }

      Object.assign(state.sessionState, newState);
    },
    appLaunched: (state, action: PayloadAction<AppLaunchedMessage>) => {
      if (
        state.sessionState.id !== action.payload.sessionId ||
        state.sessionState.deviceIdentifier !== action.payload.deviceIdentifier
      ) {
        // wrong session
        return;
      }

      state.sessionState.appLaunched = true;
      state.sessionState.state = SESSION_STATE.ACTIVE;
    },
    appInstalled: (state, action: PayloadAction<AppInstalledMessage>) => {
      if (
        state.sessionState.id !== action.payload.sessionId ||
        state.sessionState.deviceIdentifier !==
          action.payload.deviceIdentifier ||
        state.sessionState.applicationBuildId !== Number(action.payload.appId)
      ) {
        // wrong session
        return;
      }

      if ("ipAddress" in action.payload) {
        state.sessionState.ipAddress = action.payload.ipAddress;
      }
      state.sessionState.state = SESSION_STATE.READY;
    },
    sessionError: (state, action: PayloadAction<ErrorMessage>) => {
      if (
        action.payload.sessionId &&
        state.sessionState.id !== action.payload.sessionId
      ) {
        // wrong session
        return;
      }

      state.sessionState.state = SESSION_STATE.ERRORED;
      state.sessionState.message = action.payload.message;
      state.sessionState.error = action.payload;
      state.sessionState.appLaunched = false;
    },
    setSessionState: (state, action: PayloadAction<number>) => {
      state.sessionState.state = action.payload;
    },
    reportIssue: (state, action: PayloadAction<ReportedIssue>) => {
      if (action.payload.issueCode === KnownIssueCodes.BadLatency) {
        state.sessionState.issues.hasBadLatency = true;
      } else if (action.payload.issueCode === KnownIssueCodes.HighJitter) {
        state.sessionState.issues.hasHighJitter = true;
      } else if (action.payload.issueCode === KnownIssueCodes.HighPackageLoss) {
        state.sessionState.issues.hasPackageLoss = true;
      } else if (action.payload.issueCode === KnownIssueCodes.LowBandwidth) {
        state.sessionState.issues.hasNotEnoughBandwidth = true;
      } else if (action.payload.issueCode === KnownIssueCodes.OpenUrl) {
        action.payload.params?.Url &&
          state.remoteUrls.push({
            id: action.payload.id,
            url: action.payload.params?.Url,
            prompt: true,
          });
      }

      // remember the issue
      state.issues.push(action.payload);
    },
    dismissUrl: (state, action: PayloadAction<UUID>) => {
      state.remoteUrls = state.remoteUrls.map((url) => {
        if (url.id === action.payload) {
          return {
            ...url,
            prompt: false,
          };
        }
        return url;
      });
    },
    issueHandled: (state, action: PayloadAction<IssueHandledMessage>) => {
      if (action.payload.issueCode === KnownIssueCodes.BadLatency) {
        state.sessionState.issues.hasBadLatency = false;
      } else if (action.payload.issueCode === KnownIssueCodes.HighJitter) {
        state.sessionState.issues.hasHighJitter = false;
      } else if (action.payload.issueCode === KnownIssueCodes.HighPackageLoss) {
        state.sessionState.issues.hasPackageLoss = false;
      } else if (action.payload.issueCode === KnownIssueCodes.LowBandwidth) {
        state.sessionState.issues.hasNotEnoughBandwidth = false;
      }

      // remove the handled issues from the list of issues
      state.issues = state.issues.filter(
        (issue) => issue.id !== action.payload.id,
      );
    },
    sessionRequested: (
      state,
      action: PayloadAction<RequestSessionMessage & { type: SessionType }>,
    ) => {
      const { appId, ...payload } = action.payload;
      Object.assign(state.sessionState, {
        ...payload,
        applicationBuildId: Number(appId),
      });
      state.sessionState.state = SESSION_STATE.REQUESTED;
      state.sessionState.appLaunched = false;
    },
    enableDebugMode: (state) => {
      state.sessionState.debugModeEnabled = true;
    },
  },
});

export const {
  setConnectionState,
  notification,
  sessionCreated,
  sessionOverview,
  sessionTerminated,
  sessionProgress,
  appLaunched,
  appInstalled,
  reportIssue,
  issueHandled,
  sessionError,
  setSessionState,
  sessionRequested,
  dismissUrl,
  enableDebugMode,
} = sessionSlice.actions;

export const selectSessionServiceConnectionState = (state: RootState) =>
  state.session.connectionState;
export const selectSessionNotification = (state: RootState) =>
  state.session.notification;
export const selectSessionState = createSelector(
  (state: RootState) => state.session.sessionState,
  (session) => ({
    ...session,
    // compute some derived properties to avoid having to useMemo everywhere
    isValid:
      session.state >= SESSION_STATE.REQUESTED &&
      session.state < SESSION_STATE.ENDING,
    isActive: session.state === SESSION_STATE.ACTIVE,
    isEnding: session.state === SESSION_STATE.ENDING,
    isReady: session.state === SESSION_STATE.READY,
    isErrored: session.state === SESSION_STATE.ERRORED,
    isEnded: session.state === SESSION_STATE.ENDED,
    isLoading:
      session.state >= SESSION_STATE.REQUESTED &&
      session.state < SESSION_STATE.READY,
    isDesktop: session.type === SessionType.LocallyRenderedWindows,
    isBrowser: session.type === SessionType.CloudRenderedNonVR,
    isCloudRenderedVr: session.type === SessionType.CloudRenderedVR,
    isCloudRendered: [
      SessionType.CloudRenderedVR,
      SessionType.CloudRenderedNonVR,
    ].includes(session.type),
    isVr: [
      SessionType.CloudRenderedVR,
      SessionType.LocallyRenderedStandalone,
    ].includes(session.type),
  }),
);

export const selectRenderingServerIp = createSelector(
  (state: RootState) => state.session.sessionState,
  (session) => session.ipAddress,
);

export const selectIsCloudRenderedVRSession = createSelector(
  selectSessionState,
  (session) => session.isCloudRenderedVr,
);

export const selectHasNonEndedSession = createSelector(
  selectSessionState,
  (session) =>
    !session.isEnded &&
    (session.isValid || session.isEnding || session.isErrored),
);

export const selectSessionOverviewReceived = createSelector(
  (state: RootState) => state.session,
  (session) => session.sessionOverviewReceived,
);

export const selectRemoteUrls = createSelector(
  (state: RootState) => state.session,
  (session) => session.remoteUrls,
);

export const selectSessionIssues = createSelector(
  (state: RootState) => state.session,
  (session) => session.issues,
);

export type SessionDataExtended = ReturnType<typeof selectSessionState>;

export default sessionSlice.reducer;
