import { useMemo } from "react";
import { CloudRenderingRegion } from "./types";
import { useVmSizesQuery } from "./useVmSizesQuery";

export function useMinVmSize({
  region,
  targetVmSize,
}: {
  region?: CloudRenderingRegion;
  targetVmSize?: string;
}) {
  const vmSizesQuery = useVmSizesQuery();
  const vmSizesInRegion = useMemo(
    () =>
      vmSizesQuery.data?.filter(
        (vmSize) => region?.supportedVmSizes.includes(vmSize.name),
      ) ?? [],
    [region, vmSizesQuery.data],
  );
  const minimumVmSize = useMemo(() => {
    // find the next-best vm size in terms of size.
    // we want the smallest vm size that is larger than the target vm size
    // let's first find the size (i.e. index) of the target vm size
    const targetVmSizeIndex =
      vmSizesQuery.data?.findIndex((vmSize) => vmSize.name === targetVmSize) ??
      -1;

    if (targetVmSizeIndex === -1) {
      return undefined;
    }

    // next up, we're going to find out the size of each vm size in the region
    const vmSizeSizes = vmSizesInRegion.map(
      (vmSizeOuter) =>
        vmSizesQuery.data?.findIndex(
          (vmSizeInner) => vmSizeOuter.name === vmSizeInner.name,
        ) ?? -1,
    );

    // now, we're going to find the smallest vm size that is larger than the target vm size
    const nextBestVmSizeIndex = vmSizeSizes.findIndex(
      (vmSizeSize) => vmSizeSize >= targetVmSizeIndex,
    );

    // last but not least, return the entire vm size object
    return vmSizesInRegion.at(nextBestVmSizeIndex);
  }, [vmSizesQuery.data, vmSizesInRegion, targetVmSize]);
  return minimumVmSize;
}

/**
 *
 * @param vmSizeA Virtual machine size A, e.g. t4.medium
 * @param vmSizeB Virtual machine size B, e.g. a10g.medium
 * @returns -1 if vmSizeA is smaller than vmSizeB, 0 if they are equal, 1 if vmSizeA is larger than vmSizeB
 */
export function useVmSizeComparator(
  vmSizeA?: string,
  vmSizeB?: string,
): -1 | 0 | 1 {
  const vmSizesQuery = useVmSizesQuery();

  return useMemo(() => {
    const vmSizeAIndex = vmSizesQuery.data?.findIndex(
      (vmSize) => vmSize.name === vmSizeA,
    );
    const vmSizeBIndex = vmSizesQuery.data?.findIndex(
      (vmSize) => vmSize.name === vmSizeB,
    );

    if (vmSizeAIndex === undefined || vmSizeBIndex === undefined) {
      return 0;
    }

    if (vmSizeAIndex < vmSizeBIndex) {
      return -1;
    } else if (vmSizeAIndex === vmSizeBIndex) {
      return 0;
    } else {
      return 1;
    }
  }, [vmSizesQuery.data, vmSizeA, vmSizeB]);
}
