import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
} from "@chakra-ui/react";
import { CheckmarkIcon, CopyIcon } from "./icons";

export function InputCopyableValue({
  data,
  id,
}: {
  data: string | undefined;
  id: string | undefined;
}) {
  return (
    <InputGroup size="md">
      <Input
        fontSize={"md"}
        size="xs"
        id={id}
        variant="flushed"
        borderBottom={"none"}
        value={data}
        isReadOnly
      />
      <InputRightElement boxSize={6}>
        <CopyButton data={data} />
      </InputRightElement>
    </InputGroup>
  );
}

export function CopyButton({ data }: { data: string | undefined }) {
  const { hasCopied, onCopy } = useClipboard(data || "");

  return (
    <IconButton
      size="sm"
      variant="ghost"
      onClick={onCopy}
      icon={<Icon as={hasCopied ? CheckmarkIcon : CopyIcon} />}
      aria-label="Copy to Clipboard"
    />
  );
}
