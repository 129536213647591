import { useContext, useEffect } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useLocation } from "react-router-dom";
import { routes } from "../utils/routes";

export default function Login() {
  const { logIn }: IAuthContext = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const path = location.state?.from?.pathname ?? routes.home;
    const search = location.state?.from?.search ?? "";
    logIn(path + search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
