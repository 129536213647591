import { Center, Heading, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import { FaCheckCircle } from "react-icons/fa";

export default function LatencySuccess() {
  return (
    <Center py={8} textAlign="center" flexDirection={"column"} gap={4}>
      <Icon as={FaCheckCircle} boxSize={16} color="brand.500" />
      <Heading as="h2" size="lg">
        {t("server_location_wizard.success_header")}
      </Heading>
    </Center>
  );
}
