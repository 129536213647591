import { version as portalVersion } from "../utils/version";

const messageDefaultVersion = 1;

export const Message = {
  ACKNOWLEDGE: "acknowledge",
  CLIENT_HELLO: "client_hello",
  SERVER_HELLO: "server_hello",
  REQUEST_AUTHENTICATION: "request_authentication",
  VERIFY_DEVICE_AUTHORIZATION_REQUEST: "verify_device_authentication_request",
};

export type MessageHeader = {
  Name?: string;
};

export type ServiceMessage = Record<string, unknown> & {
  Header?: MessageHeader;
};

export type ServerHelloMessage = {
  IncomingMessages: { Name: string }[];
  ServerName: string;
} & ServiceMessage;

export type VerifyAuthorizationMessage = {
  UserCode: string;
} & ServiceMessage;

export const headers = Object.values(Message).reduce(
  (headers, messageName) => ({
    ...headers,
    [messageName]: { Name: messageName, Version: messageDefaultVersion },
  }),
  {},
);

export function buildMessageHeader(
  message: string,
  version = messageDefaultVersion,
) {
  return { Name: message, Version: version };
}

export const helloMessage = {
  Header: buildMessageHeader(Message.CLIENT_HELLO),
  ClientName: "Portal Frontend",
  ClientVersion: portalVersion,
  OutgoingMessages: [{ Name: Message.REQUEST_AUTHENTICATION, Version: 2 }],
  AcceptedMessages: [
    { Name: Message.VERIFY_DEVICE_AUTHORIZATION_REQUEST, Version: 1 },
  ],
};

export const acknowledgeMessage = {
  Header: buildMessageHeader(Message.ACKNOWLEDGE),
};
