import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogProps,
    Button,
  } from "@chakra-ui/react";
  import { useRef } from "react";
  import { useTranslation } from "react-i18next";
  
  export function ConfirmMachineDestroyDialog({
    isOpen,
    onClose,
    onConfirm,
    ...props
  }: { onConfirm(): void } & Omit<
    AlertDialogProps,
    "leastDestructiveRef" | "children"
  >) {
    const { t } = useTranslation();
    const cancelRef = useRef(null);
  
    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        {...props}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("render_machine.terminate_dialog.title")}
            </AlertDialogHeader>
  
            <AlertDialogBody>
              {t("render_machine.terminate_dialog.are_you_sure")}
            </AlertDialogBody>
  
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t("render_machine.terminate_dialog.cancel")}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose();
                  onConfirm();
                }}
                ml={3}
              >
                {t("render_machine.terminate_dialog.confirm")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }
  