import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector, useNotificationToast } from "../hooks";
import { useVmSizesQuery } from "../hooks/useVmSizesQuery";

export const VmUpgradeNotification = () => {
  const { t } = useTranslation();
  const toast = useNotificationToast();
  const currentSession = useAppSelector(selectSessionState);
  const [vmSize, setVmSize] = useState<string | undefined>();
  const vmSizesQuery = useVmSizesQuery();

  useEffect(() => {
    if (!currentSession.isValid) {
      setVmSize(undefined);
      return;
    }

    if (currentSession.vmSize) {
      setVmSize(currentSession.vmSize);
    }

    const vmSizeIndex = vmSizesQuery.data?.findIndex((x) => x.name === vmSize);
    const sessionVmSizeIndex = vmSizesQuery.data?.findIndex(
      (x) => x.name === currentSession.vmSize,
    );

    if (
      vmSizeIndex != null &&
      sessionVmSizeIndex != null &&
      vmSizeIndex >= 0 &&
      sessionVmSizeIndex >= 0 &&
      sessionVmSizeIndex > vmSizeIndex
    ) {
      setVmSize(currentSession.vmSize);
      toast({
        title: t("vmUpgradeNotification.title"),
        description: t("vmUpgradeNotification.description"),
        status: "success",
        duration: 6000,
      });
    }
  }, [currentSession, t, toast, vmSize, vmSizesQuery.data]);

  return null;
};
