import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import portalApiClient, {
  getApplicationCategory as fetchApplicationCategory,
} from "../portal-api";
import {
  ApplicationCategory,
  Organization,
  PortalApplicationCategoryListResponse,
} from "./types";
import { getQueryKeyForOrganization } from "./useActiveOrganizationQuery";

const baseQueryKeyForApplicationCategories = [
  "application-categories",
] as const;

export function getQueryKeyForApplicationCategories(
  organizationId: Organization["id"] | undefined,
) {
  return [
    ...getQueryKeyForOrganization(organizationId),
    ...baseQueryKeyForApplicationCategories,
  ];
}

export function useInfiniteApplicationCategoriesQuery(
  organization?: Organization,
) {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: getQueryKeyForApplicationCategories(organization?.id),
    queryFn: async ({ pageParam }) => {
      const response =
        await portalApiClient.get<PortalApplicationCategoryListResponse>(
          pageParam,
          {
            params: { organization: organization?.id, page_size: 5 },
          },
        );

      // put the data into the query cache to be accessible also for other queries
      if (organization?.id) {
        response.data.results.forEach((category) => {
          queryClient.setQueryData(
            getQueryKeyForApplicationCategory(category.id, organization.id),
            category,
          );
        });
      }
      return response.data;
    },
    initialPageParam: `/api/v2/application-categories/`,
    retry: false,
    getNextPageParam: (lastPage) => lastPage.next,
  });
}

export function useApplicationCategoryQuery(
  applicationCategoryId: number,
  organization?: Organization,
) {
  return useQuery<ApplicationCategory>({
    queryKey: getQueryKeyForApplicationCategory(
      applicationCategoryId,
      organization?.id,
    ),
    queryFn: () =>
      fetchApplicationCategory(applicationCategoryId, {
        organization: organization?.id,
      }),
    retry: false,
    enabled: !!applicationCategoryId && !!organization,
  });
}

function getQueryKeyForApplicationCategory(
  applicationCategoryId: number,
  organizationId: Organization["id"] | undefined,
) {
  return [
    ...baseQueryKeyForApplicationCategories,
    applicationCategoryId,
    organizationId,
  ];
}
