import {
  Alert,
  AlertIcon,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../auth";
import { ClearIcon } from "../components/icons";
import { useFeatureFlags } from "../hooks";
import { useVirtualMachinesQuery } from "../hooks/useVirtualMachinesQuery";

export function FeatureFlagsPage() {
  const { t } = useTranslation();
  const [flags, setFlags] = useFeatureFlags();
  const { user } = useAuthentication();
  const { data: vms } = useVirtualMachinesQuery();

  const virtualMachinesForUser = useMemo(
    () =>
      vms?.items.filter(
        (vm) => vm.activeUserIdentifier === user?.id.toString(),
      ) ?? [],
    [user?.id, vms?.items],
  );

  return (
    <Stack spacing={8} maxWidth={"container.sm"}>
      <Heading as="h2">{t("featureFlags.title")}</Heading>
      <Alert status="info">
        <AlertIcon />
        {t("featureFlags.description")}
      </Alert>
      <FormControl as="fieldset">
        <Stack spacing={2} paddingTop={4}>
          <FormLabel htmlFor="custom-vm-image" margin={0}>
            {t("streamingPreferences.streaming.custom_vm_image")}
          </FormLabel>
          <InputGroup>
            <Input
              id="custom-vm-image"
              value={flags.vmImage ?? ""}
              placeholder="(Default)"
              onChange={(evt) =>
                setFlags((flags) => ({
                  ...flags,
                  vmImage: evt.target.value,
                }))
              }
            />
            {flags.vmImage && (
              <InputRightElement>
                <IconButton
                  variant={"ghost"}
                  icon={<Icon as={ClearIcon} />}
                  aria-label="Clear custom image"
                  onClick={() =>
                    setFlags((flags) => ({ ...flags, vmImage: undefined }))
                  }
                />
              </InputRightElement>
            )}
          </InputGroup>
          <FormHelperText marginTop={0}>
            {t("streamingPreferences.streaming.custom_vm_image_help_text")}
          </FormHelperText>
        </Stack>
      </FormControl>
      {user?.is_superuser && (
        <FormControl as="fieldset">
          <Stack spacing={2} paddingTop={4}>
            <FormLabel htmlFor="vm-id" margin={0}>
              {t("streamingPreferences.streaming.vm_id")}
            </FormLabel>
            <Select
              id="vm-id"
              placeholder="(Empty)"
              isDisabled={virtualMachinesForUser.length === 0}
              value={flags.virtualMachineId ?? ""}
              onChange={(evt) =>
                setFlags((flags) => ({
                  ...flags,
                  virtualMachineId:
                    evt.target.value === "" ? undefined : evt.target.value,
                }))
              }
            >
              {virtualMachinesForUser.map((vm) => (
                <option value={vm.id} key={vm.id}>
                  {vm.id} {vm.publicIp ? `- ${vm.publicIp}` : ""}
                </option>
              ))}
            </Select>
            <FormHelperText marginTop={0}>
              {t("streamingPreferences.streaming.vm_id_help_text")}
            </FormHelperText>
          </Stack>
        </FormControl>
      )}
      <FormControl as="fieldset">
        <Stack spacing={4} paddingTop={4}>
          <HStack>
            <Switch
              id="debug-mode"
              isChecked={flags?.debugModeEnabled ?? false}
              onChange={(evt) =>
                setFlags((flags) => ({
                  ...flags,
                  debugModeEnabled: evt.target.checked,
                }))
              }
            />
            <FormLabel htmlFor="debug-mode" margin={0}>
              {t("streamingPreferences.security.debug_mode")}
            </FormLabel>
          </HStack>
          <FormHelperText marginTop={0}>
            {!flags?.debugModeEnabled
              ? t("streamingPreferences.security.debug_mode_help_text_disabled")
              : t("streamingPreferences.security.debug_mode_help_text_enabled")}
          </FormHelperText>
        </Stack>
      </FormControl>
    </Stack>
  );
}
