import { Icon, IconButton, IconButtonProps } from "@chakra-ui/react";
import { MdOutlineDragIndicator as DragHandleIcon } from "react-icons/md";

export function DragHandle(props: Omit<IconButtonProps, "aria-label">) {
  return (
    <IconButton
      variant="link"
      padding={2}
      cursor="move"
      transform={"rotate(90deg)"}
      {...props}
      aria-label="Move"
    >
      <Icon fill="chakra-body-text" as={DragHandleIcon} />
    </IconButton>
  );
}
