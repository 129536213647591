import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import queryString from "query-string";
import { useMemo } from "react";
import {
  Navigate,
  generatePath,
  useLocation,
  useParams,
} from "react-router-dom";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import { SessionType } from "../session/types";
import { routes } from "../utils/routes";
import { SessionTypeEnumParam } from "./LaunchPageContext";
import { RenderMode, TargetDeviceType } from "./launch/types";

const stringify = queryString.stringify;

type LegacyLaunchPageParams = {
  groupId?: string;
  id: string;
};

export const LegacyLaunchPage = () => {
  const location = useLocation();
  const { id: applicationId, groupId } = useParams<LegacyLaunchPageParams>();
  const [
    {
      device: queryParamSessionType,
      args: launchArgs,
      forceColdVm,
      vmImage,
      region,
      vmSize,
    },
  ] = useQueryParams({
    device: SessionTypeEnumParam,
    args: StringParam,
    forceColdVm: BooleanParam,
    vmImage: StringParam,
    region: StringParam,
    vmSize: StringParam,
  });

  const search = useMemo(() => {
    return stringify({
      args: launchArgs,
      forceColdVm: forceColdVm ? 1 : 0,
      vmImage,
      region,
      vmSize,
    });
  }, [forceColdVm, launchArgs, region, vmImage, vmSize]);

  let targetDeviceType: TargetDeviceType, renderMode: RenderMode;
  const route = groupId ? routes.launch.appViaGroup : routes.launch.app,
    baseRouteArgs = {
      id: applicationId ?? null,
      groupId: groupId ?? null,
    };
  if (queryParamSessionType === SessionType.LocallyRenderedWindows) {
    renderMode = "local";
    targetDeviceType = "windows";
  } else if (queryParamSessionType === SessionType.LocallyRenderedStandalone) {
    renderMode = "local";
    targetDeviceType = "standalone";
  } else if (queryParamSessionType === SessionType.CloudRenderedNonVR) {
    renderMode = "cloud";
    targetDeviceType = "browser";
  } else if (queryParamSessionType === SessionType.CloudRenderedVR) {
    renderMode = "cloud";
    targetDeviceType = "standalone";
  } else {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>Unsupported launch mode!</AlertDescription>
      </Alert>
    );
  }

  return (
    <Navigate
      to={{
        ...location,
        pathname: generatePath(route, {
          ...baseRouteArgs,
          targetDeviceType,
          renderMode,
        }),
        search,
      }}
    />
  );
};
