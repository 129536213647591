import { Text } from "@chakra-ui/layout";
import {
  CircularProgress,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  OrderedList,
  Stack,
  Tooltip,
  UnorderedList,
  useClipboard,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "../../components/icons";
import { useCurrentClientApplicationVersionQuery } from "../../hooks";
import { ClientApplicationType } from "../../hooks/types";
import { useDeviceTranslation } from "./useDeviceTranslation";

export function InstallationInstructionsMDM({
  clientType,
}: {
  clientType: ClientApplicationType;
}) {
  // Quest query
  const { data: { binary: downloadUrl } = {}, isLoading } =
    useCurrentClientApplicationVersionQuery(ClientApplicationType.MetaQuest);
  const { onCopy } = useClipboard(downloadUrl || "");
  const { t } = useTranslation();

  return (
    <Stack spacing={4}>
      <Text>
        {t(
          "clients.installation_methods.mdm.intro",
          useDeviceTranslation(clientType),
        )}
      </Text>

      <OrderedList spacing={2}>
        <ListItem>
          <Text as="strong">
            {t("clients.installation_methods.mdm.steps.login.title")}
          </Text>
          <Text>{t("clients.installation_methods.mdm.steps.login.text")}</Text>
        </ListItem>
        <ListItem>
          <Text as="strong">
            {t(
              "clients.installation_methods.mdm.steps.navigate_to_app_management.title",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.navigate_to_app_management.text",
            )}
          </Text>
        </ListItem>
        <ListItem>
          <Text as="strong">
            {t("clients.installation_methods.mdm.steps.add_application.title")}
          </Text>
          <Text>
            {t("clients.installation_methods.mdm.steps.add_application.text")}
          </Text>
          <InputGroup size="sm" marginY={2}>
            <Input
              variant="outline"
              value={downloadUrl ?? "Loading ..."}
              isReadOnly
              isDisabled={isLoading}
            />
            <InputRightElement>
              <Tooltip
                label={t("clients.installation_methods.mdm.copy_download_url")}
                borderRadius={4}
              >
                <IconButton
                  size="sm"
                  onClick={onCopy}
                  icon={
                    isLoading ? (
                      <CircularProgress isIndeterminate />
                    ) : (
                      <Icon as={CopyIcon} />
                    )
                  }
                  aria-label="Click to copy"
                  variant="ghost"
                />
              </Tooltip>
            </InputRightElement>
          </InputGroup>
        </ListItem>
        <ListItem>
          <Text as="strong">
            {t(
              "clients.installation_methods.mdm.steps.choose_deployment_method.title",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.choose_deployment_method.text",
            )}
          </Text>

          <UnorderedList>
            <ListItem>
              <Text as="strong">
                {t(
                  "clients.installation_methods.mdm.steps.choose_deployment_method.options.direct_upload.title",
                )}
              </Text>
              <Text>
                {t(
                  "clients.installation_methods.mdm.steps.choose_deployment_method.options.direct_upload.text",
                )}
              </Text>
            </ListItem>
            <ListItem>
              <Text as="strong">
                {t(
                  "clients.installation_methods.mdm.steps.choose_deployment_method.options.external_link.title",
                )}
              </Text>
              <Text>
                {t(
                  "clients.installation_methods.mdm.steps.choose_deployment_method.options.external_link.text",
                )}
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text as="strong">
            {t(
              "clients.installation_methods.mdm.steps.configure_app_settings.title",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.configure_app_settings.text",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.configure_app_settings.text2",
            )}
          </Text>
        </ListItem>
        <ListItem>
          <Text as="strong">
            {t(
              "clients.installation_methods.mdm.steps.set_permissions_and_policies.title",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.set_permissions_and_policies.text",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.set_permissions_and_policies.text2",
            )}
          </Text>
        </ListItem>
        <ListItem>
          <Text as="strong">
            {t(
              "clients.installation_methods.mdm.steps.deploy_the_application.title",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.deploy_the_application.text",
            )}
          </Text>
        </ListItem>
        <ListItem>
          <Text as="strong">
            {t(
              "clients.installation_methods.mdm.steps.verify_deployment.title",
            )}
          </Text>
          <Text>
            {t("clients.installation_methods.mdm.steps.verify_deployment.text")}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.verify_deployment.text2",
            )}
          </Text>
        </ListItem>
        <ListItem>
          <Text as="strong">
            {t(
              "clients.installation_methods.mdm.steps.manage_updates_and_support.title",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.manage_updates_and_support.text",
            )}
          </Text>
          <Text>
            {t(
              "clients.installation_methods.mdm.steps.manage_updates_and_support.text2",
            )}
          </Text>
        </ListItem>
      </OrderedList>
    </Stack>
  );
}
