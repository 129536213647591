import { UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { TAuthConfig, TRefreshTokenExpiredEvent } from "react-oauth2-code-pkce";
import { portalOAuth2ClientId as clientId, portalBackendUrl } from "../config";
import { PortalError } from "../hooks/types";
import { useActiveOrganizationQuery } from "../hooks/useActiveOrganizationQuery";

export function useAuthConfig() {
  const activeOrganizationQuery = useActiveOrganizationQuery();

  return useMemo<
    Pick<
      UseQueryResult<TAuthConfig, AxiosError<PortalError>>,
      "isLoading" | "isError" | "isSuccess" | "error" | "data"
    >
  >(() => {
    const authorizationEndpoint =
      activeOrganizationQuery.data?.authorization_endpoint;

    if (!authorizationEndpoint) {
      return {
        ...activeOrganizationQuery,
        data: undefined as unknown as TAuthConfig,
      };
    }
    return {
      ...activeOrganizationQuery,
      data: {
        clientId,
        authorizationEndpoint: authorizationEndpoint,
        tokenEndpoint: `${portalBackendUrl}/oauth/token/`,
        redirectUri: `${document.location.origin}/oauth2/callback`,
        scope: "read",
        autoLogin: false,
        onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
          event.login(),
        // no jwt right now
        decodeToken: false,
        storage: "session",
        clearURL: false,
      },
    };
  }, [activeOrganizationQuery]);
}
