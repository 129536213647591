import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface DesktopClientState {
  isConnected: boolean;
  isDeviceAuthSupported: boolean;
  desktopClientName?: string;
  connectionAttemptFailed: boolean;
  isAvailableLocally: boolean;
}

// Define the initial state using that type
const initialState: DesktopClientState = {
  isConnected: false,
  isDeviceAuthSupported: false,
  connectionAttemptFailed: false,
  isAvailableLocally: false,
};

interface HandshakeCompletedPayload {
  isDeviceAuthSupported: boolean;
  name: string;
}

export const desktopClientSlice = createSlice({
  name: "desktopClient",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handshakeCompleted: (
      state,
      action: PayloadAction<HandshakeCompletedPayload>,
    ) => {
      state.isConnected = true;
      state.isDeviceAuthSupported = action.payload.isDeviceAuthSupported;
      state.desktopClientName = action.payload.name;
      state.connectionAttemptFailed = false;
    },
    connectionLost: (state) => {
      state.isConnected = false;
      state.isDeviceAuthSupported = false;
      state.desktopClientName = undefined;
      state.connectionAttemptFailed = false;
      state.isAvailableLocally = false;
    },
    connectionAttemptFailed: (state) => {
      state.connectionAttemptFailed = true;
    },
    availableLocally: (state) => {
      state.isAvailableLocally = true;
    },
    unavailableLocally: (state) => {
      state.isAvailableLocally = false;
    },
  },
});

export const {
  handshakeCompleted,
  connectionLost,
  connectionAttemptFailed,
  availableLocally,
  unavailableLocally,
} = desktopClientSlice.actions;

export const selectDesktopClient = (state: RootState) => state.desktopClient;

export const selectDesktopClientName = createSelector(
  selectDesktopClient,
  (desktopClient) => desktopClient.desktopClientName,
);

export default desktopClientSlice.reducer;
