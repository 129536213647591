import { extendTheme, getToken } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { cssVar } from "@chakra-ui/styled-system";
import "@fontsource/raleway/400.css";
import "@fontsource/raleway/700.css";
import Color from "color";
import generatePalette from "./palette";

const $menuBg = cssVar("menu-bg");
const $popperBg = cssVar("popper-bg");

function getTextOnBrandColor(
  brandingColor: string,
  { theme, colorMode }: StyleFunctionProps,
) {
  const isDarkMode = colorMode === "dark";

  const textColor = getToken(
    "colors",
    theme.semanticTokens.colors["chakra-body-text"][
      isDarkMode ? "_dark" : "_light"
    ],
  )(theme);
  const bgColor = getToken(
    "colors",
    theme.semanticTokens.colors["chakra-body-bg"][
      isDarkMode ? "_dark" : "_light"
    ],
  )(theme);
  // find out what's a suitable text color for the branded buttons
  return Color(textColor).contrast(Color(brandingColor)) >=
    Color(bgColor).contrast(Color(brandingColor))
    ? "chakra-body-text"
    : "chakra-body-bg";
}

function brandTheme(brandingColor = "#04385F", backgroundColor = "#ffffff") {
  const brandColors = generatePalette(brandingColor, 500);
  const bgColor = Color(backgroundColor);

  return extendTheme({
    semanticTokens: {
      colors: {
        "chakra-subtle-text": { _light: "gray.600", _dark: "whiteAlpha.500" },
        "chakra-subtle-bg": {
          _light: "background.400",
          _dark: "background.700",
        },
        "chakra-body-bg": {
          _light: "background.500",
          _dark: "background.800",
        },
      },
    },
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    colors: {
      brand: brandColors,
      backgroundAlpha: {
        50: bgColor.alpha(0.04).hexa(),
        100: bgColor.alpha(0.06).hexa(),
        200: bgColor.alpha(0.08).hexa(),
        300: bgColor.alpha(0.16).hexa(),
        400: bgColor.alpha(0.24).hexa(),
        500: bgColor.alpha(0.36).hexa(),
        600: bgColor.alpha(0.48).hexa(),
        700: bgColor.alpha(0.64).hexa(),
        800: bgColor.alpha(0.8).hexa(),
        900: bgColor.alpha(0.92).hexa(),
      },
      background: generatePalette(backgroundColor, 800),
    },
    shadows: {
      outline: `0 0 0 3px ${Color(brandingColor).alpha(0.3).hexa()}`,
    },
    fonts: {
      heading: "Raleway, sans-serif",
      body: "Raleway, sans-serif",
    },
    components: {
      Popover: {
        baseStyle: {
          popper: {
            zIndex: "var(--chakra-zIndices-popover)",
          },
          content: {
            [$popperBg.variable]: "var(--chakra-colors-chakra-subtle-bg)",
            _dark: {
              [$popperBg.variable]: "var(--chakra-colors-chakra-subtle-bg)",
            },
          },
        },
      },
      Menu: {
        baseStyle: {
          list: {
            zIndex: "var(--chakra-zIndices-dropdown)",
            [$menuBg.variable]: "var(--chakra-colors-chakra-subtle-bg)",
            _dark: {
              [$menuBg.variable]: "var(--chakra-colors-chakra-subtle-bg)",
            },
          },
        },
        variants: {
          branded: (props: StyleFunctionProps) => {
            return {
              list: {
                [$menuBg.variable]: "var(--chakra-colors-brand-500)",
                color: getTextOnBrandColor(brandingColor, props),
                _dark: {
                  [$menuBg.variable]: "var(--chakra-colors-brand-500)",
                },
              },
              item: {
                _focus: {
                  [$menuBg.variable]: "var(--chakra-colors-brand-600)",
                  _dark: {
                    [$menuBg.variable]: "var(--chakra-colors-brand-400)",
                  },
                },
              },
            };
          },
        },
      },
      Button: {
        baseStyle: {
          fontWeight: "bold", // Normally, it is "semibold"
        },
        variants: {
          solid: (props: StyleFunctionProps) => {
            if (props.colorScheme === "brand") {
              return {
                bg: "brand.500",
                color: getTextOnBrandColor(brandingColor, props),
              };
            } else {
              return {};
            }
          },
        },
      },
      Link: {
        baseStyle: {
          textDecoration: "underline",
          _hover: {
            color: "brand.600",
            _dark: {
              color: "brand.200",
            },
          },
        },
        variants: {
          unstyled: {
            textDecoration: "none",
            color: "inherit",
            _hover: {
              textDecoration: "none",
            },
          },
        },
      },
    },
    styles: {
      global: () => ({
        body: {
          // disable pinch & zoom on touch devices
          touchAction: "pan-y pan-x",
        },
      }),
    },
  });
}

export { brandTheme };
