import { useMemo } from "react";
import { usePrevious } from "react-use";
import { useAppSelector } from ".";
import {
  selectDevicesListUpdateCounter,
  selectOnlineClientDevices,
} from "../features/devicesSlice";
import { ClientDevice } from "../session/types";

function getClientDifference(arr1: ClientDevice[], arr2: ClientDevice[]) {
  return arr1.filter(
    (item1) => !arr2.some((item2) => item2.identifier === item1.identifier),
  );
}

export function useDeviceConnectionChanges() {
  const connectedClientDevices = useAppSelector(selectOnlineClientDevices);
  const deviceListUpdateCounter = useAppSelector(
    selectDevicesListUpdateCounter,
  );
  const previouslyConnectedClientDevices = usePrevious(connectedClientDevices);

  return useMemo(() => {
    if (deviceListUpdateCounter < 2) {
      return {
        newlyConnectedDevices: [],
        disconnectedDevices: [],
      };
    }

    return {
      newlyConnectedDevices: getClientDifference(
        connectedClientDevices,
        previouslyConnectedClientDevices ?? [],
      ),
      disconnectedDevices: getClientDifference(
        previouslyConnectedClientDevices ?? [],
        connectedClientDevices,
      ),
    };
  }, [
    connectedClientDevices,
    deviceListUpdateCounter,
    previouslyConnectedClientDevices,
  ]);
}
