import { useDisclosure } from "@chakra-ui/react";
import { isAxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isArray } from "remeda";
import { ApplicationBuildId } from "./types";
import {
  SelectCloudRenderingRegion,
  useBestCloudRenderingRegionQuery,
} from "./useCloudRenderingRegionsQuery";
import { useLargeDistanceToRegionWarning } from "./useLargeDistanceToRegionWarning";
import { useUserPreferences } from "./useUserPreferences";

export const useRegionNotSupportedWarning = ({
  applicationBuildId,
  requestedRegion: userRequestedRegion,
  requestedVmSize,
  onRejected,
  onAccepted: outerOnAccepted,
}: {
  applicationBuildId?: ApplicationBuildId;
  requestedRegion?: string;
  requestedVmSize?: string;
  onRejected: () => void;
  onAccepted: (region: SelectCloudRenderingRegion) => void;
}) => {
  const { data: userPreference } = useUserPreferences();
  const [selectedRegion, setSelectedRegion] =
    useState<SelectCloudRenderingRegion>();
  const onAccepted = useCallback(
    (region: SelectCloudRenderingRegion) => {
      setSelectedRegion(region);
      outerOnAccepted(region);
    },
    [outerOnAccepted],
  );
  const requestedRegion = useBestCloudRenderingRegionQuery(
    {
      preferredRegion:
        userRequestedRegion ?? userPreference?.preferredRenderRegion,
      preferredVmSize: requestedVmSize,
    },
    false,
  );
  const {
    isInvalidRequestedRegion,
    isInvalidVmSize,
  }: {
    isInvalidRequestedRegion: boolean;
    isInvalidVmSize: boolean;
  } = useMemo(() => {
    const isBadRequest =
      userRequestedRegion &&
      requestedRegion.error &&
      isAxiosError(requestedRegion.error) &&
      requestedRegion.error.response?.status === 400;
    if (isAxiosError(requestedRegion.error) && isBadRequest) {
      const errorResponse = requestedRegion.error?.response?.data ?? {};
      return {
        isInvalidRequestedRegion:
          errorResponse.Region ||
          (isArray<string>(errorResponse.VmSize) &&
            errorResponse.VmSize.some(
              (message: string) =>
                message === "VM size is not available in region",
            )),
        isInvalidVmSize: !!errorResponse.VmSize,
      };
    }
    return { isInvalidRequestedRegion: false, isInvalidVmSize: false };
  }, [requestedRegion.error, userRequestedRegion]);

  // in manual mode, this is the user selected Region (either via query param or manual user selection), otherwise, it's just the next best region
  const bestAvailableRegion = useBestCloudRenderingRegionQuery({
    appIds: applicationBuildId ? [applicationBuildId] : undefined,
    preferredRegion: isInvalidRequestedRegion ? undefined : userRequestedRegion,
    preferredVmSize: isInvalidVmSize ? undefined : requestedVmSize,
  });
  const {
    isOpen,
    onOpen: onPrompt,
    onClose,
  } = useDisclosure({ onClose: onRejected });

  const returnValue = useMemo(() => {
    return {
      alertProps: {
        isOpen,
        onAccept: onAccepted,
        onDecline: onClose,
        bestRegion: bestAvailableRegion,
        alternativeRegion: requestedRegion,
      },
      noValidRegion: bestAvailableRegion.isError && requestedRegion.isError,
    };
  }, [isOpen, onClose, onAccepted, bestAvailableRegion, requestedRegion]);

  useEffect(() => {
    if (!userPreference) {
      return;
    }

    // no user preference ==> automatic selection
    const isAutomaticMode = !userPreference.preferredRenderRegion;

    if (!bestAvailableRegion?.region) {
      return;
    }

    if (isAutomaticMode) {
      // automatic mode, we just accept the best region
      onAccepted({
        ...bestAvailableRegion,
        // we don't want to pass vm size in automatic mode
        vmSize: undefined,
      });
      return;
    }

    if (
      requestedRegion?.region &&
      requestedRegion.region.name === bestAvailableRegion.region.name
    ) {
      // if the user has already selected the best region, we just accept it
      onAccepted({
        ...bestAvailableRegion,
        // we don't want to pass vm size in automatic mode
        vmSize: undefined,
      });
      return;
    }

    // in all other cases, we need to prompt the user
    onPrompt();
  }, [
    onPrompt,
    onAccepted,
    bestAvailableRegion,
    userPreference,
    requestedRegion,
    userRequestedRegion,
  ]);

  // show a warning if the selected cloud-renderin gregion is far away from the user
  useLargeDistanceToRegionWarning(selectedRegion?.region);

  return returnValue;
};
