import { useEffect, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useAuthentication } from "../auth";
import { useVirtualMachinesQuery } from "./useVirtualMachinesQuery";

type FeatureFlags = {
  encryptVrStream?: boolean;
  vmImage?: string;
  debugModeEnabled?: boolean;
  virtualMachineId?: string;
  [key: string]: boolean | string | undefined;
};

export function useFeatureFlags() {
  const { user } = useAuthentication();
  const key = useMemo(() => `portal-feature-flags-${user?.id}`, [user?.id]);
  const { data: vms, isSuccess: vmsSuccess } = useVirtualMachinesQuery();

  const [featureFlags, setFeatureFlags] = useLocalStorage<FeatureFlags>(key, {
    encryptVrStream: undefined,
    debugModeEnabled: false,
  });

  const vm = useMemo(
    () =>
      vms?.items.find(
        (vm) =>
          vm.id === featureFlags.virtualMachineId &&
          vm.activeUserIdentifier === user?.id.toString(),
      ),
    [featureFlags, user?.id, vms?.items],
  );

  // check if the flags selected vm still exists. If not cleare it
  useEffect(() => {
    if (!vmsSuccess) {
      return;
    }

    if (vm) {
      return;
    }

    // reset the vm ID
    setFeatureFlags((flags) => ({
      ...flags,
      virtualMachineId: undefined,
    }));
  }, [vm, vmsSuccess, setFeatureFlags]);

  return [featureFlags, setFeatureFlags] as const;
}
