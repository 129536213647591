import { Stack } from "@chakra-ui/layout";
import { Center, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { LinkButton } from "../../../components";

export function NoSessionState() {
  const { t } = useTranslation();

  return (
    <Center>
      <Stack>
        <Text align="center" fontSize="xl">
          {t("session.idle")}
        </Text>
        <LinkButton colorScheme="brand" to={`/`}>
          {t("session.browse")}
        </LinkButton>
      </Stack>
    </Center>
  );
}
