import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes as CancelIcon } from "react-icons/fa";
import { MdFiberSmartRecord as SessionActiveIcon } from "react-icons/md";
import { useMatch } from "react-router-dom";
import { useAuthentication } from "../auth";
import { selectCloudRendering } from "../features/cloudRenderingSlice";
import { enableDebugMode, selectSessionState } from "../features/sessionSlice";
import { useAppDispatch, useAppSelector, useSessionDevice } from "../hooks";
import {
  useApplicationBuildQuery,
  useApplicationForApplicationBuildQuery,
} from "../hooks/useApplicationBuildsQuery";
import { useCloudRenderingRegion } from "../hooks/useCloudRenderingRegionsQuery";
import { useVmSizeQuery } from "../hooks/useVmSizesQuery";
import { SESSION_STATE } from "../session/session-state";
import type { SessionData } from "../session/types";
import { SessionType } from "../session/types";
import { sendEnableDebug, terminateSession } from "../signalR";
import { routes } from "../utils/routes";
import { AppBarButtonPopoverContent } from "./AppBarButtonPopover";
import { ApplicationImage } from "./ApplicationImage";
import { BrandedSkeleton } from "./BrandedSkeleton";
import { ConfirmSessionAbortDialog } from "./ConfirmSessionAbortDialog";
import { ExpectedWaitTime } from "./ExpectedWaitTime";
import { InputCopyableValue } from "./InputCopyableValue";
import { LinkButton } from "./LinkButton";
import { MotionIcon } from "./MotionIcon";
import { SessionDuration } from "./SessionDuration";

dayjs.extend(relativeTime);
dayjs.extend(duration);

function DesktopStartupProgress({ progress }: { progress?: number }) {
  if (!progress) return <>...</>;
  return <>{Math.floor((progress ?? 0) * 100) + "%"}</>;
}

function SessionInformation() {
  const currentSession = useAppSelector(selectSessionState);
  const cloudRendering = useAppSelector(selectCloudRendering);
  const { data: cloudRenderingRegion } = useCloudRenderingRegion(
    currentSession.renderRegion,
  );
  const vmSize = useVmSizeQuery(currentSession.vmSize);
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const dispatch = useAppDispatch();
  const applicationBuildQuery = useApplicationBuildQuery(
    currentSession.applicationBuildId,
  );

  const [enablingDebugMode, setEnablingDebugMode] = useState(false);

  const enableDebug = useCallback(async () => {
    if (!currentSession.id) throw new Error("No session id");

    if (!(await sendEnableDebug(currentSession.id))) {
      return;
    }

    dispatch(enableDebugMode());
    setEnablingDebugMode(true);

    // takes about 15s to enable debug mode, this helps to get some feedback
    const timeout = setTimeout(() => {
      setEnablingDebugMode(false);
    }, 15000);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentSession.id, dispatch]);

  const latencyMs = useMemo(() => {
    if (
      !currentSession.renderRegion ||
      !cloudRendering.regionsLatency[currentSession.renderRegion]
    )
      return undefined;

    return cloudRendering.regionsLatency[currentSession.renderRegion];
  }, [cloudRendering.regionsLatency, currentSession.renderRegion]);

  return (
    <Stack>
      <Heading as="h6" size="xs">
        {t("session.debug.id")}
      </Heading>
      <InputCopyableValue data={currentSession?.id} id={"portal-session-id"} />
      {(currentSession.type === SessionType.CloudRenderedNonVR ||
        currentSession.type === SessionType.CloudRenderedVR) && (
        <>
          {currentSession?.ipAddress && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.serverIp")}
              </Heading>
              <InputCopyableValue
                data={currentSession?.ipAddress}
                id={"portal-session-ip-address"}
              />
            </>
          )}
          {currentSession?.renderCloudProvider && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.cloudProvider")}
              </Heading>
              <Text>{currentSession?.renderCloudProvider}</Text>
            </>
          )}
          {currentSession?.renderRegion && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.region")}
              </Heading>
              <Text>
                {cloudRenderingRegion?.displayName ||
                  currentSession?.renderRegion}
              </Text>
            </>
          )}
          {currentSession?.vmSize && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.vm_size")}
              </Heading>
              <Text>{vmSize?.displayName}</Text>
            </>
          )}
          {applicationBuildQuery?.data?.id && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.app_version_api_id")}
              </Heading>
              <Text>{applicationBuildQuery?.data?.id}</Text>
            </>
          )}
          {applicationBuildQuery?.data?.version && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.app_version")}
              </Heading>
              <Text>{applicationBuildQuery?.data?.version}</Text>
            </>
          )}
          {currentSession?.vmImage && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.vm_image")}
              </Heading>
              <Text>{currentSession?.vmImage}</Text>
            </>
          )}
          {latencyMs && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.latency")}
              </Heading>
              <Text>
                {latencyMs.toFixed(0)}
                ms
              </Text>
            </>
          )}
          {currentSession.isActive && (
            <>
              <Heading as="h6" size="xs">
                {t("session.debug.duration")}
              </Heading>
              <Text>
                <SessionDuration />
              </Text>
            </>
          )}
        </>
      )}

      {user?.is_superuser &&
        currentSession.ipAddress &&
        currentSession.isCloudRendered && (
          <>
            <Divider />
            <Tooltip
              label={
                currentSession.debugModeEnabled
                  ? t("session.debug.debug_enable_tooltip")
                  : t("session.debug.enable_debug_tooltip")
              }
            >
              <Button
                onClick={() => enableDebug()}
                isDisabled={currentSession.debugModeEnabled}
                isLoading={enablingDebugMode}
              >
                {t("session.debug.enable_debug")}
              </Button>
            </Tooltip>
          </>
        )}
    </Stack>
  );
}

function ImageOverlay({
  session: currentSession,
}: {
  session: Pick<SessionData, "state" | "message" | "type" | "progress">;
}) {
  const { t } = useTranslation();
  const device = useSessionDevice();
  const isSessionReady = useMemo(
    () => currentSession.state === SESSION_STATE.READY,
    [currentSession.state],
  );
  const isSessionEnding = useMemo(
    () => currentSession.state === SESSION_STATE.ENDING,
    [currentSession.state],
  );
  const isDesktopSession =
    currentSession.type === SessionType.LocallyRenderedWindows;

  const spinner = (
    <CircularProgress isIndeterminate color="brand.500" size={10} />
  );

  return (
    <>
      {currentSession.state < SESSION_STATE.READY && spinner}
      <Text textAlign={"center"}>
        {isSessionReady &&
          t("session.ready_instructions", {
            device: device?.name ?? "Unknown",
          })}
        {currentSession.state < SESSION_STATE.READY &&
          (isDesktopSession ? (
            <DesktopStartupProgress progress={currentSession.progress} />
          ) : currentSession.message ===
            "This operation is taking longer than expected" ? (
            <>{t("session.taking_longer_than_expected")}</>
          ) : (
            <>
              {t("session.ready_in")}{" "}
              <ExpectedWaitTime session={currentSession} />
            </>
          ))}
        {isSessionEnding && <>{t("session.ending")}</>}
      </Text>
    </>
  );
}

function ActiveSessionOverview({
  session: currentSession,
  onGotoSession = () => {},
}: {
  session: SessionData;
  onGotoSession(): void;
}) {
  const { data: app } = useApplicationForApplicationBuildQuery(
    currentSession.applicationBuildId,
  );
  const { t } = useTranslation();
  const abortSessionDialogState = useDisclosure();

  const isSessionActive = useMemo(
    () => currentSession.state === SESSION_STATE.ACTIVE,
    [currentSession.state],
  );
  const isSessionEnding = useMemo(
    () => currentSession.state === SESSION_STATE.ENDING,
    [currentSession.state],
  );

  return (
    <>
      <Stack>
        <BrandedSkeleton isLoaded={!!app}>
          <Heading as="h3" fontSize="lg">
            {app?.name}
          </Heading>
        </BrandedSkeleton>
        <Box position="relative">
          <ApplicationImage
            src={app?.images?.[0]?.image}
            borderRadius={4}
            minHeight={120}
            filter="blur 5px"
          />

          <Stack
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="0"
            left="0"
            bgColor={!isSessionActive ? "backgroundAlpha.800" : undefined}
          >
            <ImageOverlay session={currentSession} />
          </Stack>
        </Box>
        {!isSessionEnding && (
          <HStack>
            <LinkButton
              to={routes.session}
              colorScheme="brand"
              variant="solid"
              width="full"
              onClick={onGotoSession}
              linkProps={{ flexGrow: 1 }}
            >
              {t("session.go_to_session")}
            </LinkButton>
            <IconButton
              aria-label="Abort session"
              icon={<Icon as={CancelIcon} />}
              onClick={abortSessionDialogState.onOpen}
              colorScheme="red"
            />
          </HStack>
        )}
      </Stack>
      <ConfirmSessionAbortDialog
        isOpen={abortSessionDialogState.isOpen}
        onClose={abortSessionDialogState.onClose}
        onConfirm={() => {
          currentSession.id && terminateSession(currentSession.id);
        }}
      />
    </>
  );
}

export function ActiveSessionButton(props: ButtonProps) {
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const currentSession = useAppSelector(selectSessionState);
  const borderColor = useColorModeValue("gray.600", "white");
  const isOnSessionPage = !!useMatch(routes.session);
  const isSessionReady = useMemo(
    () => currentSession.state === SESSION_STATE.READY,
    [currentSession.state],
  );
  const isSessionActive = useMemo(
    () => currentSession.state === SESSION_STATE.ACTIVE,
    [currentSession.state],
  );
  const isSessionEnding = useMemo(
    () => currentSession.state === SESSION_STATE.ENDING,
    [currentSession.state],
  );
  const isDesktopSession =
    currentSession.type === SessionType.LocallyRenderedWindows;

  const getIcon = () => {
    if (
      currentSession.state <= SESSION_STATE.READY ||
      currentSession.state === SESSION_STATE.ENDING
    ) {
      return <CircularProgress isIndeterminate color="brand.500" size={5} />;
    }

    if (isSessionActive) {
      return (
        <MotionIcon
          initial={{ opacity: 1 }}
          animate={{ opacity: 0.2 }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            type: "spring",
            duration: 1,
          }}
          color="red.400"
          as={SessionActiveIcon}
        />
      );
    }

    return undefined;
  };

  const getButtonLabel = () => {
    if (isSessionReady) {
      return t("session.ready_short");
    }

    if (isSessionEnding) {
      return t("session.ending");
    }

    if (isSessionActive) {
      return t("session.active");
    }

    if (isDesktopSession) {
      return <DesktopStartupProgress progress={currentSession.progress} />;
    }

    return <ExpectedWaitTime session={currentSession} />;
  };

  return (
    <Popover
      trigger="click"
      placement="bottom-end"
      isOpen={isPopoverOpen}
      closeOnBlur={false}
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <Button
          leftIcon={getIcon()}
          variant="outline"
          borderColor={borderColor}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          {...props}
        >
          {getButtonLabel()}
        </Button>
      </PopoverTrigger>
      <AppBarButtonPopoverContent width="xs">
        <PopoverHeader>{t("session.active")}</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton onClick={() => setIsPopoverOpen(false)} top={2} />
        <PopoverBody>
          <Stack>
            {!isOnSessionPage ? (
              <ActiveSessionOverview
                onGotoSession={() => setIsPopoverOpen(false)}
                session={currentSession}
              />
            ) : (
              <SessionInformation />
            )}
          </Stack>
        </PopoverBody>
      </AppBarButtonPopoverContent>
    </Popover>
  );
}
