import { SESSION_STATE } from "../session/session-state";
import type { SessionData } from "../session/types";
import { TimeTicker } from "./TimeTicker";

export function ExpectedWaitTime({
  session,
}: {
  session: Pick<SessionData, "state" | "message" | "expectedWaitTimeSec">;
}) {
  if (
    (session.state && session.state < SESSION_STATE.LOADING) ||
    !session.expectedWaitTimeSec ||
    session.message === "This operation is taking longer than expected"
  ) {
    return <>...</>;
  }

  // wait time can also become negative so we need to cap it
  const seconds = Math.max(0, Math.round(session.expectedWaitTimeSec));
  return (
    <>
      {"~ "}
      <TimeTicker seconds={seconds} min={0} />
    </>
  );
}
