import {
  Box,
  Button,
  Heading,
  Link,
  StackProps,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import _NoDataImage from "../assets/img/undraw_empty.svg?react";
import { useControlPanelUrl } from "../hooks/useControlPanelUrl";
import { useCurrentUserPlatformPermissionsQuery } from "../hooks/useUserPlatformPermissionsQuery";

export const NoDataImage = chakra(_NoDataImage);

export function EmptyList(props: StackProps) {
  const { t } = useTranslation();
  const controlPanelUrl = useControlPanelUrl();
  const userPlatformPermissionsQuery = useCurrentUserPlatformPermissionsQuery();
  const userIsApplicationManager = useMemo(
    () =>
      !!(userPlatformPermissionsQuery.data ?? []).find(
        (perm) => perm === "assets.add_application",
      ),
    [userPlatformPermissionsQuery.data],
  );

  return (
    <VStack marginTop="auto" spacing={4} {...props}>
      <Box marginY={5}>
        <NoDataImage boxSize={64} fill="brand-bg" />
      </Box>
      <Heading>{t("list.empty.title")}</Heading>
      <VStack>
        <Text>{t("list.empty.description")}</Text>
        <Text>
          {t(
            userIsApplicationManager
              ? "list.empty.action_description"
              : "list.empty.action_description_no_permission",
          )}
        </Text>
      </VStack>
      {userIsApplicationManager && (
        <Link href={controlPanelUrl} target="_blank" _hover={undefined}>
          <Button colorScheme="brand">{t("list.empty.action_label")}</Button>
        </Link>
      )}
    </VStack>
  );
}
