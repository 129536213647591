import { HStack, Spacer, Stack, VStack } from "@chakra-ui/layout";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Heading,
  Icon,
  IconButton,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FaExclamationCircle as WarningIcon } from "react-icons/fa";
import { BrandedSkeleton } from "../../components";
import { selectOnlineDevices } from "../../features/devicesSlice";
import {
  requestNotificationPermission,
  selectNotification,
} from "../../features/notificationSlice";
import { SessionDataExtended } from "../../features/sessionSlice";
import {
  useAppDispatch,
  useAppSelector,
  useClientApplicationVersionInformation,
} from "../../hooks";
import { useApplicationForApplicationBuildQuery } from "../../hooks/useApplicationBuildsQuery";
import { SESSION_STATE } from "../../session/session-state";
import { isIOS } from "../../utils/browser-support";
import { useSessionPageContext } from "./SessionPageContext";

const browserSupportsNotifications = "Notification" in window && !isIOS;

export function SessionControls({ session }: { session: SessionDataExtended }) {
  const connectedDevices = useAppSelector(selectOnlineDevices);
  const activeDevice = connectedDevices.find(
    (d) => d.identifier === session.deviceIdentifier,
  );
  const { data: app } = useApplicationForApplicationBuildQuery(
    session.applicationBuildId,
  );
  const dispatch = useAppDispatch();
  const { permission: notificationPermission } =
    useAppSelector(selectNotification);
  const enableBrowserNotifications = useCallback(() => {
    dispatch(requestNotificationPermission());
  }, [dispatch]);
  const { t } = useTranslation();
  const { showAbortSessionDialog } = useSessionPageContext();
  const { isOutdated } = useClientApplicationVersionInformation(activeDevice);
  const [isHidingError, setIsHidingError] = useState<boolean>(false);

  return (
    <VStack
      alignItems="normal"
      paddingX={{ base: 0, md: 4 }}
      minWidth={["full", "full", "xs", "xs", "sm"]}
      maxWidth={[null, null, "30%"]}
      justifyContent="space-between"
    >
      <Spacer flexGrow="0" />

      <VStack alignItems="normal">
        <BrandedSkeleton isLoaded={!!app?.name || !session.id}>
          <Heading as="h3">{app?.name || "Loading ..."}</Heading>
          <HStack>
            {session.isCloudRendered && !session.isBrowser && activeDevice && (
              <Text fontSize="m" color={"chakra-subtle-text"}>
                <Trans
                  i18nKey="session.running_on"
                  values={{
                    name: activeDevice.name,
                  }}
                  components={{ bold: <strong /> }}
                />
              </Text>
            )}
            {session.isCloudRendered && !session.isBrowser && isOutdated && (
              <Tooltip
                hasArrow
                label={`Client is outdated, please update soon.`}
              >
                <IconButton
                  aria-label="Outdated version warning"
                  icon={<Icon as={WarningIcon} />}
                  color="yellow.200"
                  variant="link"
                  size="xs"
                />
              </Tooltip>
            )}
          </HStack>
        </BrandedSkeleton>
      </VStack>

      <Stack>
        {(session.issues.hasBadLatency ||
          session.issues.hasHighJitter ||
          session.issues.hasNotEnoughBandwidth ||
          session.issues.hasPackageLoss) &&
          isHidingError === false && (
            <Alert
              status="warning"
              variant="subtle"
              position={"relative"}
              flexDirection="column"
              alignItems={"normal"}
            >
              <AlertTitle
                paddingRight={6}
                marginRight={0}
                marginBottom={2}
                position="relative"
              >
                {t("session.networking_problems_title")}
                <CloseButton
                  position="absolute"
                  size="sm"
                  right={-1}
                  top={-1}
                  onClick={() => setIsHidingError(true)}
                />
              </AlertTitle>
              <AlertDescription>
                <Text>{t("session.networking_problems")}</Text>
                {session.issues.hasBadLatency && (
                  <Text>{t("session.networking_latency")}</Text>
                )}
                {session.issues.hasNotEnoughBandwidth && (
                  <Text>{t("session.networking_bandwidth")}</Text>
                )}
                {(session.issues.hasHighJitter ||
                  session.issues.hasPackageLoss) && (
                  <Text>{t("session.networking_unstable")}</Text>
                )}
                <Text color="chakra-subtle-text" marginTop={4}>
                  {t("session.networking_help")}{" "}
                  <Link
                    isExternal
                    href={
                      "https://innoactive.io/troubleshoot/cloud-rendering-network-performance"
                    }
                    textDecoration={"underline"}
                    _hover={{ color: "brand.500" }}
                  >
                    Network Performance Troubleshooting Guide
                  </Link>
                </Text>
              </AlertDescription>
            </Alert>
          )}
        {session.isLoading && (
          <Alert
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            width={{ base: "100%", md: "auto" }}
          >
            <Box flex="1">
              <AlertTitle>{t("session.preparing")}</AlertTitle>
              <AlertDescription display="block">
                <VStack marginTop={2}>
                  <Text>
                    {t(
                      session.isVr
                        ? "session.continue_while_waiting"
                        : "session.continue_while_waiting_no_hmd",
                    )}
                  </Text>

                  {browserSupportsNotifications && (
                    <Text>
                      {notificationPermission === "granted" ? (
                        t("session.notified_when_ready")
                      ) : (
                        <Trans i18nKey="session.enable_notifications_to_be_notified">
                          To get notified once ready, please click{" "}
                          <Link onClick={enableBrowserNotifications}>here</Link>
                        </Trans>
                      )}
                    </Text>
                  )}
                </VStack>
              </AlertDescription>
            </Box>
          </Alert>
        )}
        {session.isValid && (
          <Button
            onClick={showAbortSessionDialog}
            variant="outline"
            width="full"
          >
            {t(
              session.state <= SESSION_STATE.READY
                ? "session.abort"
                : "session.end",
            )}
          </Button>
        )}
      </Stack>
    </VStack>
  );
}
