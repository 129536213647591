import { Button, ButtonProps } from "@chakra-ui/button";
import { Link, LinkProps } from "@chakra-ui/layout";
import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export type LinkButtonProps = Pick<
  RouterLinkProps,
  "to" | "replace" | "state"
> &
  ButtonProps & { linkProps?: LinkProps };

export const LinkButton = forwardRef(
  (
    { to, linkProps = {}, isDisabled, ...buttonProps }: LinkButtonProps,
    ref,
  ) => {
    return (
      <Link
        _hover={{ textDecoration: "none" }}
        as={RouterLink}
        to={isDisabled ? "#" : to}
        {...linkProps}
      >
        <Button {...buttonProps} isDisabled={isDisabled} ref={ref} />
      </Link>
    );
  },
);
