import {
  createListenerMiddleware,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ApplicationBuildId, CloudRenderingRegion } from "../hooks/types";
import { RootState } from "../store";
import { latencyLookup } from "../utils/latency-lookup";

type CloudRenderingState = {
  regionsLatency: { [id: string]: number };
  regionsLatencies: { [id: string]: number[] };
  regionsToTest: CloudRenderingRegion[];
  latencyTestRunning: boolean;
  latencyTestCompleted: boolean;
  configurationDialogShown: boolean;
  applicationBuildId?: ApplicationBuildId;
  numberOfRegionsToTest: number;
  numberOfRegionsTested: number;
};

type LatencyCheck = {
  region: string;
  latencyMs: number;
};

function delay(ms: number, signal: AbortSignal) {
  return new Promise<void>((resolve, reject) => {
    // eslint-disable-next-line prefer-const
    let timeout: number;
    const handleAbort = () => {
      clearTimeout(timeout);
      reject(signal.reason);
    };
    signal.addEventListener("abort", handleAbort);
    timeout = window.setTimeout(() => {
      signal?.removeEventListener("abort", handleAbort);
      resolve();
    }, ms);
  });
}

const latencyPersistenceKey = "portal-latency";

function persistLatencyInformation(latency: { [id: string]: number }) {
  try {
    localStorage.setItem(latencyPersistenceKey, JSON.stringify(latency ?? {}));
  } catch {
    // ignore write errors
  }
}

function loadLatencyInformation(): { [id: string]: number } {
  try {
    const serializedState = localStorage.getItem(latencyPersistenceKey);
    if (!serializedState) {
      return {};
    }

    return JSON.parse(serializedState);
  } catch {
    /* empty */
  }
  return {};
}

export const cloudRenderingSlice = createSlice({
  name: "cloud-rendering",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: () => {
    const initialState: CloudRenderingState = {
      regionsLatency: loadLatencyInformation(),
      regionsLatencies: {},
      regionsToTest: [],
      latencyTestRunning: false,
      latencyTestCompleted: false,
      configurationDialogShown: false,
      numberOfRegionsToTest: 0,
      numberOfRegionsTested: 0,
    };

    if (Object.keys(initialState.regionsLatency).length !== 0) {
      initialState.latencyTestCompleted = true;
    }
    return initialState;
  },
  reducers: {
    openConfigurationDialog: (
      state,
      action: PayloadAction<ApplicationBuildId | undefined>,
    ) => {
      state.configurationDialogShown = true;
      state.applicationBuildId = action?.payload;
    },
    setRegionsToTest: (
      state,
      action: PayloadAction<CloudRenderingRegion[]>,
    ) => {
      state.latencyTestCompleted = false;
      state.latencyTestRunning = true;
      state.regionsToTest = action.payload;
      state.numberOfRegionsToTest = action.payload.length;
      state.numberOfRegionsTested = 0;
    },
    resetTestRegions: (state) => {
      state.latencyTestCompleted = false;
      state.latencyTestRunning = false;
      state.regionsLatency = {};
      state.regionsLatencies = {};
    },
    closeConfigurationDialog: (state) => {
      state.configurationDialogShown = false;
    },
    addLatency: (state, action: PayloadAction<LatencyCheck>) => {
      const latencies = state.regionsLatencies[action.payload.region] ?? [];

      latencies.push(action.payload.latencyMs);
      state.regionsLatencies[action.payload.region] = latencies;

      // calculate the median latency
      const half = Math.floor(latencies.length / 2);
      if (latencies.length % 2) {
        state.regionsLatency[action.payload.region] = latencies[half];
      } else {
        state.regionsLatency[action.payload.region] = Math.floor(
          (latencies[half - 1] + latencies[half]) / 2,
        );
      }
    },
    completeLatencyTest: (state) => {
      state.latencyTestCompleted = true;
      state.latencyTestRunning = false;
      state.regionsToTest = [];
      state.numberOfRegionsToTest = 0;
      state.numberOfRegionsTested = state.numberOfRegionsToTest;
    },
    abortLatencyTest: (state) => {
      state.latencyTestCompleted = false;
      state.latencyTestRunning = false;
      state.regionsToTest = [];
      state.numberOfRegionsToTest = 0;
      state.numberOfRegionsTested = state.numberOfRegionsToTest;
    },
    removeRegionToTest: (state, action: PayloadAction<string>) => {
      const regionIndex = state.regionsToTest.findIndex(
        (region) => region.name === action.payload,
      );
      if (regionIndex !== -1) {
        state.regionsToTest.splice(regionIndex, 1);
        // update the test progress
        state.numberOfRegionsTested += 1;
      }
    },
  },
});

export const {
  openConfigurationDialog,
  resetTestRegions,
  setRegionsToTest,
  closeConfigurationDialog,
  addLatency,
  completeLatencyTest,
  removeRegionToTest,
  abortLatencyTest,
} = cloudRenderingSlice.actions;

// Middleware to start latency checks and save the latency info to local storage
const listenerMiddleware = createListenerMiddleware<{
  cloudRendering: CloudRenderingState;
}>();

listenerMiddleware.startListening({
  matcher: isAnyOf(setRegionsToTest, abortLatencyTest),
  effect: async (_action, listenerApi) => {
    // cancel any previously running tasks
    listenerApi.cancelActiveListeners();

    // start the latency test
    const cloudRenderingState = listenerApi.getState().cloudRendering;
    const regionsToTest = cloudRenderingState.regionsToTest;

    if (regionsToTest.length === 0) {
      return;
    }

    // the latency lookups need to be made sequentially to avoid network clogging
    for (const region of regionsToTest) {
      if (listenerApi.signal.aborted) {
        // abort if requested
        return;
      }

      try {
        // wait a bit to avoid network cloging
        await delay(200, listenerApi.signal);

        const latency = await latencyLookup(region);

        listenerApi.dispatch(
          addLatency({
            latencyMs: latency,
            region: region.name,
          }),
        );

        // ignore the result if the document is not visible right now and schedule to continue the test later on
        if (document.visibilityState !== "visible") {
          // pause the test until the tab is visible again
          const waitForVisbility = new Promise<void>((resolve, reject) => {
            listenerApi.signal.addEventListener("abort", reject);
            document.addEventListener(
              "visibilitychange",
              () => {
                if (document.visibilityState === "visible") {
                  resolve();
                }
              },
              { once: true },
            );
          });
          await listenerApi.pause(waitForVisbility);
          // delay execution a bit to prevent interference with other requests
          await delay(500, listenerApi.signal);
        }
      } catch (err) {
        // could not determine the latency to that region, remove it from the regions to test (see finally block)
      }
      if (listenerApi.signal.aborted) {
        // abort if requested
        return;
      }
      // mark the region as tested
      listenerApi.dispatch(removeRegionToTest(region.name));
    }

    listenerApi.dispatch(completeLatencyTest());
  },
});

listenerMiddleware.startListening({
  actionCreator: completeLatencyTest,
  effect: async (_action, listenerApi) => {
    // persist latency information to localstorage
    const state = listenerApi.getState() as RootState;
    persistLatencyInformation(state.cloudRendering.regionsLatency);
  },
});

listenerMiddleware.startListening({
  actionCreator: resetTestRegions,
  effect: async (_action) => {
    // clear the stored latency information from localstorage
    persistLatencyInformation({});
  },
});

export const latencyCheckMiddleware = listenerMiddleware.middleware;

export const selectCloudRendering = (state: RootState) => state.cloudRendering;
export const selectRegionsLatency = createSelector(
  selectCloudRendering,
  (cloudRendering) => cloudRendering.regionsLatency,
);
export const selectLatencyTestProgress = createSelector(
  selectCloudRendering,
  (cloudRendering) =>
    cloudRendering.numberOfRegionsToTest === 0
      ? 0
      : cloudRendering.numberOfRegionsTested /
        cloudRendering.numberOfRegionsToTest,
);
export const selectRegionsToTest = createSelector(
  selectCloudRendering,
  (cloudRendering) => cloudRendering.regionsToTest,
);

export const selectLatencyTestCompleted = createSelector(
  selectCloudRendering,
  (cloudRendering) => cloudRendering.latencyTestCompleted,
);

export default cloudRenderingSlice.reducer;
