import { useEffect, useState } from "react";

export function useSecondsTicker({
  startSeconds,
  direction,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  enabled = true,
}: {
  startSeconds: number;
  direction: -1 | 1;
  min: number;
  max: number;
  enabled?: boolean;
}) {
  const [seconds, setSeconds] = useState(startSeconds);

  useEffect(() => {
    if (!enabled) {
      setSeconds(startSeconds);
      return;
    }

    setSeconds(startSeconds);
    const interval = setInterval(() => {
      setSeconds((seconds) =>
        Math.min(Math.max(seconds + direction, min), max),
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [direction, max, min, enabled, startSeconds]);

  return seconds;
}
