import { v4 as uuidv4 } from "uuid";

const key = "portal-unique-id";

/**
 * Obtain a unique id for the current browser window / tab.
 * inspired by https://github.com/dfbaskin/browser-tab-unique-id/blob/master/src/unique-id.js
 */
export function getUniqueIdForApplication() {
  let value = window.sessionStorage.getItem(key);

  if (!value || !window.name) {
    value = uuidv4();
    window.sessionStorage.setItem(key, value);
  }

  window.name = value;
  return value;
}
