import { Heading, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { MdSearchOff as NotFoundIcon } from "react-icons/md";
import { LinkButton } from "../components";

export function NoMatchPage() {
  return (
    <HStack>
      <Icon flexBasis={40} height="full" as={NotFoundIcon} />
      <Stack spacing={6}>
        <Heading>Not Found!</Heading>
        <Text>
          The page you were looking for does not appear to exist. Please check
          the url or use the button below to start over.
        </Text>
        <LinkButton colorScheme="brand" to="/">
          Back to Home
        </LinkButton>
      </Stack>
    </HStack>
  );
}
