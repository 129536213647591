import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export function ConfirmSessionAbortDialog({
  isOpen,
  onClose,
  onConfirm,
  ...props
}: { onConfirm(): void } & Omit<
  AlertDialogProps,
  "leastDestructiveRef" | "children"
>) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      {...props}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("session.abort_dialog.title")}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t("session.abort_dialog.are_you_sure")}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t("session.cancel")}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onClose();
                onConfirm();
              }}
              ml={3}
            >
              {t("session.confirm")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
