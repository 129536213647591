import { Flex, FlexProps } from "@chakra-ui/layout";
import {
  Button,
  Hide,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaFilter as FilterIcon } from "react-icons/fa";
import { DEVICE_FILTER, RENDER_FILTER, useSearchContext } from "../search";
import { SearchButton } from "./SearchButton";

export const FilterBar = (props: FlexProps) => {
  const { t } = useTranslation();
  const { deviceFilter, renderFilter, setDeviceFilter, setRenderFilter } =
    useSearchContext();

  const renderLabel = () => {
    return t("search.filter.rendering.label", {
      type: t("search.filter.rendering." + renderFilter),
    });
  };

  const deviceLabel = () => {
    return t("search.filter.device.label", {
      type: t("search.filter.device." + deviceFilter),
    });
  };

  const isNotFilteredForBrowser = () => {
    return deviceFilter === "browser";
  };

  const isNoDeviceFilterActive = () => {
    return deviceFilter === "all";
  };

  return (
    <Flex
      flexDirection={"row"}
      alignItems={{ base: "baseline", sm: "center" }}
      {...props}
    >
      <Hide above="md">
        <SearchButton />
      </Hide>
      <Spacer />
      <Flex
        direction={{ base: "column", md: "row-reverse" }}
        alignItems={"end"}
        gap={[2, 1]}
      >
        <Menu>
          <MenuButton
            rightIcon={<Icon as={FilterIcon} boxSize={3} />}
            as={Button}
            variant={"ghost"}
            size={{ base: "md", md: "sm" }}
            marginLeft={{ sm: 1 }}
            flexShrink={0}
          >
            {t("search.filter.label")}
          </MenuButton>
          <MenuList>
            <MenuOptionGroup
              value={deviceFilter}
              title={t("search.filter.device.title")}
              onChange={(device) => {
                // with a radio button, the on change handler will always return one device only
                setDeviceFilter(device as string);
              }}
              type="radio"
            >
              {Object.values(DEVICE_FILTER).map((value) => (
                <MenuItemOption key={value} value={value}>
                  {t(`search.filter.device.${value}`)}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
            <MenuOptionGroup
              value={renderFilter}
              title={t("search.filter.rendering.title")}
              onChange={(renderType) => {
                // with a radio button, the on change handler will always return one device only
                setRenderFilter(renderType as string);
              }}
              type="radio"
            >
              <MenuItemOption value={RENDER_FILTER.ALL}>
                {t("search.filter.rendering.all")}
              </MenuItemOption>
              <MenuItemOption
                value={RENDER_FILTER.LOCAL}
                isDisabled={
                  isNotFilteredForBrowser() || isNoDeviceFilterActive()
                }
              >
                {t("search.filter.rendering.local")}
              </MenuItemOption>
              <MenuItemOption value={RENDER_FILTER.STREAMING}>
                {t("search.filter.rendering.streaming")}
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        {deviceFilter !== "all" && (
          <Tag size="lg" variant="outline" colorScheme={"gray"}>
            <TagLabel>{deviceLabel()}</TagLabel>
            <TagCloseButton
              onClick={() => {
                setDeviceFilter("all");
              }}
            />
          </Tag>
        )}
        {renderFilter !== "all" && (
          <Tag size="lg" variant="outline" colorScheme={"gray"}>
            <TagLabel>{renderLabel()}</TagLabel>
            <TagCloseButton
              onClick={() => {
                setRenderFilter("all");
              }}
            />
          </Tag>
        )}
      </Flex>
    </Flex>
  );
};
