export const SESSION_STATE = {
  UNKNOWN: -1,
  REQUESTED: 0,
  LOADING: 1,
  READY: 2, // session is ready and waiting for consumer to connect
  ACTIVE: 3, // session is actively consumed (app running / streaming client connected)
  ENDING: 4,
  ENDED: 5,
  ERRORED: 6,
} as const;
