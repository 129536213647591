import { Stack } from "@chakra-ui/layout";
import { Alert } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function InstallationInstructionsPicoEnterpriseStore() {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Alert status="info">
        {t("clients.installation_methods.pico_enterprise_store.text")}
      </Alert>
    </Stack>
  );
}
