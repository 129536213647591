import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CircularProgress,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import log from "loglevel";
import queryString from "query-string";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  selectHasNonEndedSession,
  selectSessionOverviewReceived,
} from "../features/sessionSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { KnownIssueCodes } from "../session/types";
import { sendIssueReport } from "../signalR";

export function RemoteRenderingBackchannel() {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const isSessionOverviewReceived = useAppSelector(
    selectSessionOverviewReceived,
  );
  const isActiveSession = useAppSelector(selectHasNonEndedSession);
  const { redirect_uri, ...params } = queryString.parse(search);
  const isValidRedirectUri = !(
    !redirect_uri || typeof redirect_uri !== "string"
  );
  const url = useMemo(
    () =>
      isValidRedirectUri
        ? new URL(redirect_uri + "?" + queryString.stringify(params))
        : undefined,
    [isValidRedirectUri, params, redirect_uri],
  );

  useEffect(() => {
    if (!isActiveSession || !isSessionOverviewReceived || !url) return;

    sendIssueReport({
      id: window.crypto.randomUUID(),
      message: "Callback URL needs to be opened on remote machine",
      issueCode: KnownIssueCodes.OpenCallbackUrl,
      params: {
        Url: url.toString(),
      },
    });
    log.info("Opening remote URL", url.toString());
  }, [isSessionOverviewReceived, dispatch, url, isActiveSession]);

  if (!isValidRedirectUri) {
    log.warn("No redirect_uri found in query params", params);
    return (
      <Alert status="error">
        <AlertIcon />
        No (valid) redirect_uri found in query params
      </Alert>
    );
  }

  return !isSessionOverviewReceived || !isActiveSession ? (
    <CircularProgress isIndeterminate />
  ) : (
    <Alert
      status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Redirect successful
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Text>
          Successfully opened{" "}
          <Tooltip label={url?.toString() ?? ""}>
            <Text
              as="span"
              display={"inline"}
              textDecoration={"underline"}
              textDecorationStyle={"dotted"}
            >
              callback URL
            </Text>
          </Tooltip>{" "}
          on remote machine.
        </Text>
        <Text marginTop={4}>You may close this tab.</Text>
      </AlertDescription>
    </Alert>
  );
}
