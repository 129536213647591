import {
  Button,
  ButtonProps,
  Center,
  Divider,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  LinkBox,
  LinkBoxProps,
  LinkOverlay,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsSearch as SearchIcon } from "react-icons/bs";
import { FaRegFrown as NoResultsIcon } from "react-icons/fa";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useSearchApplicationQuery } from "../hooks";
import { ApplicationId } from "../hooks/types";
import { routes } from "../utils/routes";
import { ApplicationImage } from "./ApplicationImage";
import { BrandedBadge } from "./BrandedBadge";

interface AppSearchResultProps extends Omit<LinkBoxProps, "id"> {
  image: string;
  name: string;
  id: ApplicationId;
  tags: string[];
  onClick(): void;
}

const AppSearchResult = ({
  image,
  name,
  id,
  tags = [],
  onClick,
  ...props
}: AppSearchResultProps) => {
  return (
    <LinkBox onClick={onClick} {...props}>
      <HStack
        paddingLeft={3}
        paddingRight={5}
        paddingY={3}
        spacing={3}
        borderRadius={5}
        border={"1px solid"}
        borderColor="chakra-border-color"
        _hover={{
          backgroundColor: "chakra-subtle-bg",
        }}
      >
        <ApplicationImage
          src={image}
          borderRadius={5}
          fit="cover"
          height={14}
          width={20}
        />
        <Stack minWidth={0} overflow="hidden">
          <LinkOverlay
            as={RouterLink}
            to={generatePath(routes.application.details, { id })}
          >
            <Skeleton isLoaded={!!name}>
              <Text>{name || "Fallback Application Title"}</Text>
            </Skeleton>
          </LinkOverlay>
          {tags.length && (
            <HStack spacing={2}>
              {tags?.map((tag) => (
                <BrandedBadge fontSize="xs" key={tag}>
                  {tag}
                </BrandedBadge>
              ))}
            </HStack>
          )}
        </Stack>
      </HStack>
    </LinkBox>
  );
};

export const SearchButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // ensure a minimum search term length of three chars
  const { data, isLoading } = useSearchApplicationQuery(
    debouncedSearchText?.length >= 3 ? debouncedSearchText : undefined,
  );

  return (
    <>
      <Button
        onClick={onOpen}
        variant="ghost"
        leftIcon={<SearchIcon />}
        {...props}
      >
        {t("header.search")}
      </Button>
      <Modal
        blockScrollOnMount={false}
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay bgColor="blackAlpha.700" />
        <ModalContent bgColor="backgroundAlpha.900">
          <ModalHeader paddingX={4} paddingY={2}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" height="100%">
                <Icon as={SearchIcon} />
              </InputLeftElement>
              <Input
                placeholder={t("search.placeholder")}
                size="lg"
                border={0}
                _focus={undefined}
                value={searchText}
                _placeholder={{ color: "gray.400" }}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </InputGroup>
          </ModalHeader>
          {searchText && (
            <>
              <Divider width="auto" marginX={4} />
              <ModalBody padding={4}>
                <Stack spacing={2}>
                  {searchText?.length < 3 && (
                    <Text paddingLeft={3}>{t("search.minimum_chars")}</Text>
                  )}
                  {isLoading ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : data?.count === 0 ? (
                    <VStack spacing={4} paddingX={3}>
                      <Icon as={NoResultsIcon} boxSize={8} />
                      <Text>{t("search.no_app", { term: searchText })}</Text>
                    </VStack>
                  ) : (
                    data?.results.map(({ images, ...app }) => (
                      <AppSearchResult
                        key={app.id}
                        id={app.id}
                        name={app.name}
                        tags={app.tags}
                        image={images?.[0]?.image}
                        onClick={() => {
                          // close the popup
                          onClose();
                          // clear search results
                          setSearchText("");
                        }}
                      />
                    ))
                  )}
                </Stack>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
