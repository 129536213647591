import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { useAuthentication } from "../auth";
import {
  selectCloudRendering,
  selectRegionsLatency,
} from "../features/cloudRenderingSlice";
import { getOrderedCloudRenderingRegions } from "../session-management-api";
import { useAppSelector } from "./redux";
import { CloudRenderingRegion, RegionFilter } from "./types";
import { useRegionsWithoutCapacity } from "./useRegionsWithoutCapacity";
import { useUserPreferences } from "./useUserPreferences";

export interface Region extends CloudRenderingRegion {
  latencyMs?: number;
}

export function getCloudRenderingRegionsQueryKey(filters: RegionFilter = {}) {
  return [
    "cloud-rendering-regions",
    ...Object.values(filters).filter((x) => x !== undefined),
  ];
}

export function useCloudRenderingRegionsQuery<T = CloudRenderingRegion[]>(
  props: RegionFilter = {},
  queryOptions?: Partial<
    UseQueryOptions<CloudRenderingRegion[], AxiosError, T>
  >,
) {
  const { user } = useAuthentication();
  const [regionsWithoutCapacity] = useRegionsWithoutCapacity();

  const queryResult = useQuery<CloudRenderingRegion[], AxiosError, T>({
    queryKey: getCloudRenderingRegionsQueryKey(props),
    queryFn: async () => {
      const regions = await getOrderedCloudRenderingRegions({ ...props });
      return regions.map((region) => ({
        ...region,
        isEnabled:
          region.isEnabled &&
          // ensure regions without capacity are disabled
          regionsWithoutCapacity.includes(region.name) === false,
      }));
    },
    ...queryOptions,
    enabled: !!user && queryOptions?.enabled,
  });

  return queryResult;
}

export function useBestVmSize(region?: Region) {
  let vmSize = undefined;
  if (region && region?.supportedVmSizes.length > 0) {
    vmSize = region.supportedVmSizes[0];
  }
  return vmSize;
}

export type SelectCloudRenderingRegion = {
  region: Region | undefined;
  vmSize: string | undefined;
  isSuccess: boolean;
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
  error?: Error;
};

export function useAllowedWavelengthZoneNames() {
  const regionsLatency = useAppSelector(selectRegionsLatency);

  return Object.keys(regionsLatency).filter((c) => c.includes("-wlz-"));
}

export function useBestCloudRenderingRegionQuery(
  filters: RegionFilter = {},
  limitWlzRegions = true,
): SelectCloudRenderingRegion {
  const cloudRendering = useAppSelector(selectCloudRendering);
  const userPreference = useUserPreferences();
  const allowedWavelengthZones = useAllowedWavelengthZoneNames();
  const {
    data: selectedRegion,
    isLoading,
    isSuccess,
    isFetched,
    error,
    isError,
  } = useCloudRenderingRegionsQuery(
    {
      latencyInfo: cloudRendering.regionsLatency,
      preferredRegion: userPreference.data?.preferredRenderRegion,
      allowWavelengthZones: true,
      allowedWavelengthZones: limitWlzRegions
        ? allowedWavelengthZones
        : // undefined means, all wlz are allowed
          undefined,
      ...filters,
    },
    {
      enabled: userPreference.isSuccess,
      placeholderData: keepPreviousData,
      // only consider enabled regions and only return the first one
      select: (regions) => regions.filter((r) => r.isEnabled).at(0),
    },
  );

  const vmSize = useBestVmSize(selectedRegion);

  const status = useMemo(() => {
    return {
      isLoading: userPreference.isLoading || isLoading,
      isFetched: userPreference.isFetched && isFetched,
      isSuccess:
        userPreference.isSuccess && isSuccess && !!vmSize && !!selectedRegion,
      isError:
        userPreference.isError ||
        isError ||
        (!vmSize && !!selectedRegion) ||
        (!selectedRegion && isSuccess),
      error: error ?? undefined,
    };
  }, [
    error,
    isError,
    isFetched,
    isLoading,
    isSuccess,
    selectedRegion,
    userPreference.isError,
    userPreference.isFetched,
    userPreference.isLoading,
    userPreference.isSuccess,
    vmSize,
  ]);

  return useMemo(
    () => ({
      region: selectedRegion,
      vmSize,
      ...status,
    }),
    [selectedRegion, status, vmSize],
  );
}

export function useCloudRenderingRegion(name?: string) {
  return useCloudRenderingRegionsQuery(
    {},
    {
      select: (regions) => regions?.find((x) => x.name === name),
      placeholderData: keepPreviousData,
    },
  );
}
