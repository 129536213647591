/**
 * Mouse Button identifiers as provided by Browser's MouseEvents
 * see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button#value
 */
export enum MouseButton {
  Left = 0,
  Middle = 1,
  Right = 2,
  Back = 3,
  Forward = 4,
}

export enum ButtonAction {
  Up = "up",
  Down = "down",
}

export type Coordinates2D = { x: number; y: number };
