import { useQuery } from "@tanstack/react-query";
import { useAuthentication } from "../auth";
import type { UserPreferences } from "../hooks/types";
import { getUserPreferences } from "../session-management-api";

export const userPreferencesQueryKey = ["user-preferences"];

export function useUserPreferences() {
  const { user } = useAuthentication();

  return useQuery<UserPreferences>({
    queryKey: userPreferencesQueryKey,
    queryFn: async () => getUserPreferences(),
    retry: false,
    enabled: !!user,
  });
}
