import {
  Badge,
  Button,
  ButtonProps,
  HStack,
  Icon,
  IconButton,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  FaCircle as CircleIcon,
  FaExclamationCircle as WarningIcon,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  selectActiveDevice,
  selectOnlineDevices,
  setActiveDevice,
} from "../features/devicesSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useClientApplicationVersionInformation } from "../hooks/useClientApplicationVersionInformation";
import { ClientDevice } from "../session/types";
import { AppBarButtonPopoverContent } from "./AppBarButtonPopover";

export const ConnectedDevicesButton = (props: ButtonProps) => {
  const connectedClientDevices = useAppSelector(selectOnlineDevices);
  const { t } = useTranslation();

  return (
    <Popover trigger="hover" placement="bottom-end">
      <PopoverTrigger>
        <Button
          leftIcon={<Icon as={CircleIcon} color="green" fontSize="14" />}
          borderColor={"chakra-body-text"}
          variant="outline"
          {...props}
        >
          {t("clients.connected")}
        </Button>
      </PopoverTrigger>
      <AppBarButtonPopoverContent width="2xs">
        <PopoverHeader>{t("clients.connected_clients")}</PopoverHeader>
        <PopoverArrow />
        <PopoverBody>
          <List spacing={3}>
            {connectedClientDevices.map((device, idx) => (
              <ListItem key={device.identifier || idx}>
                <SelectableClientDevice deviceData={device} />
              </ListItem>
            ))}
          </List>
        </PopoverBody>
        <PopoverFooter>
          <Button
            as={Link}
            size="sm"
            variant="solid"
            width="full"
            to="/connect-hmd"
          >
            <Text>{t("details.pair_vr_device")}</Text>
          </Button>
        </PopoverFooter>
      </AppBarButtonPopoverContent>
    </Popover>
  );
};

function SelectableClientDevice({ deviceData }: { deviceData: ClientDevice }) {
  const activeClientDevice = useAppSelector(selectActiveDevice);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isOutdated, targetVersion } =
    useClientApplicationVersionInformation(deviceData);

  return (
    <HStack
      fontWeight={
        deviceData.identifier === activeClientDevice?.identifier
          ? "bold"
          : undefined
      }
      justifyContent="space-between"
    >
      <Text>{deviceData.name}</Text>
      <HStack>
        {deviceData.identifier === activeClientDevice?.identifier ? (
          <Badge ml="1" colorScheme="green">
            {t("clients.active")}
          </Badge>
        ) : (
          <Button
            size="xs"
            variant="outline"
            borderColor={"chakra-body-text"}
            onClick={() => dispatch(setActiveDevice(deviceData.identifier))}
          >
            {t("clients.set_active")}
          </Button>
        )}
        {isOutdated && (
          <Tooltip
            hasArrow
            label={`Client is outdated (${deviceData.version}). Please update to ${targetVersion} soon.`}
          >
            <IconButton
              aria-label="Outdated version warning"
              icon={<Icon as={WarningIcon} />}
              color="yellow.200"
              variant="link"
              size="xs"
            />
          </Tooltip>
        )}
      </HStack>
    </HStack>
  );
}
