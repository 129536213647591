import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Stack,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

export function ApplicationQueryError({ error }: { error: AxiosError }) {
  const { t } = useTranslation();

  return (
    <Alert status="error">
      <AlertIcon alignSelf={"baseline"} />
      <Stack>
        <AlertTitle>
          {error.response?.status === 404
            ? t("launch.app_not_found_title")
            : t("session.unexpected_error")}
        </AlertTitle>
        {error.response?.status === 404 ? (
          <AlertDescription>{t("launch.app_not_found")}</AlertDescription>
        ) : (
          error.message
        )}
      </Stack>
    </Alert>
  );
}
