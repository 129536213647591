import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  ListItem,
  SkeletonText,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { default as Markdown } from "react-markdown";
import { Navigate, generatePath, useParams } from "react-router-dom";
import { partition } from "remeda";
import {
  AppActions,
  ApplicationGroup,
  ApplicationImageCarousel,
  ApplicationQueryError,
  BrandedBadge,
  BrandedSkeleton,
} from "../components";
import { XRPlatformsDisplay } from "../components/XRPlatformDisplay";
import { useActiveOrganizationQuery, usePortalName } from "../hooks";
import {
  Application,
  ApplicationBuild,
  ApplicationBuildId,
  ApplicationCategory,
  ApplicationId,
  ApplicationLaunchConfiguration,
  LaunchableApplicationLaunchConfiguration,
} from "../hooks/types";
import {
  useApplicationBuildQuery,
  useInfiniteApplicationBuildsQuery,
} from "../hooks/useApplicationBuildsQuery";
import { useApplicationCategoryQuery } from "../hooks/useApplicationCategoriesQuery";
import {
  useApplicationQuery,
  useApplicationsQuery,
} from "../hooks/useApplicationsQuery";

dayjs.extend(LocalizedFormat);

function RelatedApps({
  applicationCategoryId,
  applicationId,
}: {
  applicationCategoryId: ApplicationCategory["id"];
  applicationId: ApplicationId;
}) {
  const { data: organization } = useActiveOrganizationQuery();
  const { t } = useTranslation();

  const { data: groupData } = useApplicationCategoryQuery(
    applicationCategoryId,
    organization,
  );
  const { data: appPageData } = useApplicationsQuery();

  const relatedApplicationIds = useMemo(() => {
    if (!groupData) return [];

    if (groupData.applications?.length > 0) {
      return groupData?.applications;
    }

    if (!appPageData) return [];

    const applicationIds = appPageData.results.map((app) => app.id);
    if (applicationIds?.length <= 3) {
      return [];
    }
    if (applicationIds?.length < 6) {
      return applicationIds?.slice(0, 3);
    }

    const randomIndex =
      Math.floor(Math.random() * (applicationIds?.length - 2)) + 0;

    return applicationIds?.slice(randomIndex, randomIndex + 3);
  }, [appPageData, groupData]);

  return (
    <>
      {relatedApplicationIds?.length > 0 && (
        <ApplicationGroup
          appIds={
            relatedApplicationIds.filter(
              (_applicationId) => _applicationId !== applicationId,
            ) || []
          }
          title={t("details.related_apps")}
        />
      )}
    </>
  );
}

type DetailPageParams = {
  id?: string;
  groupId?: string;
};

export function DetailPage() {
  const productName = usePortalName();
  const { id: applicationId, groupId } = useParams<DetailPageParams>();
  const isApplicationBuildId = !isNaN(Number(applicationId));

  // if application Id is a number, it's actually an application build's id, not an application id which would be a UUID
  // in this case, we need to find the underlying application id
  const applicationBuildId = Number(applicationId);
  const applicationBuildQuery = useApplicationBuildQuery(applicationBuildId, {
    throwOnError: true,
    enabled: isApplicationBuildId,
  });

  const applicationGroupId = Number(groupId) || 0;
  const { data: organization } = useActiveOrganizationQuery();
  const { data: groupData, isSuccess: groupDataLoaded } =
    useApplicationCategoryQuery(applicationGroupId, organization);

  const {
    isError,
    error,
    data: app,
    isPlaceholderData,
    isSuccess,
  } = useApplicationQuery(applicationId!, {
    enabled: !isApplicationBuildId,
    placeholderData: {
      id: "00000000-0000-0000-0000-000000000000",
      legacy_identity: "0",
      name: "Loading",
      tags: ["test1", "test2", "test3"],
      description: "Some placeholder text",
      launch_configurations: [],
      images: [],
      permissions: {},
      panoramic_preview_image: null,
      modified_date: new Date().toISOString(),
      oauth2_client_id: null,
      description_html: "",
      organizations: [],
    },
  });
  const isLoaded = isSuccess && !isPlaceholderData;

  if (!applicationId) {
    return <Navigate to="/" />;
  }

  // redirect to the application page if a build id was provided
  if (isApplicationBuildId && applicationBuildQuery.isSuccess) {
    return (
      <Navigate
        to={generatePath(groupId ? "/groups/:groupId/apps/:id" : "/apps/:id", {
          id: applicationBuildQuery.data.application,
          groupId: groupId ?? null,
        })}
      />
    );
  }

  if (isError) {
    return (
      <Box
        flexGrow={1}
        alignItems={"center"}
        alignSelf={"center"}
        display={"flex"}
        maxW={"container.sm"}
        padding={4}
      >
        <ApplicationQueryError error={error} />
      </Box>
    );
  }

  return (
    <Box>
      <Helmet>
        <title>
          {app?.name ?? ""}
          {app?.name ? " - " : ""}
          {productName}
        </title>
      </Helmet>
      <Stack
        position="relative"
        padding={[4, 6]}
        flexDirection={{ base: "column-reverse", md: "row" }}
      >
        <Stack
          position="relative"
          zIndex={2}
          px={4}
          paddingLeft="0"
          flexBasis="40%"
          alignSelf="flex-start"
          paddingTop="20px"
          justifyContent="center"
          spacing={8}
        >
          <Stack>
            {applicationGroupId > 0 && (
              <BrandedSkeleton isLoaded={groupDataLoaded}>
                <Heading as="h3" size="sm" fontWeight="bold">
                  {groupData?.name}
                </Heading>
              </BrandedSkeleton>
            )}
            <BrandedSkeleton isLoaded={isLoaded}>
              <Heading as="h2" size="xl">
                {app?.name}
              </Heading>
            </BrandedSkeleton>
            <Stack direction="row">
              {app?.tags.map((tag, idx) => (
                <BrandedSkeleton isLoaded={isLoaded} key={idx}>
                  <BrandedBadge variant="subtle" colorScheme="gray">
                    {tag}
                  </BrandedBadge>
                </BrandedSkeleton>
              ))}
            </Stack>
          </Stack>
          <AppActions applicationId={applicationId} />
          {!!app?.description && (
            <Box>
              <BrandedSkeleton isLoaded={isLoaded}>
                <Text as={Box}>
                  <Markdown components={markdownRenderer}>
                    {app?.description}
                  </Markdown>
                </Text>
              </BrandedSkeleton>
            </Box>
          )}
          {isSuccess && (
            <>
              <Divider />
              <ApplicationChangelogs app={app} />
            </>
          )}
        </Stack>

        <Box overflow="hidden" flexBasis="60%">
          {app && (
            <ApplicationImageCarousel
              applicationData={app}
              maxHeight={{ base: "xs", md: "md" }}
              minHeight={{ base: "xs", md: "sm" }}
            />
          )}
        </Box>
      </Stack>
      <Box marginTop={[10]}>
        <RelatedApps
          applicationCategoryId={applicationGroupId}
          applicationId={applicationId}
        />
      </Box>
    </Box>
  );
}
const markdownTheme: Parameters<typeof ChakraUIRenderer>[0] = {
  ul: (props) => <UnorderedList spacing={2} {...props} />,
  li: (props) => <ListItem {...props} />,
};
const markdownRenderer = ChakraUIRenderer(markdownTheme);

function ApplicationChangelogs({ app }: { app: Application }) {
  // for each launch configuration supported by this application, load the respective build(s) to get the changelog
  const launchConfigurations =
    app.launch_configurations.filter<LaunchableApplicationLaunchConfiguration>(
      (
        launchConfiguration,
      ): launchConfiguration is LaunchableApplicationLaunchConfiguration =>
        !!launchConfiguration.application_build,
    );
  const launchConfigurationsByBuild = useMemo(() => {
    // merge windows launch configurations if they use the same build
    const [windowsLaunchConfigurations, otherLaunchConfigurations] = partition(
      launchConfigurations,
      ({ xr_platform: type }) => type.startsWith("win"),
    );

    const result: [
      ApplicationLaunchConfiguration["xr_platform"][],
      ApplicationBuildId,
    ][] = [];
    // merge the windows xr platforms if they share the same build
    if (
      windowsLaunchConfigurations.length > 1 &&
      windowsLaunchConfigurations
        .map(({ application_build }) => application_build)
        .every(
          (buildId) =>
            buildId === windowsLaunchConfigurations[0].application_build,
        )
    ) {
      result.push([
        windowsLaunchConfigurations.map(({ xr_platform: type }) => type),
        windowsLaunchConfigurations[0].application_build,
      ]);
    } else {
      windowsLaunchConfigurations
        .sort((a, b) => a.xr_platform.localeCompare(b.xr_platform))
        .forEach(({ xr_platform: type, application_build }) => {
          result.push([[type], application_build]);
        });
    }

    // re-add the other launch configurations
    otherLaunchConfigurations
      .sort((a, b) => a.xr_platform.localeCompare(b.xr_platform))
      .forEach(({ xr_platform: type, application_build }) => {
        result.push([[type], application_build]);
      });

    return result;
  }, [launchConfigurations]);

  // if there's only one launch configuration, just show it's changelog
  if (launchConfigurationsByBuild.length === 1) {
    return (
      <LazyApplicationBuildChangelog
        buildId={launchConfigurationsByBuild[0][1]}
      />
    );
  }

  return (
    <Tabs isLazy>
      <TabList>
        {launchConfigurationsByBuild.map(([xrPlatforms]) => (
          <Tab key={xrPlatforms.join("_")}>
            <XRPlatformsDisplay xrPlatforms={xrPlatforms} />
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {launchConfigurationsByBuild.map(([xrPlatforms, buildId]) => (
          <TabPanel key={xrPlatforms.join("_")}>
            <LazyApplicationBuildChangelog buildId={buildId} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}

function LazyApplicationBuildChangelog({
  buildId,
}: {
  buildId: ApplicationBuildId;
}) {
  const { t } = useTranslation();
  const [showOlderChangelogs, setShowOlderChangelogs] = useState(false);
  const applicationBuildQuery = useApplicationBuildQuery(buildId, {
    retry: false,
    throwOnError: true,
  });
  // fetch changelogs of older versions as well
  const infiniteApplicationBuildsQuery = useInfiniteApplicationBuildsQuery({
    application: applicationBuildQuery.data?.application,
    supported_xr_platform: applicationBuildQuery.data?.supported_xr_platforms,
    ordering: "-version",
  });

  if (applicationBuildQuery.isLoading) {
    return (
      <Stack spacing={4}>
        <BrandedSkeleton>
          <Heading size="xs">Changelog loading</Heading>
        </BrandedSkeleton>
        <BrandedSkeleton>
          <SkeletonText noOfLines={3} spacing={2} />
        </BrandedSkeleton>
      </Stack>
    );
  }

  if (applicationBuildQuery.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>Error!</AlertTitle>
        <AlertDescription>
          {(applicationBuildQuery.error as Error).message}
        </AlertDescription>
      </Alert>
    );
  }

  if (applicationBuildQuery.isSuccess) {
    return (
      <Stack spacing={4}>
        <Heading size="sm">{t("details.changelog")}</Heading>
        <ApplicationBuildChangelog build={applicationBuildQuery.data} />
        {showOlderChangelogs &&
          infiniteApplicationBuildsQuery.data?.pages.map((page) =>
            page.results
              .filter((build) => build.id !== applicationBuildQuery.data.id)
              .map((build) => (
                <ApplicationBuildChangelog key={build.id} build={build} />
              ))
              .flat(),
          )}
        {(((infiniteApplicationBuildsQuery.data?.pages[0].count ?? 0) > 1 &&
          !showOlderChangelogs) ||
          infiniteApplicationBuildsQuery.hasNextPage) && (
          <Button
            variant={"ghost"}
            size="xs"
            onClick={() => {
              if (!showOlderChangelogs) setShowOlderChangelogs(true);
              else infiniteApplicationBuildsQuery.fetchNextPage();
            }}
            alignSelf={"start"}
          >
            {t("details.changelog_show_more")}
          </Button>
        )}
      </Stack>
    );
  }

  return null;
}

function ApplicationBuildChangelog({ build }: { build: ApplicationBuild }) {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={4}
      color={!build.changelog ? "chakra-subtle-text" : undefined}
    >
      <Heading size="xs" as="h3">
        <HStack>
          <Text>{build.version}</Text>
          <Spacer />
          <Tooltip label={dayjs(build.created_date).format("LLLL")}>
            <Text>{dayjs(build.created_date).fromNow()}</Text>
          </Tooltip>
        </HStack>
      </Heading>
      <Markdown components={markdownRenderer}>
        {build.changelog || t("details.no_changelog_available")}
      </Markdown>
    </Stack>
  );
}
