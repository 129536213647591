import {
  createListenerMiddleware,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { sendSessionActivity } from "../signalR";
import type { RootState } from "../store";

export interface InteractiveSpectatorState {
  isFullscreen: boolean;
  /**
   * Whether the audio output is blocked by the browser policy.
   * See https://www.chromium.org/audio-video/autoplay/
   */
  isAudioOutputBlockedByBrowserPolicy: boolean;
  isAudioMuted: boolean;
  isMicrophoneEnabled: boolean;
  isMicrophoneMuted: boolean;
  connectionState?: RTCPeerConnectionState;
  connectionError?: string;
  isConnected: boolean;
  isNotReceivingFrames: boolean;
  pressedKeyCodes: string[];
  downedMouseButtonIds: number[];
}

const initialState: InteractiveSpectatorState = {
  isFullscreen: false,
  isAudioOutputBlockedByBrowserPolicy: false,
  isAudioMuted: false,
  isMicrophoneEnabled: false,
  isMicrophoneMuted: false,
  isConnected: false,
  isNotReceivingFrames: false,
  pressedKeyCodes: [],
  downedMouseButtonIds: [],
};

export const interactiveSpectatorSlice = createSlice({
  name: "interactiveSpectator",
  initialState,
  reducers: {
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      state.isFullscreen = action.payload;
    },
    setIsAudioOutputBlockedByBrowserPolicy: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isAudioOutputBlockedByBrowserPolicy = action.payload;
    },
    enableAudioOutput: (state) => {
      state.isAudioMuted = false;
      state.isAudioOutputBlockedByBrowserPolicy = false;
    },
    disableAudioOutput: (state) => {
      state.isAudioMuted = true;
      state.isAudioOutputBlockedByBrowserPolicy = false;
    },
    toggleAudioOutput: (state) => {
      state.isAudioMuted = !state.isAudioMuted;
      if (state.isAudioMuted === false) {
        state.isAudioOutputBlockedByBrowserPolicy = false;
      }
    },
    toggleMicrophone: (state) => {
      state.isMicrophoneMuted = !state.isMicrophoneMuted;
    },
    enableMicrophone: (state) => {
      state.isMicrophoneEnabled = true;
      state.isMicrophoneMuted = false;
    },
    disableMicrophone: (state) => {
      state.isMicrophoneEnabled = false;
    },
    setConnectionState: (
      state,
      action: PayloadAction<RTCPeerConnectionState>,
    ) => {
      state.connectionState = action.payload;
      state.connectionError =
        action.payload === "failed" ? "Connection failed" : undefined;
      state.isConnected = action.payload === "connected";
    },
    setIsNotReceivingFrames: (state, action: PayloadAction<boolean>) => {
      state.isNotReceivingFrames = action.payload;
    },
    addPressedKeyCode: (state, action: PayloadAction<string>) => {
      state.pressedKeyCodes = [
        ...Array.from(new Set([...state.pressedKeyCodes, action.payload])),
      ];
    },
    removePressedKeyCode: (state, action: PayloadAction<string>) => {
      state.pressedKeyCodes = state.pressedKeyCodes.filter(
        (keyCode) => keyCode !== action.payload,
      );
    },
    resetPressedKeyCodes: (state) => {
      state.pressedKeyCodes = [];
    },
    resetDownedMouseButtons: (state) => {
      state.downedMouseButtonIds = [];
    },
    updateDownedMouseButton: (
      state,
      action: PayloadAction<{ buttonId: number; change: "up" | "down" }>,
    ) => {
      state.downedMouseButtonIds =
        action.payload.change === "down"
          ? // add the pressed button to the list of mouse buttons currently pressed
            [
              ...Array.from(
                new Set([
                  ...state.downedMouseButtonIds,
                  action.payload.buttonId,
                ]),
              ),
            ]
          : // remove the released button from the list of mouse buttons currently pressed
            state.downedMouseButtonIds.filter(
              (buttonId) => buttonId !== action.payload.buttonId,
            );
    },
    mouseMovement: () => {},
  },
});

export const {
  addPressedKeyCode,
  disableAudioOutput,
  disableMicrophone,
  enableAudioOutput,
  enableMicrophone,
  removePressedKeyCode,
  resetDownedMouseButtons,
  resetPressedKeyCodes,
  setConnectionState,
  setFullscreen,
  setIsAudioOutputBlockedByBrowserPolicy,
  setIsNotReceivingFrames,
  toggleAudioOutput,
  toggleMicrophone,
  updateDownedMouseButton,
  mouseMovement,
} = interactiveSpectatorSlice.actions;

export const selectInteractiveSpectator = (state: RootState) =>
  state.interactiveSpectator;

export const selectConnectionState = createSelector(
  selectInteractiveSpectator,
  (state) => ({
    isConnected: state.isConnected,
    connectionState: state.connectionState,
    connectionError: state.connectionError,
  }),
);

export default interactiveSpectatorSlice.reducer;

// Middleware to start latency checks and save the latency info to local storage
const listenerMiddleware = createListenerMiddleware<{
  interactiveSpectator: InteractiveSpectatorState;
}>();

listenerMiddleware.startListening({
  matcher: isAnyOf(
    addPressedKeyCode,
    disableAudioOutput,
    disableMicrophone,
    enableAudioOutput,
    enableMicrophone,
    removePressedKeyCode,
    resetDownedMouseButtons,
    resetPressedKeyCodes,
    setFullscreen,
    toggleAudioOutput,
    toggleMicrophone,
    updateDownedMouseButton,
    mouseMovement,
  ),
  effect: async (_action, listenerApi) => {
    // wait for 5 seconds before sending the session activity
    await listenerApi.delay(5000);
    const state = listenerApi.getState() as RootState;

    if (!state.session.sessionState.id) {
      // no active session
      return;
    }

    if (!state.session.sessionState.appLaunched) {
      // the app is not launched
      return;
    }

    // cancel all other listeners to ensure that just a single session activity is sent
    listenerApi.cancelActiveListeners();
    // send the session activity
    sendSessionActivity(state.session.sessionState.id);
  },
});

export const sessionActivityMiddleware = listenerMiddleware.middleware;
