import { StrictMode as ReactStrictMode } from "react";

export function StrictMode({
  children,
  isDisabled,
}: Parameters<typeof ReactStrictMode>[0] & { isDisabled?: boolean }) {
  if (!isDisabled) {
    return <ReactStrictMode>{children}</ReactStrictMode>;
  }
  return <>{children}</>;
}
