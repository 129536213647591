import log from "loglevel";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDeviceConnectionChanges, useNotificationToast } from "../hooks";

export function HMDOnlineStatusNotifications() {
  const toast = useNotificationToast();
  const { newlyConnectedDevices, disconnectedDevices } =
    useDeviceConnectionChanges();
  const { t } = useTranslation();

  // Notify when a (new) device comes online
  useEffect(() => {
    if (newlyConnectedDevices.length) {
      log.debug("newly connected devices", newlyConnectedDevices);

      newlyConnectedDevices.forEach((device) =>
        toast({
          title: t("device.came_online_title"),
          description: t("device.came_online", { name: device.name }),
          status: "info",
          duration: 3000,
        }),
      );
    }

    if (disconnectedDevices.length) {
      log.debug("disconnected devices", disconnectedDevices);

      disconnectedDevices.forEach((device) =>
        toast({
          title: t("device.went_offline_title"),
          description: t("device.went_offline", { name: device.name }),
          status: "warning",
          duration: 3000,
        }),
      );
    }
  }, [newlyConnectedDevices, disconnectedDevices, t, toast]);

  return null;
}
