import { CircularProgress } from "@chakra-ui/progress";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import log from "loglevel";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { BooleanParam } from "serialize-query-params";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import {
  ApplicationImage,
  ApplicationQueryError,
  DialogContainer,
  LaunchConfigurationSelection,
  SessionAlreadyRunningWarningDialog,
  TargetDeviceSelection,
} from "../components";
import { RequireLatency } from "../components/RequireLatency";
import { selectDevicesListReceived } from "../features/devicesSlice";
import {
  selectSessionOverviewReceived,
  selectSessionState,
} from "../features/sessionSlice";
import {
  useActiveOrganizationQuery,
  useAppSelector,
  useApplicationQuery,
  useEncryptVrStreamSetting,
  useFeatureFlags,
  usePortalName,
  useTargetDevices,
} from "../hooks";
import {
  useLaunchConfigurationCandidates,
  useLaunchConfigurationsForLaunch,
} from "../hooks/useLaunchConfigurations";
import { useVirtualMachineQuery } from "../hooks/useVirtualMachineQuery";
import { SESSION_STATE } from "../session/session-state";
import { DeviceType, SessionType } from "../session/types";
import { terminateSession } from "../signalR";
import { isAnyApplicationBuildInstalled } from "../utils/applications";
import { routes } from "../utils/routes";
import { LaunchPageContext } from "./LaunchPageContext";
import { CloudLaunchBrowser } from "./launch/CloudLaunchBrowser";
import { CloudLaunchStandalone } from "./launch/CloudLaunchStandalone";
import { LocalLaunchStandalone } from "./launch/LocalLaunchStandalone";
import { LocalLaunchWindows } from "./launch/LocalLaunchWindows";
import { RenderMode, TargetDeviceType } from "./launch/types";

export type LaunchPageParams = {
  groupId?: string;
  id: string;
  renderMode: RenderMode;
  targetDeviceType: TargetDeviceType;
};

export function LaunchPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: organization, isLoading: isLoadingOrganization } =
    useActiveOrganizationQuery();
  const isSessionOverviewReceived = useAppSelector(
    selectSessionOverviewReceived,
  );
  const { t } = useTranslation();
  const {
    id: applicationId,
    renderMode,
    targetDeviceType,
  } = useParams<LaunchPageParams>();
  const productName = usePortalName();
  const clientIdentificationReceived = useAppSelector(
    selectDevicesListReceived,
  );
  const {
    data: application,
    isPending,
    isError,
    error,
  } = useApplicationQuery(applicationId);

  // launch configurations that are generally valid for the selected render mode and target device type
  const validLaunchConfigurations = useLaunchConfigurationsForLaunch(
    application,
    renderMode,
    targetDeviceType,
  );

  const currentSession = useAppSelector(selectSessionState);
  const [flags] = useFeatureFlags();
  const encryptVrStreamSetting = useEncryptVrStreamSetting();
  const [
    {
      args: launchArgs,
      forceColdVm,
      encryptVrStream,
      debugModeEnabled,
      virtualMachineId,
      vmImage,
      region: renderRegion,
      vmSize,
      targetDevice: _targetDeviceIdentifier,
    },
  ] = useQueryParams({
    args: withDefault(StringParam, ""),
    forceColdVm: withDefault(BooleanParam, false),
    encryptVrStream: withDefault(BooleanParam, encryptVrStreamSetting ?? false),
    debugModeEnabled: withDefault(
      BooleanParam,
      flags.debugModeEnabled ?? false,
    ),
    virtualMachineId: withDefault(StringParam, flags.virtualMachineId),
    vmImage: withDefault(StringParam, flags.vmImage),
    region: withDefault(StringParam, undefined),
    vmSize: withDefault(StringParam, undefined),
    targetDevice: withDefault(StringParam, undefined),
  });
  const { data: vm, isLoading: isLoadingVirtualMachine } =
    useVirtualMachineQuery(virtualMachineId);
  const [targetDeviceIdentifier, setTargetDeviceIdentifier] = useState<
    string | undefined
  >(_targetDeviceIdentifier);
  const sessionType: SessionType = useMemo(() => {
    if (renderMode === "cloud") {
      if (targetDeviceType === "standalone") return SessionType.CloudRenderedVR;
      if (targetDeviceType === "browser") return SessionType.CloudRenderedNonVR;
    } else if (renderMode === "local") {
      if (targetDeviceType === "standalone")
        return SessionType.LocallyRenderedStandalone;
      if (targetDeviceType === "windows")
        return SessionType.LocallyRenderedWindows;
    }
    return SessionType.Unknown;
  }, [renderMode, targetDeviceType]);
  const [skipToRunningSession, setSkipToRunningSession] = useState(false);
  // TODO: This should also check the standalone app is already installed on the device
  const { candidates: targetDevices } = useTargetDevices(
    [
      SessionType.CloudRenderedVR,
      SessionType.LocallyRenderedStandalone,
    ].includes(sessionType)
      ? DeviceType.Standalone
      : sessionType === SessionType.LocallyRenderedWindows
        ? DeviceType.Desktop
        : DeviceType.Browser,
    targetDeviceIdentifier,
  );
  const targetDevice =
    targetDevices.length === 1 ? targetDevices[0] : undefined;

  const hideAbortSessionDialog = () => {
    setSkipToRunningSession(true);
  };

  const overrideRunningSession = async () => {
    await terminateSession(currentSession.id!);
  };

  // launch configurations that work for both the selected target device and the requested session type
  const launchConfigurationCandidates = useLaunchConfigurationCandidates(
    validLaunchConfigurations,
    sessionType,
    targetDevice,
  );

  let matchingLaunchConfiguration;
  if (launchConfigurationCandidates.length === 1) {
    matchingLaunchConfiguration = launchConfigurationCandidates.at(0);
  }

  if (!applicationId) {
    return <Navigate to={routes.home} />;
  }

  if (isError) {
    return <ApplicationQueryError error={error} />;
  }

  if (
    // if a virtual machine is specified, we need to fetch its details
    (virtualMachineId && isLoadingVirtualMachine) ||
    isLoadingOrganization ||
    isPending ||
    isSessionOverviewReceived === false ||
    clientIdentificationReceived === false
  ) {
    return <CircularProgress isIndeterminate color="brand.500" />;
  }

  if (!validLaunchConfigurations.length) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          {t("launch.no_app_build_for_launch")}
        </AlertDescription>
      </Alert>
    );
  }

  // when a target device is required (launch on standalone), make sure we have one
  if (
    [
      SessionType.CloudRenderedVR,
      SessionType.LocallyRenderedStandalone,
    ].includes(sessionType)
  ) {
    if (targetDevices.length === 0) {
      // if no suitable device, redirect the user to connect one and inform them what kind of device is required
      validLaunchConfigurations.map(
        ({ xr_platform: xrPlatform }) => xrPlatform,
      );
      let error = "";
      // if the app supports multiple devices (Pico, Quest, Wave, etc.) or if we using cloud-rendered VR
      if (sessionType === SessionType.CloudRenderedVR) {
        error = "connect_standalone_device";
      } else if (validLaunchConfigurations.length > 1) {
        error =
          "no_device_" +
          validLaunchConfigurations
            .map(({ xr_platform }) => xr_platform)
            .sort((a, b) => a.localeCompare(b))
            .join("_");
      } else if (validLaunchConfigurations.length === 1) {
        // if the app only supports one device, inform the user about the required device
        const xrPlatform = validLaunchConfigurations[0].xr_platform;
        switch (xrPlatform) {
          case "quest":
            error = "no_device_quest";
            break;
          case "wave":
            error = "no_device_wave";
            break;
          case "pico":
            error = "no_device_pico";
            break;
          default:
            error = "connect_standalone_device";
            break;
        }
      }
      return (
        // prompt the user to connect a compatible device, once the device is connected, come back here
        <Navigate
          to={{
            pathname: routes.connectDevice,
          }}
          state={{ from: location, error }}
        />
      );
    } else if (targetDevices.length > 1) {
      // when there are multiple target devices, prompt the user to select one
      return (
        <VStack spacing={4}>
          <DialogContainer>
            <TargetDeviceSelection
              targetDevices={targetDevices}
              onTargetDeviceSelected={(targetDevice) =>
                setTargetDeviceIdentifier(targetDevice.identifier)
              }
            />
          </DialogContainer>
        </VStack>
      );
    } else {
      if (
        targetDevice &&
        // FIXME: for standalone devices, we don't know the specific build that is installed on the device
        !isAnyApplicationBuildInstalled(
          application?.legacy_identity,
          targetDevice,
        ) &&
        sessionType === SessionType.LocallyRenderedStandalone
      ) {
        return (
          <Alert status="warning">
            <AlertIcon />
            <HStack>
              <Text>{t("launch.application_not_installed_on_device")}</Text>
              <Button size="sm" onClick={navigateBack}>
                Go back
              </Button>
            </HStack>
          </Alert>
        );
      }
    }
  }

  if (
    !matchingLaunchConfiguration &&
    launchConfigurationCandidates.length > 1
  ) {
    return (
      <LaunchConfigurationSelection
        launchConfigurations={launchConfigurationCandidates}
        onLaunchConfigurationSelected={log.info}
      />
    );
  }

  if (
    matchingLaunchConfiguration &&
    currentSession.state >= SESSION_STATE.REQUESTED &&
    currentSession.state < SESSION_STATE.ENDED
  ) {
    // if a session is already running / loading
    // if the active session is for a different app than the requested one, warn the user so he can choose what to do
    if (
      (currentSession.applicationBuildId !==
        matchingLaunchConfiguration.application_build ||
        currentSession.type !== sessionType) &&
      !skipToRunningSession
    ) {
      return (
        <SessionAlreadyRunningWarningDialog
          isOpen={true}
          onClose={hideAbortSessionDialog}
          onConfirm={overrideRunningSession}
        />
      );
    } else {
      return <Navigate to={routes.session} />;
    }
  }

  if (!matchingLaunchConfiguration) {
    return (
      <Alert status="warning">
        <AlertIcon />
        <HStack>
          <Text> {t("launch.no_matching_launch_configuration")}</Text>
          <Button size="sm" onClick={navigateBack}>
            Go back
          </Button>
        </HStack>
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Launching {application?.name} - {productName}
        </title>
      </Helmet>

      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
        alignItems={"stretch"}
      >
        <ApplicationImage
          src={application?.images[0]?.image}
          objectFit="cover"
          maxW={{ base: "100%", sm: "2xs" }}
          alt={application?.name}
          transition={"opacity 0.2s ease-in-out"}
          height="auto"
        />

        <CardBody>
          <Stack spacing={4}>
            <Heading as="h2" size="md" fontWeight={"normal"}>
              <Trans
                t={t}
                i18nKey={
                  application
                    ? "launch.launch_app_with_name"
                    : "launch.launch_app"
                }
                values={{ name: application?.name }}
              />
            </Heading>
            {application && organization && matchingLaunchConfiguration && (
              <LaunchPageContext.Provider
                value={{
                  applicationBuildId:
                    matchingLaunchConfiguration.application_build,
                  launchArgs,
                  organization,
                  application,
                  forceColdVm,
                  encryptVrStream,
                  debugModeEnabled,
                  virtualMachineId,
                  vmImage,
                  vmSize,
                  renderRegion: renderRegion ?? vm?.region,
                  targetDevice,
                }}
              >
                {sessionType === SessionType.CloudRenderedNonVR && (
                  <RequireLatency>
                    <CloudLaunchBrowser />
                  </RequireLatency>
                )}
                {sessionType === SessionType.CloudRenderedVR && (
                  <RequireLatency>
                    <CloudLaunchStandalone />
                  </RequireLatency>
                )}
                {sessionType === SessionType.LocallyRenderedStandalone && (
                  <LocalLaunchStandalone />
                )}
                {sessionType === SessionType.LocallyRenderedWindows && (
                  <LocalLaunchWindows />
                )}
                {sessionType === SessionType.Unknown && (
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertDescription>
                      {t("launch.launch_type_unsupported")}
                    </AlertDescription>
                  </Alert>
                )}
              </LaunchPageContext.Provider>
            )}
          </Stack>
        </CardBody>
      </Card>
    </>
  );

  function navigateBack() {
    navigate(-1);
  }
}
