import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { setUserPreferences } from "../session-management-api";
import { UserPreferences } from "./types";
import { useNotificationToast } from "./useNotificationToast";
import { userPreferencesQueryKey } from "./useUserPreferences";

export function usePreferredCloudRenderingRegionMutation({
  onSuccess,
  onError,
  ...options
}: Partial<
  Omit<
    UseMutationOptions<UserPreferences, AxiosError, UserPreferences>,
    "mutationFn"
  >
> = {}) {
  const queryClient = useQueryClient();
  const toast = useNotificationToast();
  const { t } = useTranslation();

  const setPreferredCloudRenderingRegionMutation = useMutation<
    UserPreferences,
    AxiosError<unknown, unknown>,
    UserPreferences
  >({
    mutationFn: (preferences: UserPreferences) =>
      setUserPreferences(preferences),
    onSuccess: (data, variables, context) => {
      onSuccess && onSuccess(data, variables, context);
      queryClient.invalidateQueries({ queryKey: userPreferencesQueryKey });
      toast.closeAll();
      toast({
        title: t("streamingPreferences.dialog.savedTitle"),
        description: t("streamingPreferences.dialog.saved"),
        status: "success",
      });
    },
    onError: (error, variables, context) => {
      onError && onError(error, variables, context);
      queryClient.invalidateQueries({ queryKey: userPreferencesQueryKey });
      toast.closeAll();
      toast({
        title: t("streamingPreferences.dialog.errorTitle"),
        description: t("streamingPreferences.dialog.error"),
        status: "error",
      });
    },
    ...options,
  });

  return setPreferredCloudRenderingRegionMutation;
}
