import { useQuery } from "@tanstack/react-query";
import { getOrganizationCloudRenderingPolicies } from "../session-management-api";

export function useOrganizationCloudRenderingPolicies() {

  return useQuery({
    queryKey: ["policies"],
    queryFn: () => getOrganizationCloudRenderingPolicies(),
  });
}
