import { Image, ImageProps } from "@chakra-ui/image";
import { Box } from "@chakra-ui/layout";
import {
  ResponsiveValue,
  StyleProps,
  forwardRef,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useBranding } from "../branding";
import { useImage } from "../hooks";
import { BrandedSkeleton } from "./BrandedSkeleton";

interface ApplicationImageProps extends ImageProps {
  src?: string;
  imageParams?: string;
  minHeight?: ResponsiveValue<number | string>;
  maxHeight?: ResponsiveValue<number | string>;
}

export const ApplicationImage = forwardRef<ApplicationImageProps, "img">(
  ({ src: imageUrl, imageParams, minHeight, maxHeight, ...props }, ref) => {
    const {
      data: { src, width, height } = {},
      isFetched,
      isSuccess,
    } = useImage(imageUrl, imageParams);

    const computedProps = useMemo(
      () => ({
        ...props,
        minHeight: !width || !height ? minHeight : undefined,
        maxHeight: !width || !height || height >= width ? maxHeight : undefined,
      }),
      [props, width, height, minHeight, maxHeight],
    );

    if (!(isFetched || !imageUrl)) {
      return (
        <BrandedSkeleton height="full" display="flex" {...computedProps} />
      );
    }

    return isSuccess ? (
      <Image src={src} {...computedProps} ref={ref} />
    ) : (
      <PlaceholderImage {...computedProps} ref={ref} />
    );
  },
);

const PlaceholderImage = forwardRef<StyleProps, "img">((props, ref) => {
  const { data: branding } = useBranding();
  const bgColor = useColorModeValue(
    "linear(to-t, blackAlpha.500, blackAlpha.100)",
    "linear(to-t, whiteAlpha.500, whiteAlpha.100)",
  );

  return branding?.app_image_placeholder ? (
    <Image src={branding?.app_image_placeholder} {...props} ref={ref} />
  ) : (
    <Box bgGradient={bgColor} {...props} ref={ref} />
  );
});
