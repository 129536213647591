import { useQuery } from "@tanstack/react-query";
import { useAuthentication } from "../auth";
import { getVirtualMachine } from "../session-management-api";
import { getVirtualMachinesQueryKey } from "./useVirtualMachinesQuery";

export function useVirtualMachineQuery(id?: string) {
  const { user } = useAuthentication();

  return useQuery({
    queryKey: getVirtualMachinesQueryKey({}, id),
    queryFn: () => getVirtualMachine(id!),
    refetchOnMount: true,
    enabled: !!user?.id && !!id,
  });
}
