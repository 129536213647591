import { Box, Center, HStack, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { FaStar } from "react-icons/fa";

export function StarRating({
  rating = 0,
  setRating,
  count,
  size,
}: {
  rating?: number;
  setRating: (rating: number) => void;
  count?: number;
  size?: number;
}) {
  const [hover, setHover] = useState<number | null>(null);
  return (
    <HStack spacing={"2px"}>
      {[...Array(count || 5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <Box
            as="label"
            key={index}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(null)}
          >
            <IconButton
              aria-label={""}
              variant="unstyled"
              onClick={() => setRating(ratingValue)}
              icon={
                <Center>
                  <FaStar
                    color={
                      ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                    }
                    cursor={"pointer"}
                    size={size || 20}
                  />
                </Center>
              }
            />
          </Box>
        );
      })}
    </HStack>
  );
}
