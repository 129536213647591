import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector, useSessionDevice } from "../hooks";
import { SESSION_STATE } from "../session/session-state";
import { SessionType } from "../session/types";
import { terminateSession } from "../signalR";
import { routes } from "../utils/routes";
import { LinkButton } from "./LinkButton";

export function SessionIsReadyDialog() {
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const cancelRef = useRef(null);
  const { t } = useTranslation();
  const currentSession = useAppSelector(selectSessionState);
  const isOnSessionPage = !!useMatch(routes.session);
  const device = useSessionDevice();

  // reset the acknowledgment state when a new session is started
  useEffect(() => {
    if (currentSession.state === SESSION_STATE.LOADING) {
      setIsAcknowledged(false);
    }
  }, [currentSession.state]);

  return (
    <AlertDialog
      isOpen={
        !isAcknowledged &&
        !isOnSessionPage &&
        currentSession.state === SESSION_STATE.READY &&
        currentSession.type === SessionType.CloudRenderedVR
      }
      leastDestructiveRef={cancelRef}
      isCentered
      onClose={() => {}}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("session.ready")}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Stack>
              <Text>
                {t("session.ready_instructions", {
                  device: device?.name ?? "Unknown",
                })}
              </Text>
              {currentSession.state === SESSION_STATE.ERRORED && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>
                    {t("session.unexpected_error")}
                  </AlertDescription>
                </Alert>
              )}
            </Stack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack>
              <Button
                colorScheme="red"
                onClick={() => terminateSession(currentSession.id!)}
                isLoading={currentSession.state === SESSION_STATE.ENDING}
              >
                {t("session.abort_dialog.title")}
              </Button>
              <LinkButton
                onClick={() => setIsAcknowledged(true)}
                colorScheme="brand"
                to={routes.session}
              >
                {t("session.ready_acknowledge")}
              </LinkButton>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
