import { Icon } from "@chakra-ui/icon";
import {
  Grid,
  Heading,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/layout";
import {
  As,
  CircularProgress,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { DialogContainer } from "../../components";
import {
  MetaIcon,
  PicoIcon,
  WaveIcon,
  WindowsIcon,
} from "../../components/icons";
import { ClientApplicationType } from "../../hooks/types";
import { useClientApplicationsQuery } from "../../hooks/useClientApplicationQuery";
import { routes } from "../../utils/routes";

function TargetPlatformItem({
  icon,
  name,
  to,
  href,
  comingSoon = false,
  isLoading = false,
  ...props
}: {
  icon: As;
  name: string;
  to?: string;
  href?: string;
  comingSoon?: boolean;
  isLoading?: boolean;
}) {
  const hoverBgColor = useColorModeValue("blackAlpha.400", "whiteAlpha.500");
  const hoverBorderColor = useColorModeValue(
    "blackAlpha.100",
    "whiteAlpha.700",
  );
  const { t } = useTranslation();
  return (
    <LinkBox
      flexGrow={1}
      flexBasis={0}
      flexShrink={1}
      borderWidth={1}
      borderRadius="md"
      padding={6}
      borderColor={useColorModeValue("blackAlpha.100", "whiteAlpha.500")}
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.200")}
      _hover={
        comingSoon
          ? undefined
          : {
              bgColor: hoverBgColor,
              borderColor: hoverBorderColor,
            }
      }
      cursor={!comingSoon ? "pointer" : "not-allowed"}
      {...props}
    >
      <Tooltip isDisabled={!comingSoon} label={t("clients.coming_soon")}>
        <VStack opacity={comingSoon ? 0.4 : 1.0} spacing={3}>
          {isLoading ? (
            <CircularProgress isIndeterminate size={16} padding={2} />
          ) : (
            <Icon boxSize={20} as={icon} />
          )}
          <Heading whiteSpace="nowrap" as="h3" size="sm">
            {comingSoon || isLoading ? (
              <>{name}</>
            ) : to ? (
              <LinkOverlay as={Link} to={to}>
                {name}
              </LinkOverlay>
            ) : href ? (
              <LinkOverlay href={href}>{name}</LinkOverlay>
            ) : (
              <>{name}</>
            )}
          </Heading>
        </VStack>
      </Tooltip>
    </LinkBox>
  );
}

export function InstallClientOverview() {
  const { t } = useTranslation();
  const { data: availableClientApplications, isLoading } =
    useClientApplicationsQuery();

  const isClientApplicationUnavailable = useCallback(
    (clientType: ClientApplicationType) => {
      return (
        availableClientApplications?.results.find(
          (clientApplication) => clientApplication.slug === clientType,
        )?.current_version === null
      );
    },
    [availableClientApplications],
  );

  return (
    <Stack spacing={8} maxWidth={"xl"} margin="0 auto">
      <Heading as="h2">{t("clients.title")}</Heading>
      <DialogContainer borderRadius={5}>
        <Stack alignItems="normal" spacing={8}>
          <Text>{t("clients.instructions")}</Text>
          <Grid
            gap={8}
            templateRows={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          >
            <TargetPlatformItem
              icon={WindowsIcon}
              name={t("clients.download_for_windows")}
              to={generatePath(routes.clients.installForDevice, {
                deviceSlug: ClientApplicationType.MicrosoftWindows,
              })}
              comingSoon={isClientApplicationUnavailable(
                ClientApplicationType.MicrosoftWindows,
              )}
              isLoading={isLoading}
            />
            <TargetPlatformItem
              icon={MetaIcon}
              name={t("clients.download_for_quest")}
              to={generatePath(routes.clients.installForDevice, {
                deviceSlug: ClientApplicationType.MetaQuest,
              })}
              comingSoon={isClientApplicationUnavailable(
                ClientApplicationType.MetaQuest,
              )}
              isLoading={isLoading}
            />
            <TargetPlatformItem
              icon={WaveIcon}
              name={t("clients.download_for_focus")}
              to={generatePath(routes.clients.installForDevice, {
                deviceSlug: ClientApplicationType.HtcViveFocus3,
              })}
              comingSoon={isClientApplicationUnavailable(
                ClientApplicationType.HtcViveFocus3,
              )}
              isLoading={isLoading}
            />
            <TargetPlatformItem
              icon={PicoIcon}
              name={t("clients.download_for_pico")}
              to={generatePath(routes.clients.installForDevice, {
                deviceSlug: ClientApplicationType.PicoNeo3Pro,
              })}
              comingSoon={isClientApplicationUnavailable(
                ClientApplicationType.PicoNeo3Pro,
              )}
              isLoading={isLoading}
            />
          </Grid>
        </Stack>
      </DialogContainer>
    </Stack>
  );
}
