import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSessionPageContext } from "../SessionPageContext";

export function SessionErrorState({ errorMessage }: { errorMessage?: string }) {
  const { t } = useTranslation();
  const { abortSession } = useSessionPageContext();

  return (
    <Box backgroundColor="backgroundAlpha.500">
      <Alert
        status="error"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {t("session.unexpected_error")}
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          <Text>{errorMessage}</Text>
        </AlertDescription>
        <Button
          variant="outline"
          marginTop={[2, 2, 4]}
          onClick={() => abortSession()}
        >
          {t("go_back")}
        </Button>
      </Alert>
    </Box>
  );
}
