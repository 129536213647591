import { useEffect } from "react";
import { selectCloudRendering } from "../features/cloudRenderingSlice";
import { notification } from "../features/sessionSlice";
import { useAppDispatch, useAppSelector } from "./redux";
import { CloudRenderingRegion } from "./types";

export function useLargeDistanceToRegionWarning(
  selectedRegion?: CloudRenderingRegion,
) {
  const dispatch = useAppDispatch();
  const cloudRendering = useAppSelector(selectCloudRendering);

  // warnings
  useEffect(() => {
    if (selectedRegion === undefined) {
      return;
    }

    const maxDistanceKm = 1000;
    if (selectedRegion.distanceM > 1000 * maxDistanceKm) {
      dispatch(
        notification({
          alert: true,
          notificationType: "LargeDistanceToRenderServer",
          level: "Warning",
          params: {
            RegionDisplayName: selectedRegion.displayName,
            DistanceM: selectedRegion.distanceM.toString(),
          },
        }),
      );
    }
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    if (selectedRegion === undefined) {
      return;
    }
    const maxLatency = 200;
    const latency = cloudRendering.regionsLatency[selectedRegion.name];
    if (latency > maxLatency) {
      dispatch(
        notification({
          alert: true,
          notificationType: "HighLatency",
          level: "Warning",
          params: {
            RegionDisplayName: selectedRegion.displayName,
            LatencyMs: latency.toString(),
          },
        }),
      );
    }
  }, [cloudRendering.regionsLatency, dispatch, selectedRegion]);
}
