import { chakra } from "@chakra-ui/react";
import PicoLogo from "../assets/img/pico-logo.svg?react";
import WaveLogo from "../assets/img/wave-logo.svg?react";

export { BsMeta as MetaIcon } from "react-icons/bs";
export {
  FaVolumeMute as AudioMutedIcon,
  FaVolumeUp as AudioUnmutedIcon,
  FaCheck as CheckmarkIcon,
  FaTimes as ClearIcon,
  FaCopy as CopyIcon,
  FaChevronDown as DropdownIcon,
  FaChevronUp as DropupIcon,
  FaExternalLinkAlt as ExternalLinkIcon,
  FaInfoCircle as InfoIcon,
  FaRegWindowRestore as MultiWindowIcon,
  FaLock as RequestAccessIcon,
  FaExclamationCircle as WarningIcon,
  FaWindows as WindowsIcon,
} from "react-icons/fa";
export { RiSpeedUpFill as LatencyIcon } from "react-icons/ri";

export const WaveIcon = chakra(WaveLogo);
export const PicoIcon = chakra(PicoLogo);
