import { InteractiveSpectator } from "../components";
import { SignalerType } from "../components/interactive-spectator/Signaler";
import { selectRenderingServerIp } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";

export function StandaloneInteractiveSpectatorPage() {
  const serverIpFromQueryParams =
    new URLSearchParams(window.location.search).get("serverIp") ?? "127.0.0.1";
  const serverIpAddress =
    useAppSelector(selectRenderingServerIp) ?? serverIpFromQueryParams;
  const params = new URLSearchParams(window.location.search);
  const signalingType =
    (params.get("signaling") as SignalerType) ??
    SignalerType.WebsocketSelfhosted;

  return (
    <InteractiveSpectator
      host={serverIpAddress}
      signaling={signalingType}
      width={"100vw"}
      height={"100vh"}
    />
  );
}
