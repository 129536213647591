import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import {
  destroyVirtualMachine,
  VirtualMachine,
} from "../session-management-api";
import { useNotificationToast } from "./useNotificationToast";
import { getVirtualMachinesQueryKey } from "./useVirtualMachinesQuery";

export function useDestroyVirtualMachineMutation({
  onSuccess,
  onError,
  ...options
}: Omit<
  UseMutationOptions<VirtualMachine, AxiosError, string>,
  "mutationFn"
> = {}) {
  const queryClient = useQueryClient();
  const toast = useNotificationToast();
  const { t } = useTranslation();

  const mutation = useMutation<VirtualMachine, AxiosError, string>({
    mutationFn: (id) => destroyVirtualMachine(id),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getVirtualMachinesQueryKey(),
      });
      toast({
        title: t("destroyRenderMachine.toast.title"),
        description: t("destroyRenderMachine.toast.description"),
        status: "success",
      });

      if (onSuccess) onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      toast({
        title: t("destroyRenderMachine.toast_error.title"),
        description: t("destroyRenderMachine.toast_error.description"),
        status: "error",
      });

      if (onError) onError(error, variables, context);
    },
    ...options,
  });
  return mutation;
}
