import { Icon } from "@chakra-ui/icon";
import { Text, UnorderedList } from "@chakra-ui/layout";
import { Alert, Button, Link, ListItem, Stack } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { FaDownload as DownloadIcon } from "react-icons/fa";
import { ExternalLink } from "../../components";
import { useCurrentClientApplicationVersionQuery } from "../../hooks";
import { ClientApplicationType } from "../../hooks/types";
import { useDeviceTranslation } from "./useDeviceTranslation";

export function InstallationInstructionsSideloading({
  clientType,
}: {
  clientType: ClientApplicationType;
}) {
  const { data, isLoading } =
    useCurrentClientApplicationVersionQuery(clientType);

  const { t } = useTranslation();

  const isWaveClient = clientType === ClientApplicationType.HtcViveFocus3;
  const isPicoNeoClient = clientType === ClientApplicationType.PicoNeo3Pro;

  const deviceTranslation = useDeviceTranslation(clientType);

  return (
    <Stack spacing={4}>
      <Alert status="warning" variant="left-accent">
        {t(
          "clients.installation_methods.sideloading.requirements",
          deviceTranslation,
        )}
      </Alert>

      <Text>
        <Trans
          t={t}
          i18nKey="clients.installation_methods.sideloading.download_first"
        >
          To install the client application via sideloading, please first
          download the application&apos;s <i>apk</i>:
        </Trans>
      </Text>

      <Button
        as={Link}
        target="_blank"
        href={data?.binary}
        width="full"
        colorScheme="brand"
        leftIcon={<Icon as={DownloadIcon} />}
        _hover={{ textDecoration: "none", bgColor: "brand.400" }}
        isLoading={isLoading}
      >
        {t("clients.installation_methods.sideloading.download")}
      </Button>

      <Text>
        {t(
          "clients.installation_methods.sideloading.method_intro",
          deviceTranslation,
        )}
      </Text>

      {!isWaveClient && !isPicoNeoClient && (
        <UnorderedList stylePosition="inside" spacing={2}>
          <ListItem>
            <ExternalLink href="https://docs.innoactive.io/iasup/how-to-use-portal-client-on-meta-quest">
              Meta Quest Developer Hub&nbsp;
              <b>
                ({t("clients.installation_methods.sideloading.recommended")})
              </b>
            </ExternalLink>
          </ListItem>
        </UnorderedList>
      )}

      {isWaveClient && (
        <UnorderedList stylePosition="inside" spacing={2}>
          <ListItem>
            <ExternalLink href="https://business.vive.com/de/support/business-training/category_howto/setting-up-on-vive-focus-3.html#GUID-F6110F95-54A5-42ED-A9D4-6AE3B4B218C0">
              {t("clients.installation_methods.sideloading.official_guide")}
            </ExternalLink>
          </ListItem>
        </UnorderedList>
      )}

      {isPicoNeoClient && (
        <UnorderedList stylePosition="inside" spacing={2}>
          <ListItem>
            <ExternalLink href="https://vr-expert.com/wp-content/uploads/2021/08/Pico-Neo-3-Guide-2.pdf">
              Pico-Neo-3-Guide Quickstart Guide (Page 4)
            </ExternalLink>
          </ListItem>
        </UnorderedList>
      )}
    </Stack>
  );
}
