import log from "loglevel";
import { useEffect } from "react";
import { SessionRequestPendingIndicator } from "../../components";
import { SessionType } from "../../session/types";
import { requestSession } from "../../signalR";
import { useLaunchPageContext } from "../LaunchPageContext";

export function LocalLaunchStandalone() {
  const { applicationBuildId, organization, launchArgs, targetDevice } =
    useLaunchPageContext();

  // launching locally with target hmd
  useEffect(() => {
    if (!targetDevice) {
      log.warn("No target device");
      return;
    }

    requestSession({
      appId: applicationBuildId,
      type: SessionType.LocallyRenderedStandalone,
      deviceIdentifier: targetDevice.identifier,
      launchArguments: launchArgs,
      organizationId: organization.id,
      // no VR streaming encryption for locally rendered sessions, see https://github.com/Innoactive/Session-Management/pull/534
      encryptVrStream: undefined,
    })
      .then(() => log.debug("Starting standalone session with vr device"))
      .catch(log.error);
  }, [launchArgs, organization.id, applicationBuildId, targetDevice]);

  return <SessionRequestPendingIndicator />;
}
