import { createContext } from "react";
import { XRPlatformType } from "../hooks/types";

interface ISearchContext {
  deviceFilter?: string;
  renderFilter?: string;
  setDeviceFilter(device: string): void;
  setRenderFilter(type: string): void;
  queryParams: { xr_platform?: XRPlatformType[] };
}

export const SearchContext = createContext({} as ISearchContext);
