import {
  Alert,
  AlertIcon,
  AlertTitle,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { routes } from "../utils/routes";
import { useAuthentication } from "./AuthenticationContext";

export default function OAuth2AuthCodeCallback() {
  const { isPendingAuthentication } = useAuthentication();
  const { t } = useTranslation();
  const [{ state = "", code }] = useQueryParams({
    state: withDefault(StringParam, ""),
    code: StringParam,
  });

  if (!code) {
    return <Navigate to={routes.home} />;
  }

  if (isPendingAuthentication) {
    return (
      <VStack>
        <Alert status="loading" maxW={"container.sm"}>
          <AlertIcon />
          <AlertTitle>{t("authorization_in_progress")}</AlertTitle>
        </Alert>
      </VStack>
    );
  }

  const [pathname, search] = state.split("?");

  return (
    <>
      <VStack>
        <Heading>
          {t("will_redirect_to")} {state}
        </Heading>
      </VStack>
      <Navigate to={{ pathname, search }} />
    </>
  );
}
