import { Heading, StackProps, VStack } from "@chakra-ui/layout";
import { useContext, useMemo } from "react";
import { generatePath } from "react-router-dom";
import { Application, ApplicationId, XRPlatformType } from "../hooks/types";
import { useApplicationsQuery } from "../hooks/useApplicationsQuery";
import { useSearchContext } from "../search";
import { routes } from "../utils/routes";
import { AppPreviewSkeleton, ApplicationPreview } from "./AppPreview";
import { BrandedSkeleton } from "./BrandedSkeleton";
import { HorizontalSlider, HorizontalSliderContext } from "./HorizontalSlider";

interface AppGroupProps {
  groupId?: number;
  appIds: ApplicationId[];
  title: string;
}

function AppPreviewInSlider({
  application,
  groupId,
}: Parameters<typeof ApplicationPreview>[0] & { groupId?: number }) {
  const { itemWidth } = useContext(HorizontalSliderContext);

  return (
    <ApplicationPreview
      url={
        groupId
          ? generatePath(routes.application.detailsViaGroup, {
              groupId: groupId.toString(),
              id: application.id,
            })
          : undefined
      }
      application={application}
      width={itemWidth}
      flexShrink="0"
    />
  );
}

export function ApplicationGroup({
  groupId,
  appIds,
  title,
  ...props
}: AppGroupProps & StackProps) {
  const { queryParams } = useSearchContext();
  const applicationsQuery = useApplicationsQuery(
    {
      id_in: appIds,
      page_size: appIds.length,
      supported_xr_platform_or: queryParams?.xr_platform
        ? (queryParams?.xr_platform as XRPlatformType[])
        : undefined,
    },
    {
      select: ({ results, ...rest }) => {
        // reorder the results to match the order of the appIds
        return {
          ...rest,
          results: appIds
            .map<
              Application | undefined
            >((appId) => results.find((app) => app.id === appId))
            .filter((app): app is Application => !!app),
        };
      },
    },
  );
  const numberOfLoadedApplications = useMemo(
    () => applicationsQuery.data?.count ?? 0,
    [applicationsQuery],
  );
  const isFetchedAfterMount = applicationsQuery.isFetchedAfterMount;

  if (
    !numberOfLoadedApplications &&
    // not on initial load
    isFetchedAfterMount
  ) {
    return null;
  }

  return (
    <VStack spacing={0} alignItems="normal" marginY={8} {...props}>
      <BrandedSkeleton isLoaded={!!title} mx={[4, 6]}>
        <Heading as="h3" size="md">
          {title || "Fallback Module Title"}
        </Heading>
      </BrandedSkeleton>
      <HorizontalSlider>
        {applicationsQuery.data?.results.map((application) => (
          <AppPreviewInSlider
            key={application.id}
            application={application}
            groupId={groupId}
          />
        )) ??
          appIds.map((appId) => (
            <ApplicationPreviewSkeletonInSlider key={appId} />
          ))}
      </HorizontalSlider>
    </VStack>
  );
}

function ApplicationPreviewSkeletonInSlider(
  props: Parameters<typeof AppPreviewSkeleton>[0],
) {
  const { itemWidth } = useContext(HorizontalSliderContext);

  return <AppPreviewSkeleton width={itemWidth} flexShrink="0" {...props} />;
}

export function ApplicationGroupSkeleton() {
  const fakeAppIds = useMemo(
    () =>
      Array.from({ length: Math.round(Math.random() * 7) + 1 }, (_, idx) =>
        idx.toString(),
      ),
    [],
  );

  return (
    <VStack spacing={0} alignItems="normal" marginY={8}>
      <BrandedSkeleton mx={[4, 6]}>Fallback Module Title</BrandedSkeleton>
      <HorizontalSlider isDisabled>
        {fakeAppIds.map((_, idx) => (
          <ApplicationPreviewSkeletonInSlider key={idx} />
        ))}
      </HorizontalSlider>
    </VStack>
  );
}
