export type Platform = "windows" | "android";

type ApplicationImageDetails = {
  image: string;
};

export const xrPlatformTypes = [
  "quest",
  "wave",
  "pico",
  "vrx",
  "win-vr",
  "win-non-vr",
] as const;

export type XRPlatformType = (typeof xrPlatformTypes)[number];

export const windowsXRPlatformTypes: XRPlatformType[] = [
  "win-vr",
  "win-non-vr",
];

export const androidXRPlatformTypes: XRPlatformType[] = [
  "quest",
  "wave",
  "pico",
  "vrx",
];

export type ApplicationLaunchConfiguration = {
  application: ApplicationId;
  application_build: ApplicationBuildId | null;
  xr_platform: XRPlatformType;
};

export type LaunchableApplicationLaunchConfiguration = Omit<
  ApplicationLaunchConfiguration,
  "application" | "application_build"
> & { application_build: ApplicationBuildId };

export type Organization = {
  id: number;
  name: string;
  subdomain?: string;
  domain?: string;
  authorization_endpoint?: string;
};

const applicationPermissions = [
  "view",
  "stream",
  "download",
  "change",
] as const;

export type ApplicationPermission = (typeof applicationPermissions)[number];

export enum ApplicationStatus {
  Pending = 0,
  InvalidPath = 1,
  NoExecutables = 2,
  MultipleExecutables = 3,
  Valid = 5,
  InvalidApplicationArchive = 7,
}

export const knownTargetPlatforms = ["windows", "android", "unknown"] as const;

export type TargetPlatform = (typeof knownTargetPlatforms)[number];

export type Application = {
  id: UUID;
  legacy_identity: string;
  oauth2_client_id: string | null;
  tags: string[];
  description_html: string;
  description: string;
  name: string;
  images: ApplicationImageDetails[];
  panoramic_preview_image: string | null;
  modified_date: string;
  external_references?: ApplicationExternalReference[];
  organizations: Organization["id"][];
  launch_configurations: Omit<ApplicationLaunchConfiguration, "application">[];
  permissions: {
    [perm in ApplicationPermission]?: boolean;
  };
};

export type ApplicationBuild = {
  id: number;
  application: ApplicationId;
  version: string;
  supported_xr_platforms: XRPlatformType[];
  metadata: Record<string, unknown>;
  is_deprecated: boolean;
  status: ApplicationStatus;
  supports_arbitrary_cli_args: boolean;
  launch_args: string | null;
  changelog: string;
  target_platform: TargetPlatform;
  executable_path: string | null;
  application_archive: string;
  optimized_application_archive: string | null;
  package_name: string | null;
  created_date: string;
};

export type PaginatedApplicationBuildsList =
  PortalBackendPaginatedResult<ApplicationBuild>;

export interface ApplicationCategory {
  id: number;
  name: string;
  order: number;
  applications: ApplicationId[];
}

export type PortalError = {
  message?: string;
  detail?: string;
  [key: string]: undefined | string | string[];
};

export interface PortalPrivacyAndTerms {
  is_enabled: boolean;
  privacy_content: string;
  terms_content: string;
}

export interface UserPreferences {
  preferredRenderRegion: string | undefined;
}

export interface SessionRating {
  starRating: number;
  ratingComment: string;
}

export interface VmSizeInformation {
  graphicsCardDisplayName: string;
  name: string;
  displayName: string;
  isDefault: boolean;
  minRam: number;
  minVCpu: number;
  regionalAvailability: string[];
  isEnabled: boolean;
}

export interface CloudRenderingRegion {
  isEnabled: boolean;
  name: string;
  displayName: string;
  cloudProvider: string;
  type: string;
  networkOperator: string;
  supportedVmSizes: string[];
  distanceM: number;
  latitude: number;
  longitude: number;
  latencyTestUrl: string;
}

export interface RegionFilter {
  appIds?: ApplicationBuildId[];
  deviceIdentifier?: string;
  latencyInfo?: { [key: string]: number };
  preferredVmSize?: string;
  preferredRegion?: string;
  allowedCloudProviders?: string[];
  allowedNetworkOperators?: string[];
  allowLocalZones?: boolean;
  allowWavelengthZones?: boolean;
  allowedWavelengthZones?: string[];
  allowUnmanagedZones?: boolean;
  includeDisabledRegions?: boolean;
}

export type ApplicationUrlRewriteRule = {
  name: string;
  description: string | null;
  match: string;
  replace: string;
  enabled: boolean;
  isPreset: boolean;
};

export type ApplicationUrlRewriteConfiguration = {
  rules?: Array<Partial<ApplicationUrlRewriteRule>>;
};

export type ApplicationCloudRenderingConfiguration = {
  vm_size: string | null;
  url_rewrites: ApplicationUrlRewriteConfiguration | null;
};

export type UUID = string;

export type ApplicationId = Application["id"];

export type ApplicationBuildId = ApplicationBuild["id"];

export type ApplicationExternalReference = {
  type: string;
  url: string;
  is_active: boolean;
};

export type PaginatedApplicationsList =
  PortalBackendPaginatedResult<Application>;

export type PaginationFilters = {
  page?: number;
  page_size?: number;
};

export type OrderingFilters = {
  ordering?: string;
};

export type ApplicationBuildListFilters = PaginationFilters &
  OrderingFilters & {
    supported_xr_platform?: XRPlatformType | XRPlatformType[];
    application?: ApplicationId;
  };

export type ApplicationListFilters = PaginationFilters &
  OrderingFilters & {
    fulltext_search?: string;
    id_in?: ApplicationId[];
    organization?: number;
    supported_xr_platform?: XRPlatformType | XRPlatformType[];
    supported_xr_platform_or?: XRPlatformType | XRPlatformType[];
    permission?: ApplicationPermission;
  };

export type PortalBackendPaginatedResult<T> = {
  count: number;
  next: string;
  previous: string;
  results: T[];
};

export type PortalApplicationCategoryListResponse =
  PortalBackendPaginatedResult<ApplicationCategory>;

export enum ClientApplicationType {
  MetaQuest = "oculus-quest",
  HtcViveFocus3 = "htc-vive-focus-3",
  PicoNeo3Pro = "pico-neo-3-pro",
  MicrosoftWindows = "desktop-client",
}
