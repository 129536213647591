import { Box } from "@chakra-ui/layout";
import { ResponsiveValue } from "@chakra-ui/react";
import { Pannellum } from "pannellum-react";
import { useMemo, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ApplicationImage, BrandedSkeleton } from ".";
import { useImage } from "../hooks";
import { Application } from "../hooks/types";
import { SliderControlElement } from "./HorizontalSlider";
import { SliderControlElementSide } from "./SliderControlElementSide";

interface ApplicationImageCarouselProps {
  applicationData: Application;
  minHeight: ResponsiveValue<number | string>;
  maxHeight: ResponsiveValue<number | string>;
}

export function ApplicationImageCarousel({
  applicationData,
  minHeight: _minHeight,
  maxHeight,
}: ApplicationImageCarouselProps) {
  const [isMouseOverElement, setIsMouseOverElement] = useState(false);
  const minHeight = useMemo(
    () => _minHeight || ["200px", "300px", "470px"],
    [_minHeight],
  );
  const panoramicPreviewImageUrl = applicationData?.panoramic_preview_image;
  // the data structure for preview images on the backend needs to be flattened
  const previewImageUrls = useMemo(
    () => (applicationData?.images || []).map((image) => image.image),
    [applicationData?.images],
  );

  // load the first preview and panoramic image
  const { isFetched } = useImage(previewImageUrls?.[0]);
  const { isFetched: isPanoramicFetched, data: panoramicImage } = useImage(
    panoramicPreviewImageUrl || undefined,
  );

  const imagesLoaded = useMemo(() => {
    const panoramicLoaded = !panoramicPreviewImageUrl || isPanoramicFetched;
    const appImagesLoaded = !previewImageUrls?.length || isFetched;

    return panoramicLoaded && appImagesLoaded;
  }, [
    isPanoramicFetched,
    isFetched,
    panoramicPreviewImageUrl,
    previewImageUrls,
  ]);

  const panoramicImageSlide = useMemo(() => {
    if (!panoramicImage || !imagesLoaded) {
      return null;
    }

    return (
      <Box
        key="panorama"
        sx={{
          ".pnlm-container": {
            borderRadius: 5,
          },
        }}
        // Case when there are no other preview images to determine the height relatively --> assign minimum height
        {...{
          height: !previewImageUrls.length ? minHeight : "100%",
        }}
      >
        <Pannellum
          height="100%"
          image={panoramicImage.src}
          showControls={false}
          showFullscreenCtrl={false}
          autoRotate={-3}
          autoLoad
        />
      </Box>
    );
  }, [minHeight, panoramicImage, previewImageUrls, imagesLoaded]);

  const applicationPreviewImages = useMemo(() => {
    // add a placeholder image no preview images
    if (!previewImageUrls.length) {
      previewImageUrls.push("undefined");
    }

    return previewImageUrls?.map((imageUrl) => (
      <ApplicationImage
        key={imageUrl}
        src={imageUrl}
        borderRadius={5}
        maxHeight={maxHeight}
        objectFit={"contain"}
        minHeight={minHeight}
        imageParams="width=850"
      />
    ));
  }, [previewImageUrls, maxHeight, minHeight]);

  // the carousel doesnt accept coditional rendering in return so we filter before
  const carouselSlides = useMemo(() => {
    const ret = applicationPreviewImages;
    if (panoramicImageSlide) {
      ret.push(panoramicImageSlide);
    }
    return ret;
  }, [applicationPreviewImages, panoramicImageSlide]);

  if (!panoramicPreviewImageUrl && !previewImageUrls?.length) {
    return null;
  }

  return (
    <BrandedSkeleton
      height={!imagesLoaded ? minHeight : undefined}
      isLoaded={imagesLoaded}
    >
      <Box
        onMouseEnter={() => setIsMouseOverElement(true)}
        onMouseLeave={() => setIsMouseOverElement(false)}
        sx={{
          // hide indicator dots if there's only one element anyways
          ".control-dots": {
            ".dot:only-child": {
              display: "none",
            },
          },
        }}
      >
        <Carousel
          autoPlay={true}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            //TODO: if mobile not
            isMouseOverElement &&
            hasPrev && (
              <SliderControlElement
                side={SliderControlElementSide.Left}
                onClick={onClickHandler}
              />
            )
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            isMouseOverElement &&
            hasNext && (
              <SliderControlElement
                side={SliderControlElementSide.Right}
                onClick={onClickHandler}
              />
            )
          }
        >
          {carouselSlides}
        </Carousel>
      </Box>
    </BrandedSkeleton>
  );
}
