import { useMemo } from "react";
import { useActiveOrganizationQuery } from "./useActiveOrganizationQuery";

export function useBaseUrl() {
  const { data: activeOrganization } = useActiveOrganizationQuery();

  const controlPanelUrl = useMemo(
    () => `${window.location.protocol}//${activeOrganization?.domain}`,
    [activeOrganization?.domain],
  );

  return controlPanelUrl;
}

export function useControlPanelUrl(path?: string) {
  const baseUrl = useBaseUrl();

  const controlPanelUrl = useMemo(
    () => `${baseUrl}/control-panel/v2${path ?? ""}`,
    [baseUrl, path],
  );

  return controlPanelUrl;
}
