import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { getSessionDetails } from "../session-management-api";
import { TimeCounter } from "./TimeTicker";

export function SessionDuration() {
  const { id: sessionId, isActive } = useAppSelector(selectSessionState);
  const sessionDetailsQuery = useQuery({
    queryKey: ["sessionDetails", sessionId],
    queryFn: async () => {
      const details = await getSessionDetails(
        sessionId ?? "00000000-0000-0000-0000-000000000000",
      );
      if (!details.experienceStartedDateTime) {
        details.experienceStartedDateTime = new Date().toISOString();
      }
      return details;
    },
    enabled: !!sessionId,
  });
  const refetch = sessionDetailsQuery.refetch;

  useEffect(() => {
    // refetch the session details when session becomes active
    if (isActive && sessionId) {
      refetch();
    }
  }, [isActive, sessionId, refetch]);

  if (
    !sessionDetailsQuery.data?.experienceStartedDateTime ||
    sessionDetailsQuery.isLoading
  ) {
    return <>...</>;
  }

  return (
    <TimeCounter
      startDate={sessionDetailsQuery.data?.experienceStartedDateTime}
      min={0}
    />
  );
}
