import { useEffect } from "react";
import { useAuthentication } from "../auth";
import { useCloudRenderingRegionsQuery } from "../hooks";
import {
  setToken
} from "../signalR";

export function CloudRenderingSessionProvider() {
  const { token } = useAuthentication();

  // ensure we load the regions at least once
  useCloudRenderingRegionsQuery();

  useEffect(() => {
    if (token) setToken(token);
  }, [token]);

  return null;
}
