import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getVmSizes } from "../session-management-api";
import { CloudRenderingRegion, PortalError, VmSizeInformation } from "./types";

export interface Region extends CloudRenderingRegion {
  latencyMs?: number;
}

export function useVmSizesQuery(
  options?: Partial<
    UseQueryOptions<VmSizeInformation[], AxiosError<PortalError>>
  >,
) {
  return useQuery<VmSizeInformation[], AxiosError<PortalError>>({
    queryKey: ["vm-sizes"],
    queryFn: () => getVmSizes(),
    staleTime: 1800,
    refetchOnMount: false,
    ...options,
  });
}

export function useVmSizeQuery(vmSize?: string) {
  const { data: vmSizes } = useVmSizesQuery();
  return vmSizes?.find((x) => x.name === vmSize);
}
