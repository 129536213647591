import { ColorModeScript } from "@chakra-ui/color-mode";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { isAxiosError } from "axios";
import log from "loglevel";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import App from "./App";
import { AuthenticationProvider } from "./auth";
import { AuthProviderWrapper } from "./auth/auth";
import { StrictMode } from "./components";
import { googleTagManagerId } from "./config";
import { DesktopClientProvider } from "./desktopClient";
import "./i18n";
import { CloudRenderingSessionProvider } from "./session/session";
import { store } from "./store";
import { SetSentrySession, SetSentryUser, setupSentry } from "./utils/sentry";

setupSentry();

log.setLevel(import.meta.env.DEV ? log.levels.DEBUG : log.levels.WARN);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        // do not retry if the error is a 404
        if (isAxiosError(error)) {
          const statusCode = error?.response?.status;
          return !!statusCode && (statusCode < 400 || statusCode >= 500);
        }

        return failureCount < 3;
      },
    },
  },
});

if (googleTagManagerId) {
  TagManager.initialize({
    gtmId: googleTagManagerId,
  });
}

const root = createRoot(document.getElementById("root")!);

root.render(
  <StrictMode>
    <ColorModeScript />
    <QueryClientProvider client={queryClient}>
      <AuthProviderWrapper>
        <AuthenticationProvider>
          <SetSentryUser />
          <Provider store={store}>
            <CloudRenderingSessionProvider />
            <DesktopClientProvider>
              <SetSentrySession />
              <App />
            </DesktopClientProvider>
          </Provider>
        </AuthenticationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthProviderWrapper>
    </QueryClientProvider>
  </StrictMode>,
);
