export function downloadFile(url: string, filename?: string) {
  const link = document.createElement("a");

  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    link.setAttribute("href", url);
    link.setAttribute("download", filename ?? url.split("/").slice(-1)[0]);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return url;
}
