import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import customProtocolCheck from "custom-protocol-check";
import { debug, error, warn } from "loglevel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { LinkButton } from "../../components";
import { UpdateApplicationWarningDialog } from "../../components/UpdateApplicationWarningDialog";
import {
  cloudRenderingSessionManagementServiceUrl,
  desktopClientOAuth2ClientId,
  desktopClientOAuth2ClientSecret,
  portalBackendUrl,
} from "../../config";
import { useDesktopClientContext } from "../../desktopClient";
import { selectDesktopClient } from "../../features/desktopClientSlice";
import {
  useAppSelector,
  useCurrentClientApplicationVersionQuery,
} from "../../hooks";
import { ClientApplicationType } from "../../hooks/types";
import { SessionType } from "../../session/types";
import { requestSession } from "../../signalR";
import {
  isAnyApplicationBuildInstalled,
  isApplicationBuildInstalled,
} from "../../utils/applications";
import { isWindows } from "../../utils/browser-support";
import { routes } from "../../utils/routes";
import { useLaunchPageContext } from "../LaunchPageContext";

const desktopClientConfigName = new URL(portalBackendUrl).host;
const desktopClientProtocol = `innoprtl://`;

// To launch an app locally on Windows, we need the Desktop Client to be running
// 1. The Desktop Client could be running already, we need to "just" wait until we can connect to its service websocket
// 2. The Desktop Client might be installed but not running already, we need to prompt the user to start it (custom protocol)
// 3. The Desktop Client might not yet be installed. In this case, the prompt will never be shown to the user and they need to download and install manually. This case is not detectable / avoidable, so we'll always need to show the instructions for it

export function LocalLaunchWindows() {
  const { t } = useTranslation();
  const {
    isDeviceAuthSupported,
    connectionAttemptFailed,
    isAvailableLocally: isDesktopClientRunningLocally,
  } = useAppSelector(selectDesktopClient);
  const {
    applicationBuildId,
    application,
    organization,
    launchArgs,
    targetDevice,
  } = useLaunchPageContext();
  const { requestAuthentication, setLocalConnectionPollingInterval } =
    useDesktopClientContext();
  const isDesktopClientConnected = useMemo(
    () => !!targetDevice,
    [targetDevice],
  );
  const { data: { binary: downloadUrl } = {} } =
    useCurrentClientApplicationVersionQuery(
      ClientApplicationType.MicrosoftWindows,
    );
  const [enforceUpdate, setEnforceUpdate] = useState(true);
  const [updateChecked, setUpdateChecked] = useState(false);
  const [
    updateApplicationWarningDialogShown,
    setUpdateApplicationWarningDialogShown,
  ] = useState(false);
  const [autoLaunchRequested, setAutoLaunchRequested] = useState(false);
  const [numberOfLaunchAttempts, setNumberOfLaunchAttempts] = useState(0);
  const [isLaunchButtonDisabled, setIsLaunchButtonDisabled] = useState(false);

  const launchDesktopClient = useCallback(() => {
    let customProtocol = desktopClientProtocol;
    if (!isDeviceAuthSupported) {
      customProtocol = desktopClientProtocol.concat(
        `new-config?url=${portalBackendUrl}&name=${desktopClientConfigName}&id=${desktopClientOAuth2ClientId}&secret=${desktopClientOAuth2ClientSecret}&session_url=${cloudRenderingSessionManagementServiceUrl}/hub/sessions`,
      );
    }

    // check if desktop client is even installed and if so try to open it via custom protocol
    new Promise<void>((resolve, reject) => {
      setNumberOfLaunchAttempts((prev) => prev + 1);

      customProtocolCheck(customProtocol, reject, resolve, 500, () => {
        reject("Browser does not support custom protocols");
        Sentry.captureException(
          new Error("Browser does not support custom protocols"),
        );
      });
    })
      .then(() => {
        // start polling the connection to the desktop client more frequently
        setLocalConnectionPollingInterval(250);
        // don't show the launch button while the custom prompt is shown
        setIsLaunchButtonDisabled(true);
      })
      .catch((err) => {
        // if error is undefined, it means the user has cancelled the prompt
        if (err === undefined) {
          return;
        }
      });
  }, [isDeviceAuthSupported, setLocalConnectionPollingInterval]);

  useEffect(() => {
    // activate the button again if window gets focus (which happens when custom protocol prompt is closed)
    const eventHandler = () => setIsLaunchButtonDisabled(false);
    window.addEventListener("focus", eventHandler);

    return () => {
      window.removeEventListener("focus", eventHandler);
    };
  }, []);

  useEffect(() => {
    // If the connection to the desktop client's service endpoint (local websocket API) is not established and does not succeed (failed attempt), the Desktop Client is not running.
    if (
      !isDesktopClientRunningLocally &&
      connectionAttemptFailed &&
      !autoLaunchRequested
    ) {
      setAutoLaunchRequested(true);
      // attempt to start the desktop client
      launchDesktopClient();
      return;
    }

    // If the connection to the desktop client has been established locally, we can now request authentication
    if (isDeviceAuthSupported && !isDesktopClientConnected) {
      requestAuthentication();
      return;
    }
  }, [
    autoLaunchRequested,
    connectionAttemptFailed,
    isDesktopClientConnected,
    isDesktopClientRunningLocally,
    isDeviceAuthSupported,
    launchDesktopClient,
    requestAuthentication,
  ]);

  useEffect(() => {
    if (!isDesktopClientConnected) return;

    if (!targetDevice) {
      warn("No suitable target device / client connected");
      return;
    }

    if (!updateChecked) {
      if (
        isAnyApplicationBuildInstalled(
          application.legacy_identity,
          targetDevice,
        ) &&
        !isApplicationBuildInstalled(applicationBuildId, targetDevice)
      ) {
        setUpdateApplicationWarningDialogShown(true);
        return;
      }

      setUpdateChecked(true);
    }

    let appId = applicationBuildId;
    if (!enforceUpdate) {
      appId = targetDevice.installedApps.find(
        (_app) => _app.identity === application.legacy_identity,
      )!.id;
    }

    requestSession({
      appId,
      type: SessionType.LocallyRenderedWindows,
      deviceIdentifier: targetDevice.identifier,
      launchArguments: launchArgs,
      organizationId: organization.id,
      // no VR streaming encryption for locally rendered sessions, see https://github.com/Innoactive/Session-Management/pull/534
      encryptVrStream: undefined,
    })
      .then(() => debug("Starting locally rendered session on Desktop"))
      .catch(error);
  }, [
    updateApplicationWarningDialogShown,
    updateChecked,
    enforceUpdate,
    targetDevice,
    isDesktopClientConnected,
    launchArgs,
    organization.id,
    application.legacy_identity,
    applicationBuildId,
  ]);

  if (!isWindows && !isDesktopClientConnected) {
    return (
      <Alert status="warning" padding={0} bg="none">
        <AlertIcon alignSelf={"start"} />
        <Stack spacing={4}>
          <Text>
            <Trans
              t={t}
              i18nKey={"launch.desktop_client_unsupported_platform"}
            />
          </Text>
          <LinkButton
            to={generatePath(routes.application.details, {
              id: application.id,
            })}
            colorScheme="brand"
          >
            {t("launch.show_alternative_launch_modes")}
          </LinkButton>
        </Stack>
      </Alert>
    );
  }

  return (
    <>
      <Stack spacing={4}>
        <Text>
          <Trans i18nKey="launch.custom_protocol_prompt_instruction" t={t} />
        </Text>

        <Text>
          <Trans
            t={t}
            i18nKey="launch.custom_protocol_prompt_reopen"
            values={{
              action: t("launch.custom_protocol_prompt_reopen_action", {
                name: application.name,
              }),
            }}
          />
        </Text>

        <Button
          onClick={() => launchDesktopClient()}
          loadingText={t("launch.check_desktop_client_installation")}
          size="md"
          colorScheme="brand"
          alignSelf={"start"}
          isLoading={isLaunchButtonDisabled || isDesktopClientRunningLocally}
        >
          {t("launch.custom_protocol_prompt_reopen_action", {
            name: application.name,
          })}
        </Button>

        {!!downloadUrl && (
          <>
            <Divider />
            <HStack spacing={2}>
              <Text>{t("launch.desktop_client_not_installed_question")}</Text>
              <Button
                as={Link}
                to={generatePath(routes.clients.installForDevice, {
                  deviceSlug: ClientApplicationType.MicrosoftWindows,
                })}
                variant="link"
              >
                {t("launch.download_now")}
              </Button>
            </HStack>
          </>
        )}
      </Stack>

      <Modal
        isOpen={numberOfLaunchAttempts > 3}
        onClose={() => setNumberOfLaunchAttempts(1)}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t("launch.potentially_uninstalled_desktop_client_title")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {t("launch.potentially_uninstalled_desktop_client")}
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setNumberOfLaunchAttempts(1)}
            >
              {t("launch.potentially_uninstalled_desktop_client_dialog_close")}
            </Button>
            <LinkButton
              colorScheme="brand"
              to={generatePath(routes.clients.installForDevice, {
                deviceSlug: ClientApplicationType.MicrosoftWindows,
              })}
            >
              {t("launch.go_to_desktop_client_installation_instructions")}
            </LinkButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <UpdateApplicationWarningDialog
        isOpen={updateApplicationWarningDialogShown}
        onClose={() => {
          setUpdateChecked(true);
          setUpdateApplicationWarningDialogShown(false);
        }}
        onConfirm={() => {
          setUpdateChecked(true);
          setEnforceUpdate(false);
          setUpdateApplicationWarningDialogShown(false);
        }}
      />
    </>
  );
}
