import { useQuery } from "@tanstack/react-query";
import { useActiveOrganizationQuery } from "../hooks";
import { getQueryKeyForOrganization } from "../hooks/useActiveOrganizationQuery";
import portalApiClient from "../portal-api";

interface BrandingData {
  primary: string | null;
  background: string | null;
  app_image_placeholder: string | null;
  logo: string | null;
  company_name: string | null;
  product_name: string | null;
  product_icon: string | null;
}

function getBrandingQueryKey(organizationId: number | null | undefined) {
  return [...getQueryKeyForOrganization(organizationId), "branding"] as const;
}

export function useBranding() {
  const { data: activeOrganization } = useActiveOrganizationQuery();

  return useQuery<BrandingData>({
    queryKey: getBrandingQueryKey(activeOrganization?.id),
    queryFn: async () => {
      const response = await portalApiClient.get("/api/branding/", {
        params: { organization: activeOrganization?.id },
      });
      return response.data;
    },
    refetchOnMount: false,
  });
}
