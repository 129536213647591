import {
  Icon,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaExclamation as WarningIcon } from "react-icons/fa";
import { selectSessionServiceConnectionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";

export const CloudRenderingServiceStatus = (
  props: Omit<IconButtonProps, "aria-label">,
) => {
  const connectionState = useAppSelector(selectSessionServiceConnectionState);
  const { t } = useTranslation();
  const warningColor =
    connectionState === "Connecting" ? "gray.300" : "orange.300";

  if (connectionState !== "Connected") {
    return (
      <Popover trigger="hover">
        <PopoverTrigger>
          <IconButton
            variant="outline"
            color={warningColor}
            borderColor={warningColor}
            paddingX={2}
            size="xs"
            isRound
            icon={<Icon as={WarningIcon} boxSize={3} />}
            aria-label="Cloud-Rendering Service Status"
            {...props}
          />
        </PopoverTrigger>
        <PopoverContent bg={warningColor}>
          <PopoverArrow bg={warningColor} />
          <PopoverHeader>
            {connectionState === "Connecting"
              ? t("details.connecting_title")
              : t("details.not_connected_title")}
          </PopoverHeader>
          <PopoverBody>
            {connectionState === "Connecting"
              ? t("details.connecting_text")
              : t("details.not_connected_text")}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  return null;
};
