import { createContext, useContext } from "react";

interface ISessionPageContext {
  showAbortSessionDialog: () => void;
  abortSession: () => void;
}
export const SessionPageContext = createContext<ISessionPageContext>(
  {} as ISessionPageContext,
);

export function useSessionPageContext() {
  return useContext(SessionPageContext);
}
