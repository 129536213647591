import { UseToastOptions, useColorModeValue, useToast } from "@chakra-ui/react";
import { useMemo } from "react";

const defaultOptions: UseToastOptions = {
  position: "top-right",
  duration: 5000,
  isClosable: true,
};

export function useNotificationToast(options?: UseToastOptions) {
  const variant = useColorModeValue("subtle", "solid");

  return useToast(
    useMemo<UseToastOptions>(
      () => ({
        variant,
        ...defaultOptions,
        ...options,
      }),
      [options, variant],
    ),
  );
}
