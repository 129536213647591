import log from "loglevel";
import React from "react";
import { CommandType, MouseKeys } from "./capture-daemon-protocol";
import { Coordinates2D, MouseButton } from "./constants";

interface ButtonEvent {
  button: MouseButton;
  type: string | undefined;
}

/**
 * Stops an event from being further propagated to other handlers
 * @param event
 */
export function stopEventPropagation(event: React.UIEvent): void {
  event.stopPropagation();
}

/**
 * Stops an event from being further propagated to other handlers and prevents any
 * default actions from happening.
 * @param event
 */
export function discardEvent(event: React.UIEvent) {
  stopEventPropagation(event);
  event.preventDefault();
}

/**
 * Determines whether the given mouse event can be handled, i.e. is from a supported button
 * @param event
 * @returns Whether the button can be handled
 */
export function isSupportedButtonEvent(event: ButtonEvent) {
  return [MouseButton.Left, MouseButton.Right, MouseButton.Middle].includes(
    event.button,
  );
}

/**
 * Obtains the suitable button value to be sent to portal's capture daemon based on the given mouse event (which button was pressed).
 * @param event
 * @returns the event value corresponding to the mouse event which can be processed by portal capture daeomon
 */
export function getCaptureDaemonMouseEventButtonValue(
  event: ButtonEvent,
): MouseKeys {
  switch (event.button) {
    case MouseButton.Middle:
      return MouseKeys.MiddleButton;
    case MouseButton.Right:
      return MouseKeys.RightButton;
    case MouseButton.Left:
      return MouseKeys.LeftButton;
    default:
      throw new Error("Unsupported button type " + event.button);
  }
}

/**
 * Determines the actual dimensions at which the video is rendered. The dimensions are neither the container dimensions nor
 * the video's transmitted dimensions as the video is positioned using `object-fit: contain` to be fully displayed within its
 * container but this in turn leads to modified dimensions
 * @param video The video's DOM Node
 */
export function getRenderedVideoSize(video: HTMLVideoElement): Coordinates2D {
  const videoRatio = video.videoWidth / video.videoHeight;
  const containerRatio = video.offsetWidth / video.offsetHeight;

  if (videoRatio > containerRatio) {
    // the case that video is centered vertically within container due to containment
    // means we have some dead zones on top and bottom, video will fill up the complete offsetWidth
    return {
      x: video.offsetWidth,
      y: Math.round(video.offsetWidth / videoRatio),
    };
  } else {
    // the case that video is centered horizontally within container due to containment
    // means we have some potentially dead zones on left and right, video will fill up the complete offsetHeight
    return {
      x: Math.round(video.offsetHeight * videoRatio),
      y: video.offsetHeight,
    };
  }
}

/**
 * Calculate the coordinates of a mouse event relative to the target element on which the mouse event occurred
 * @param evt The event to get the coordinates from
 * @returns coordinates of the event relative to the element on which the event happened
 */
export function getCoordiantesFromMouseEvent(
  evt: React.MouseEvent<HTMLElement>,
): Coordinates2D {
  if (evt.nativeEvent?.offsetX && evt.nativeEvent?.offsetY) {
    // modern browsers give us the event's coordinates relative to the element
    return {
      x: evt.nativeEvent.offsetX,
      y: evt.nativeEvent.offsetY,
    };
  }
  // fallback
  return getTargetOffset({ x: evt.clientX, y: evt.clientY }, evt.currentTarget);
}

/**
 * Transforms coordinates given in client-space (i.e. the entire browser window) to the coordinate system of the target element
 * @param clientCoordinates The coordinates of an event relative to the client. For an event this would be {x: clientX, y: clientY}
 * @param target The HTML Element relative to which the clientCoordinates should be transformed
 * @returns The coordinates relative to the target element. This is similar to a MouseEvent's offsetX and offsetY
 */
export function getTargetOffset(
  clientCoordinates: Coordinates2D,
  target: HTMLElement,
): Coordinates2D {
  const boundingRect = target.getBoundingClientRect();
  return {
    x: clientCoordinates.x - boundingRect.x,
    y: clientCoordinates.y - boundingRect.y,
  };
}

export function getCoordinatesRelativeToVideo(
  coords: Coordinates2D,
  video: HTMLVideoElement,
): Coordinates2D {
  const { x, y } = coords;

  // if there's no video loaded yet, nothing to do
  if (video.videoWidth === 0 || video.videoHeight === 0) {
    return { x: -1, y: -1 };
  }

  // get the actual dimensions of the rendered video (taking into account containment and layout)
  const videoSize = getRenderedVideoSize(video);

  // check if x and y are even within the video's bounds
  const minX = (video.offsetWidth - videoSize.x) / 2,
    minY = (video.offsetHeight - videoSize.y) / 2,
    maxX = videoSize.x + minX,
    maxY = videoSize.y + minY;
  if (x < minX || x > maxX || y < minY || y > maxY) {
    log.warn(
      "Coordinates out of bounds",
      { x, y },
      { minX, maxX },
      { minY, maxY },
    );
    return { x: -1, y: -1 };
  }

  const position = {
    x: (x - minX) / videoSize.x,
    y: (y - minY) / videoSize.y,
  };
  return position;
}

function getRelevantTouches(event: React.TouchEvent): React.Touch[] {
  const touches: React.Touch[] = [];
  for (let i = 0; i < event.touches.length; i++) {
    touches.push(event.touches[i]);
  }
  if (event.type === "touchend") {
    // when touch ends, we also need to take into account the changed touches to avoid jumps
    for (let i = 0; i < event.changedTouches.length; i++) {
      touches.push(event.changedTouches[i]);
    }
  }
  return touches;
}

export function getCoordinatesFromTouchEvent(event: React.TouchEvent) {
  // calculate average position of all touches
  const coords = { x: 0, y: 0 };
  const touches = getRelevantTouches(event);
  for (let i = 0; i < touches.length; i++) {
    const touch = touches[i];
    const { x: _x, y: _y } = getTargetOffset(
      { x: touch.clientX, y: touch.clientY },
      touch.target as HTMLElement,
    );
    coords.x += _x / touches.length;
    coords.y += _y / touches.length;
  }
  return coords;
}

/**
 * Converts a given mouseevent into an event structure suitable to be sent to the cloud rendering machine / capture daemon
 * @param {MouseEvent} event
 */
export function getCaptureDaemonMouseEvent(event: ButtonEvent) {
  return {
    t: getCaptureDaemonMouseEventType(event),
    v: getCaptureDaemonMouseEventButtonValue(event),
  };
}

/**
 * Obtains the suitable event type to be sent to portal's capture daemon based on the given mouse event (event types like mousedown or mouseup).
 * @param {MouseEvent} event
 * @returns the event type corresponding to the mouse event which can be processed by portal capture daeomon
 */
export function getCaptureDaemonMouseEventType(event: ButtonEvent) {
  switch (event.type) {
    case "mousedown":
    case "touchstart":
    case "pointerdown":
    case "down":
      return CommandType.MouseDown;
    case "mouseup":
    case "touchend":
    case "pointerup":
    case "up":
      return CommandType.MouseUp;
    default:
      return undefined;
  }
}

/**
 * Converts the given scroll amount into the event structure suitable to be sent to the cloud rendering machine / capture daemon
 * @param {number} deltaY
 */
export function getCaptureDaemonWheelEvent(deltaY: number) {
  return {
    t: CommandType.MouseScroll,
    v: deltaY,
  };
}

export type TargetWindow = {
  handle: number;
  title: string;
  isActive: boolean;
  thumbnail: string;
};
